<template>
  <div class="goods-detail" :style="[com.style]">
    <div :style="{ height: com.swiperHeight + 'px',background:'#fff' }" v-if="com.showSwiper">
      <el-carousel
      :loop="true"
      :autoplay="true"
      :interval="com.interval * 1000"
    >
      <el-carousel-item
        v-for="(item, index) in goodsDetail.swiperData"
        :key="index"
      >
        <img :src="$route.params.url+item.imgUrl" class="pikImg" fit="fill" :onerror="common.imgOnError" />
      </el-carousel-item>
    </el-carousel>
    </div>
    <div class="goods-info">
      <div class="goods-price flex">
        <div class="price">
          ￥<span>???</span>{{com.priceTitle}}
        </div>
        <div class="old-price">￥<span>???原价</span></div>
      </div>
      <div class="goods-name">
        商品标题
      </div>
    </div>
    <div class="goods-config floor">
      <div class="item-floor flex">
        <div class="flex">
          <div class="title">
            已选
          </div>
          <div class="content">
            暂无选择
          </div>
        </div>
        <div>
          <uni-icons type="right" size="15"></uni-icons>
        </div>
      </div>
      <div class="item-floor flex">
        <div class="flex">
          <div class="title">
            配送
          </div>
          <div>请选择收货地址</div>
        </div>
        <div>
          <uni-icons type="right" size="15"></uni-icons>
        </div>
      </div>
      <div class="item-floor flex">
        <div class="flex">
          <div class="title">
            服务
          </div>
          <div class="content">
            包邮+7天无理由退货
          </div>
        </div>
      </div>
    </div>
    <div class="goods-desc floor">
      <div class="title">
        详情
      </div>
      <div class="content" v-html="goodsDetail.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goodsDetail: this.com.data[0],
    };
  },
  mounted() {
    console.log(this.com);
  },
  props: {
    com: Object,
  },
};
</script>

<style scoped>
.goods-detail {
	background-color: #f7f7f7;
	height: 100%;
	overflow: auto;
 }
.pikImg {
  width: 100%;
  height: 300px;
}

.h100 {
  height: 100%;
}
.flex {
  display: flex;
  align-items: center;
}
.floor {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
}
.goods-info {
  font-size: 16px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
}
.goods-name {
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.price {
  color: #f2270c;
}
.price span {
  font-size: 24px;
}
.old-price {
  color: gray;
  margin-left: 15px;
}
.old-price span {
  text-decoration: line-through;
}
.goods-config {
  font-size: 16px;
}
.goods-config .item-floor {
  justify-content: space-between;
  padding: 10px;
}

.goods-config .title {
  color: gray;
  margin-right: 10px;
}
.goods-config .content {
  flex: 1;
}
.goods-detail .content {
  background-color: #fff;
}
</style>
