import { render, staticRenderFns } from "./jfCommission.vue?vue&type=template&id=4c25ebe1&scoped=true&"
import script from "./jfCommission.vue?vue&type=script&lang=js&"
export * from "./jfCommission.vue?vue&type=script&lang=js&"
import style0 from "./jfCommission.vue?vue&type=style&index=0&id=4c25ebe1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c25ebe1",
  null
  
)

export default component.exports