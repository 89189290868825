import { render, staticRenderFns } from "./sysRecycle.vue?vue&type=template&id=dbba0be6&scoped=true&"
import script from "./sysRecycle.vue?vue&type=script&lang=js&"
export * from "./sysRecycle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbba0be6",
  null
  
)

export default component.exports