<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="标点图">
          <div class="rows">
            <el-input placeholder="请输入地址" v-model="com.data[0].url"></el-input>
          </div>
          <div class="rows">
            <selectorImg v-model="com.data[0].poster"></selectorImg>
          </div>
        </el-form-item>
      </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '视频'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '视频组件主要用于视频资源的展示，可通过配置url字段实现。',
      fieldData:[
        {field: 'url',explain: '视频地址', type: 'string'}
      ]
    }
  },
  props: { com: {} }
}
</script>
