<template>
  <div class="self" :style="[com.style]">
    <div class="f cart" v-if="com.cart.show">
      <span class="el-icon-shopping-cart-full"></span>
      <p>购物车</p>
    </div>
    <div class="f comment" v-if="com.comment.show">
      <span class="el-icon-chat-dot-round"></span>
        <p>评论</p>
    </div>
    <div class="f btns">
        <p :style="{'background-color':com.cartButton.bgColor, color: com.cartButton.color}">加入购物车</p>
        <p :style="{'background-color':com.buyButton.bgColor, color: com.buyButton.color}">立即购买</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverUrl: this.common.serverUrl,
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>

<style scoped>
.self{height: 50px;background: #fff;width: 100%;padding: 5px 10px 0; box-sizing: border-box;}
.f{float: left; text-align: center;}
.f span{font-size: 20px;}
.f p{font-size: 14px;margin: 0;}
.cart{width: 50px; }
.comment{width: 40px; }
.btns{width: calc(100% - 90px);margin-top: 5px; border-radius: 100px; overflow: hidden;}
.btns p{float: left; width: 50%; padding: 5px 0px 6px 0px; }
</style>
