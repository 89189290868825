<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="领取优惠券接口(必填)">
		        <selectorFun v-model="com.getCouponFun" placement="bottom-end"></selectorFun>
            </el-form-item>
            <el-form-item label="使用按钮">
                <el-radio-group v-model="com.isShow">
                    <el-radio-button :label="false || undefined">显示</el-radio-button>
                    <el-radio-button :label="true">隐藏</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '优惠券'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
			desc: `优惠券有满减券、折扣券、优惠券(无门槛)三种，计算顺序为满减券->优惠券->折扣券，多为小数时截取到只有两位。`,
			fieldData:[
                {field: 'des',explain: '描述', type: 'string'},
                {field: 'type',explain: '类型', type: 'string'},
                {field: 'startTime',explain: '开始时间', type: 'string'},
                {field: 'endTime',explain: '结束时间', type: 'string'},
                {field: 'title',explain: '标题', type: 'string'},
                {field: 'term',explain: '参与条件', type: 'number'},
                {field: 'value',explain: '折扣金额', type: 'number'},
            ],
            requestData:[
                {name: '优惠券列表',module: 'd_coupon', action: 'getList_m'},
                {name: '领取优惠券',module: 'd_user_coupon', action: 'add'}
            ]
        };
    },
    created() {
        console.log(this.com);
    },
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>

</style>
