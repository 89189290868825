<template>
  <div>
    <span>链接</span>
    <selectorPage v-model="com.pageName" :comData="comData" style="display: inline;"></selectorPage>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  props: { com: {}, comData: Object }
}
</script>
