<template>
    <div class='jf-progress' :style="[com.style]">
        <div class="wrap" :style="{backgroundColor:com.bgColor}">
            <div class="title" :style="{color:com.titleFontColor}">{{ com.title }}</div>
            <div class="progress">
                <el-progress :percentage="com.data[0].progressVal" :stroke-width="com.progressHeight" :color="com.progressColor" :type="com.progressType"></el-progress>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>
    .jf-progress {
        padding: 10px;
        box-sizing: border-box;
    }
    .wrap {
        padding: 10px;
        border-radius: 10px;
    }
    .wrap .title {
        padding: 5px 0;
        font-weight: 600;
    }
    .wrap .progress {
        padding: 5px 0;
    }
</style>
