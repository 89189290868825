import { render, staticRenderFns } from "./jfExploreEditor.vue?vue&type=template&id=20385e2f&scoped=true&"
import script from "./jfExploreEditor.vue?vue&type=script&lang=js&"
export * from "./jfExploreEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20385e2f",
  null
  
)

export default component.exports