<template>
    <div class='jf-im-edior'>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="对话">
            <div style="display: flex;margin: 10px 0;">
                李四：<el-input style="width: 100px;" v-model="formContent" size="mini"></el-input> <el-button @click="formSend(formContent)" type="primary" :disabled="formContent == ''">发送</el-button>
            </div>
            <div style="display: flex;">
                张三：<el-input style="width: 100px;" v-model="toContent" size="mini"></el-input><el-button @click="toSend(toContent)" type="primary" :disabled="toContent == ''">发送</el-button>
            </div>
        </el-form-item>
      </el-form>
      <jfExplain v-model="drawer" :name="com.cName || '聊天'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
          drawer: false,
          desc: '聊天组件是一款集成了单聊、群聊、加好友、创建群聊……的即时通讯组件。',
          fieldData:[],
          formContent: "",
          toContent: "",
        };
    },
    created() {},
    methods: {
        formSend() {
            if(this.formContent == '') return
            this.com.data.push({
                "type": "form",
                "username": "李四",
                "avatar": "https://www.bjapp11.com/svr/upload/lv/2.jpeg",
                "content": this.formContent
            })
            this.formContent = ''
        },
        toSend() {
            if(this.toContent == '') return
            this.com.data.push({
                "type": "to",
                "username": "张三",
                "avatar": "https://www.bjapp11.com/svr/upload/lv/5.jpg",
                "content": this.toContent
            })
            this.toContent = ''
        }
    },
    props:{com:Object}
};
</script>

<style scoped>

</style>
