<template>
	<div style="overflow: hidden;">
  <el-dialog class="dlg" title="参照 " :visible.sync="visible" top="5vh" :close-on-click-modal="false" :append-to-body="true" @opened="opened" width="800px">
	  <el-row>
		  <el-col :span="14">
			  <el-table :data="setFields" stripe height="500" tooltip-effect="dark" style="width: 350px">
				<el-table-column prop="to" label="接收" width="160"></el-table-column>
					<el-table-column label="来源" width="160">
						<template slot-scope="scope">
							<el-select v-model="scope.row.from" clearable placeholder="请选择">
								<el-option v-for="item in from" :value="item" :label="item"></el-option>
							</el-select>
						</template>
					</el-table-column>
				</el-table>
		  </el-col>
		  <el-col :span="10">
			  <el-form label-width="80px">
			  <el-form-item label="页面">
				  <el-cascader v-model="link" :options="pageList" clearable separator="-" :props="{ label: 'name', value: 'name' }" @change="changed" class="sj"></el-cascader>
			  </el-form-item>
			  <el-form-item label="显示字段">
				<el-select v-model="showField" placeholder="请选择" style="display: inline-block;">
					<el-option v-for="(com,idx) in coms" :value="com.field" :label="com.field"></el-option>
				</el-select>
			</el-form-item>
			</el-form>
			<div style="height: 350px;"></div>
			  <el-button type="success" size="mini" @click="visible=false">取消</el-button>
			  <el-button type="primary" size="mini" @click="confirm">确定</el-button>
		  </el-col>
	  </el-row>
	</el-dialog>
    <el-button type="primary" size="mini" @click="visible=true" :title="label" style="border:1px solid cadetblue;">{{label||'设置'}}</el-button>
	</div>
</template>

<script>
export default {
  data () {
    return {
		visible: false,
		label: '',
		pageList:[],
		to: [],
		from: [],
		link: [],
		showField:'',
		setFields:[]
    }
  },
  created: function () {
		  if(this.look){
		  		  this.link = this.look.link
		  		  this.showField = this.look.showField
		  		  this.setFields.push(...this.look.setFields)
		  }
		  this.label = this.link.join('-')
  },
  methods: {
	  opened(){
		  for(let i = 0; i < this.coms.length; i++){
		  		  let has = this.setFields.some(item=>{return item.to == this.coms[i].field})
		  		  if(!has) this.setFields.push({to: this.coms[i].field,from: ''})
		  }
		  this.search()
	  },
	  search () {
	    let fun = { module: 'ui', action: 'getList', prms: { sysId: this.$route.params.id } }
	    this.common.request(fun, this.setData.bind(this))
	  },
	  setData (r) {
	    if (r.code === 0) {
	      r = r.data[0]
	      this.pageList = []
	      for (var i = 0; i < r.length; i++) {
	        r[i].ui = JSON.parse(unescape(r[i].json))
	        const fil = this.pageList.filter(item => item.name === r[i].type)
	        if (fil.length === 0) {
	          const node = { name: r[i].type, children: [r[i]] }
	          this.pageList.push(node)
	        } else {
	          fil[0].children.push(r[i])
	        }
	      }
	    }
		if(this.link) this.changed(this.link)
	  },
	  changed(item){
		  if(item.length==0) return
		  this.from = []
		  let json = null;
		  for (var i = 0; i < this.pageList.length; i++) {
		    if(this.pageList[i].name == item[0] && this.pageList[i].children){
				for(let j = 0; j < this.pageList[i].children.length; j++){
					if(this.pageList[i].children[j].name == item[1]) json = this.pageList[i].children[j].json
				}
			}
		  }
		  let cols = JSON.parse(unescape(json)).coms[0].cols
		  for(let m = 0; m < cols.length; m++){
			  this.from.push(cols[m].field)
		  }
		  console.log(this.setFields)
	  },
	  confirm(){
		  let temp = {}
		  temp.link= this.link
		  temp.showField=this.showField
		  temp.setFields=[]
		  for(let i = 0; i < this.setFields.length; i++){
			  if(this.setFields[i].from) temp.setFields.push(this.setFields[i])
		  }
		  console.log(temp)
		  this.$emit('setLook', temp)
		  this.label = temp.link.join('-')
		  this.visible = false
	  }
  },
  model: {
    prop: 'look',
    event: 'setLook'
  },
  props: {
	  look: Object,
	  coms: Array
  }
}
</script>

<style scoped="scoped">
  .self{display: inline-block;}

</style>
