<template>
  <div>
	  <el-button plain @click="sysSet" size="mini" type="primary">系统设置</el-button>
	<el-tabs type="border-card" v-model="active">
	  <el-tab-pane label="权限" name="权限">
		  <el-button class="btn" plain size="mini" @click="add" type="primary">新建</el-button>
		  <el-button class="btn" plain size="mini" @click="search" type="warning">刷新</el-button>
		  <el-tree :data="list" node-key="id" default-expand-all @node-click="nodeClick">
		  <span class="custom-tree-node" slot-scope="{ node, data }">
			<span>{{ data.name }}</span>
			<span>
				<i v-if="node.isLeaf" class="el-icon-delete" @click.stop="del(node,data)"></i>
			</span>
		  </span>
		</el-tree>
	  </el-tab-pane>
	</el-tabs>
    
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [],
	  active: '权限'
    }
  },
  created: function () {
    this.search()
  },
  methods: {
	  getTree(arr,pId){
	    	let newArr = []
	    	for(let i = 0; i < arr.length; i++){
	    		if(arr[i].pId == pId){
	    			newArr.push(arr[i])
	    			let childs = this.getTree(arr,arr[i].id)
	    			if(childs.length>0) arr[i].children = childs
	    		}
	    	}
	    	return newArr
	    },
	  search () {
	    const data = { module: 'RemoteDb', action: 'GetRights', prms: { sysId: this.$route.params.id } }
	    this.common.request(data, this.getDataCb.bind(this))
	  },
	  getDataCb (r) {
	  	if(r.code == 0){
	  		this.list = this.getTree(r.data,0)
	  	} else this.$message.error(r.msg)
	  },
    del (node,right) {
      this.$confirm('确定删除权限[ ' + right.name + ' ]吗?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const fun = { module: 'RemoteDb', action: 'DelRight', prms: { id: right.id,sysId: this.$route.params.id } }
        this.common.request(fun, this.delCallback.bind(this,right))
      })
    },
    delCallback (right,r) {
      if (r.code === 0) {
		  this.$emit('del',right)
		  this.search()
	  }
      else this.$message.error(r.msg)
    },
	sysSet(){
		this.$emit('sysSet')
	},
	add(){
		this.$emit('add')
	},
	nodeClick(data, node) {
        this.$emit('selected', data)
    }
  },
  props: {
    sysId: String
  }
}
</script>

<style scoped="scoped">
  .btn{
    margin-left: 1px;
    padding: 3px 3px;
  }
  .el-tabs{
    height: calc(100vh - 110px);
  }
</style>
