<template>
    <div class='jf-role jf-flex' :style="[com.style,{backgroundColor:com.outsideBgColor}]">
        <div class="role-warp jf-flex" :style="{width:com.width+'%',height:com.height+'px',backgroundColor:com.insideBgColor,color:com.fontColor}">
            <div>{{com.title}}</div>
            <div class="jf-flex">
                <div class="">{{ roleInfo.name }}</div>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <el-dialog
            :title="com.title"
            :visible.sync="dialogVisible"
            width="30%">
            <el-select v-model="com.role" placeholder="请选择">
                <el-option
                v-for="item in com.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="changeRole">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            roleInfo: {},
            dialogVisible: false
        };
    },
    created() {},
    methods: {
        openBox() {
            if(this.roleInfo.id) {
                this.com.role = this.roleInfo.id
            }
            this.dialogVisible = true;
        },
        changeRole() {
            const role = this.com.data.find(item => item.id === this.com.role);
            this.roleInfo = role ? role : {};
            this.close()
        },
        close() {
            this.dialogVisible = false
        }
    },
    props:{
        com: Object
    }
};
</script>

<style scoped>
    .jf-flex {
        display: flex;
        align-items: center;
    }
    .jf-role {
        justify-content: center;
    }
    .jf-role .role-warp {
        width: 200px;
        border: 1px solid #ccc;  
        padding: 10px;
        justify-content: space-between;
    }
</style>
