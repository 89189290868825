<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="配置">
          <el-color-picker v-model="com.color"></el-color-picker>
          <el-radio-group v-model="com.type">
            <el-radio :label="2">实线</el-radio>
            <el-radio :label="1">虚线</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    	<jfExplain v-model="drawer" :name="com.cName || '分割线'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '分隔线就是一个用于元素直接分隔的线条，可以通过配置它的颜色、高度、虚线或者实线来展示分隔效果。',
      fieldData:[]
    }
  },
  props: { com: {} }
}
</script>
