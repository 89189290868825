<template>
  <div class="main" :style="[com.style,comStyle]">
    <div :class="'all groupStyle'+com.mode" v-for="(item, index) in com.data" :key="index">
    <img :src="$route.params.url+item.imgUrl" :onerror="common.imgOnError" />
		<div style="font-size: 16px;">{{item.title||'标题'}}</div>
		<div style="font-size: 14px;">{{item.introduction||'简介'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null,
      comStyle:{}
    }
  },
  methods: {
    getComStyle() {
      let that = this;
      let com = that.com;
      that.comStyle = {
        backgroundColor: com.bgColor || "" ,
        color:  com.fontColor || "",
        opacity: com.opcity / 100 || 1
      }
    }
  },
  created: function () {
    this.getComStyle()
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>
<style scoped>
  .main{
		display: flex;
		flex-direction: column;
    background-color: #fff;
	}
	.all{
		padding: 10px;
		border-bottom: 1px solid #d0d8f4;
	}
	.groupStyle0 img{
		float: left;width: 56px;height: 56px;margin-right: 10px;
	}
	.groupStyle1 img{
	  float: right;width: 56px;height: 56px;
	}
	.groupStyle2 img{
    width: 100%;
	}
</style>