<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <!-- <el-form-item label="是否多选">
        <el-radio-group v-model="com.isCheckbox" @input="handler">
          <el-radio-button :label="true">多选</el-radio-button>
          <el-radio-button :label="false">单选</el-radio-button>
        </el-radio-group>
      </el-form-item> -->
    </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '城市'"  :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '城市组件可以将城市进行首拼音进行分类显示，在移动端可以滑动右侧首字母还进行快速定位到所需城市。',
      fieldData:[]
    }
  },
  methods: {
    handler() {
      console.log(this.com.isCheckbox);
    }
  },
  props: { com: Object }
}
</script>
