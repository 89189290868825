<template>
    <div :style="[com.style,com.tabPosition ? {'display':'flex'} : {}]">
        <div>
            <div v-for="(item, index) in com.searchComs" :key="index" :style="[item.style]" class="cell">
                <!-- <div class="searchLabel">{{item.label}}：</div> -->
                <!-- <el-input v-if="item.type == 'input'" v-model="com.fun.prms[item.field]"></el-input>
                <el-select v-if="item.type == 'select'" v-model="com.fun.prms[item.field]">
                    <el-option label="None" value=""></el-option>
                    <el-option v-for="(item,cIx) in item.options" :key="cIx" :label="item.text" :value="item.value"></el-option>
                </el-select>
                <el-date-picker v-if="item.type == 'date'" v-model="com.fun.prms[item.field]" type="date" placeholder="选择日期"></el-date-picker>
                <el-time-select v-if="item.type == 'time'" v-model="com.fun.prms[item.field]" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
                <el-date-picker v-if="item.type == 'datetime'" v-model="com.fun.prms[item.field]" type="datetime" placeholder="选择日期时间"></el-date-picker>
                <button v-if="item.type == 'button'">{{ item.label }}</button> -->
                <div  v-if="item.type == 'tabs'" class='tabs flex' :class="[item.options.length > (com?.tabScrool || 5) ? 'isTabsScroll' : '']" :style="[item.style,com.tabPosition ? tabStyleLeft : {}]">
                    <div @click="hasCom(index)" v-for="(btn,index) in item.options" :key="index" :class="[index == current ? 'active' : 'tabs-item',(com?.tabScrool || 5) ? 'isItemScroll' : '']" :style="[com?.tabStyle,{'color': index == current ? com.tabActiveColor : '','fontSize': '18px','fontWeight': 400}]">{{btn.text}}</div>
                </div>
            </div>
        </div>
        <div class="jf-text-tag" :style="[com.cartWarpStyle]">
            <div class="list" v-for="(item,index) in list" :key="index" :style="[com.cartStyle,item.style|| {},{backgroundColor:com.bgColor}]">
                <div class="header between" v-if="com.title.show || com.subtitle.show">
                    <div class="title" v-if="com.title.show && com.title.label.length > 0" :style="[com.title?.style,{color:com.title.fontColor}]">{{ com.title.label + (item[com.title.field] || "") }}</div>
                    <div class="subtitle" v-if="com.subtitle.show && com.subtitle.label.length > 0" :style="[com.subtitle?.style,{color:com.subtitle.fontColor}]">{{ com.subtitle.label + (item[com.subtitle.field] || "") }}</div>
                </div>
                <div class="wrap">
                    <div class="label-list flex" :style="isFlex" v-for="(child, i) in item.data" :key="i">
                        <div class="t-left flex" style="display:flex;flex-direction: column;align-items: flex-start;">
                            <div class="flex" v-for="(col, ind) in com.cols" :key="ind" v-show="col.position == 'left' || col.position == 'center'">
                                <template v-if="col.position == 'left' || col.position == 'center'">
                                    <div :style="[col.labelStyle]" class="label" v-if="col.label && col.label !='' && !col.hide">{{ col.label }}</div>
                                    <template v-if="col.type == 'text' && !col.hide">
                                        <div class="text" :style="[col.style || {}]">{{ child[col.field] }}</div>
                                    </template>
                                    <template v-if="col.type == 'richText' && !col.hide">
                                        <div class="richText" :style="[col.style || {}]" v-html="child[col.field]"></div>
                                    </template>
                                    <template v-if="col.type == 'switch' && !col.hide">
                                        <div :style="[col.style || {}]">
                                            <el-switch v-model="child[col.field]"></el-switch>
                                        </div>
                                    </template>
                                    <template v-if="col.type == 'numberBox' && !col.hide">
                                        <div :style="[col.style || {}]">
                                            <el-input-number v-model="child[col.field]" :min="1" size="mini"></el-input-number>
                                        </div>
                                    </template>
                                    <img class="l-img" v-if="com.image.show && col.type == 'img' && !col.hide && (col.position == 'left' || col.position == 'center')" :src="$route.params.url+child[col.field]" :onerror="common.imgOnError" :style="[col.style || {}]" alt="">
                                    <img class="l-img" v-if="com.image.show && col.type == 'video' && !col.hide && (col.position == 'left' || col.position == 'center')" :src="$route.params.url+child[col.field]" :onerror="common.imgOnError" :style="[col.style || {}]" alt="">
                                    <!-- <img class="l-img" v-if="com.image.show && col.type == 'img' && !col.hide" :src="child[col.field]" :style="[col.style || {}]"  alt=""> -->
                                </template>
                            </div>
                        </div>
                        <div class="t-right">
                            <div class="flex" v-for="(col, ind) in com.cols" :key="ind" v-show="col.position == 'right' || col.position == '' || !col.position">
                                <template v-if="col.position == 'right' || col.position == '' || !col.position">
                                    <div :style="[col.labelStyle]" class="label" v-if="col.label && col.label !='' && !col.hide">{{ col.label }}</div>
                                    <template v-if="col.type == 'text' && !col.hide">
                                        <div class="text" :style="[col.style || {}]">{{ child[col.field] }}</div>
                                    </template>
                                    <template v-if="col.type == 'richText' && !col.hide">
                                        <div class="text" :style="[col.style || {}]" v-html="child[col.field]"></div>
                                    </template>
                                    <template v-if="col.type == 'img' && !col.hide">
                                        <img class="l-img" :src="$route.params.url+child[col.field]" :onerror="common.imgOnError" :style="[col.style || {}]"  alt="">
                                        <!-- <img class="l-img" :src="child[col.field]" :style="[col.style || {}]"  alt=""> -->
                                    </template>
                                    <template v-if="col.type == 'video' && !col.hide">
                                        <img class="l-img" :src="$route.params.url+child[col.field]" :onerror="common.imgOnError" :style="[col.style || {}]"  alt="">
                                        <!-- <img class="l-img" :src="child[col.field]" :style="[col.style || {}]"  alt=""> -->
                                    </template>
                                    <template v-if="col.type == 'switch' && !col.hide">
                                        <div :style="[col.style || {}]">
                                            <el-switch v-model="child[col.field]"></el-switch>
                                        </div>
                                    </template>
                                    <template v-if="col.type == 'numberBox' && !col.hide">
                                        <div :style="[col.style || {}]">
                                            <el-input-number v-model="child[col.field]" :min="1" size="mini"></el-input-number>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer flex" v-if="com.buttons.length > 0">
                    <button class="btn-list"  v-for="btn in com.buttons" :class="btn.type" :style="[btn.style]" :key="btn.label"
                    :disabled="(btn.hideBy?calc(item,btn.hideBy):true) && !btn.hide && btn.hideByType=='condition'"
                    >{{ btn.label }}</button>
                </div>
            </div>
        </div>
		<view class="empty" v-if="list.length==0" style="padding: 50px;background-color: #fff;">
			<view class="flex" style="flex-direction: column;">
				<!-- <uni-icons type="more" size="90" color="#888"></uni-icons> -->
				<view>空空如也</view>
			</view>
		</view>
    </div>
</template>

<script>
export default {
    data() {
        return {
			initList:[],
            list:[],
			state: "",
			total:0,
            isFlex: {
                display: 'flex'
            },
            tabStyleLeft: {
                display: 'flex',
                flexDirection: "column",
                height: 'auto'
            },
            current: this.com.current || 0
        };
    },
    created() {
		this.search()
	},
    watch: {
        'com.cols': {
            deep: true,
            immediate: true,
            handler: function (newValue, oldValue) {
                this.handleListStyle()
            },
        },
        'com.data': {
            deep: true,
            immediate: true,
            handler: function (newValue, oldValue) {
		        this.search()
            },
        },
    },
    methods: {
        handleListStyle() {
            this.com.cols.forEach((item)=>{
                if(item.position == 'center') {
                    this.isFlex.display = 'inline-block'
                }else if(item.position == 'left') {
                    this.isFlex.display = 'flex'
                }
            })
        },
		search() {
			this.list = [];
			this.getData();
		},
		getData(){
			// if(this.com.fun) {
			// 	if(this.com.fun.prms){
			// 		if(this.com.fun.prms.page!="") this.com.fun.prms.page = parseInt(this.com.fun.prms.page)
			// 		if(this.com.fun.prms.limit!="") this.com.fun.prms.limit = parseInt(this.com.fun.prms.limit)
			// 	}
			// 	let listParms = {
			// 		state: this.state
			// 	}
			// 	Object.assign(this.com.fun.prms,listParms,this.$root.query);
			// 	this.common.request(this.com.fun,this.getDataCb.bind(this))
			// } else 
            this.pushData(this.com.data)
		},
		getDataCb (r) {
			if(r.code == 0) {
				this.pushData(r.data[0])
				this.total = r.data[1][0].count;
			}
		},
		pushData(arr){
			// 相同订单商品合并
			let initArr = []
			let mergedArr= []
			mergedArr = arr.reduce((result, obj) => {
			  let existingGroup = result.find(group => group.id === obj.id);
			  if (existingGroup) {
				existingGroup.data.push(obj);
			  } else {
				result.push({orderId: obj.id,...obj,data: [obj] });
			  }
			  initArr.push(obj)
			  return result;
			}, []);
			this.initList =   initArr
			this.list =  mergedArr
		},
        hasCom(index){
            this.current = index
        },
		calc(info,condition){
			if(!condition || condition == '') return false
			let arr = condition.split(/(==|!=|>=|<=|>|<)/)
			if(arr.length != 3) arr = [condition,'==',1]
			let field = Number.isFinite(+arr[2]) ? parseFloat(info[arr[0]]) :info[arr[0].trim()]
			let value = Number.isFinite(+arr[2]) ? parseFloat(arr[2]) : arr[2].trim()
			switch(arr[1]){
				case '>': return field > value
				case '<': return field < value
				case '>=': return field >= value
				case '<=': return field <= value
				case '==': return field == value
				case '!=': return field != value
			}
		},
		getBinding(){
		  return [{label: '图片',field:'params.images'},{label: '描述',field:'params.info'},{label: '商品展示列表',field:'list'},{label: '商品传参列表',field:'initList'},{label: '总价',field:'allMoney'},{label: '第一条数据id',field:'orderId'},{label: '状态',field:'state'},{label: '刷新列表',field:'search()'}]
		}
    },
    props:{com:Object}
};
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
    .between {
        justify-content: space-between;
    }
    .jf-text-tag {
		width: 100%;
        padding: 10px;
        font-size: 14px;
    }
    .list {
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: #fff;
    }
    .header {
        height: 40px;
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }
    .header .title {
        font-weight: 600;
        float: left;
        font-size: 18px;
    }
    .header .subtitle {
        color: #ccc;
        float: right;
        text-align: right;
        font-size: 16px;
        float: right;
    }
    .wrap {
        font-size: 12px;
        padding: 10px;
    }
    .label-list {
        width: 100%;
        align-items: flex-start;
    }
    .wrap .t-right {
        flex: 1;
        width: 100%;
        padding: 0 10px;
    }
    .l-img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
    .wrap .label {
        color: #000;
    }
    .wrap .text {
        color: #777;
    }
    .footer {
        justify-content: flex-end;
        padding: 10px;
        flex-wrap: wrap;
    }
    .footer .btn-list{
        padding: 5px 10px;
        border-radius: 20px;
        margin-left: 5px;
        margin-top: 5px;
        font-size: 10px;
    }
    .primary{	  border: 1px solid #409EFF;}
    .success{	  border: 1px solid #67C23A;}
    .info{	  border: 1px solid #909399;}
    .warning{	  border: 1px solid #E6A23C;}
    .danger{	  border: 1px solid #F56C6C;}
    
    .tabs {
        background-color: #fff;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        width: 100%;
	}
    .tabs .tabs-item {
        flex: 1;
    }
    .isTabsScroll {
        overflow-x: scroll;
    }
    .isItemScroll {
        flex-shrink: 0;
        width: auto;
        flex: none !important;
    }
	.active {flex: 1;font-size: 18px!important;font-weight: 600!important;}
</style>
