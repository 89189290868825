<template>
    <div class='jf-cart' :style="[com.style]">
        <div class="header flex">
            <div>管理</div>
        </div>
        <div class="goods-list">
            <div v-for="(item, index) in com.data" :key="index" class="fl h100">
                <div class="listImg left h100">
                    <img :src="$route.params.url+item.imgUrl"/>
                </div>
                <div class="text right h100">
                    <div class="r-top">
                        <div class="goods-name show-ellipsis">{{item.name||'商品名称'}}</div>
                        <div class="goods-desc show-ellipsis">{{item.sku||'商品描述'}}</div>
                    </div>
                    <div class="flex card-bottom">
                        <div class="goods-price" :style="{color: com.priceColor}">
                            ￥<span class="big-font">{{item.price||'商品价格'}}</span>
                        </div>
                        <div class="goods-num">
                            <el-input-number v-model="item.number" :min="1" size="small"></el-input-number>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
    props: {
        com: Object
    }
};
</script>

<style scoped>
    .h100 {
        height: 100%;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .header {
        justify-content: flex-end;
    }
    .jf-cart {
		padding: 10px;
	}
	.jf-cart *:not(:last-child) {
	    margin: 0 0 10px 0;
	}
	.fl {
		display: flex;
		width: 100%;
		font-size: 14px;
	    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	    background-color: #FFFFFF;
	}
	.fl img {
	    width: 100%;
	    height: 100%;
	}
	.fl .left {
		width: 30%;
	}
	.fl .right {
		text-align: left;
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px;
		box-sizing: border-box;
	}
	.fl .goods-name {
		color: #434343;
	}
	/* 省略号显示 */
	.show-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* 显示的行数 */
		-webkit-box-orient: vertical;
	}
	.fl .goods-price {
		color: #ff4142;
	}
	.fl .big-font {
		font-size: 16px;
	}
	.old-price {
		margin-left: 10px;
		color: #ccc;
	}
    .card-bottom {
        justify-content: space-between;
    }
</style>
