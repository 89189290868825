<template>
  <div class="jfRedisView">
    <el-row>
      <el-col :span="4">
        <el-button class="btnRedis" plain size="mini" @click="search" type="primary">刷新</el-button>
        <el-button class="btnRedis" plain  size="mini" @click="del()" type="danger">全部删除</el-button>
      </el-col>
      <el-col :span="4">
        <el-input size="mini" v-model="pre" placeholder="前缀"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button class="btnRedis" plain  size="mini" @click="filte" type="primary">搜索</el-button>
      </el-col>
    </el-row>
    <el-container>
      <el-aside width="300px">
        <div class="rdata">
          <el-row v-for="(item,index) in list" :key="index" @click.native="rowClick(item)">
            <el-col :span="20">{{item}}</el-col>
            <el-col :span="4"><el-tag class="tag" type="danger" @click.stop="del(item)">删除</el-tag></el-col>
          </el-row>
        </div>
        <el-row><div class="cnt">总数量：{{count}}</div></el-row>
      </el-aside>
      <el-main>
        <jfJsonView v-if="current" :json="current"></jfJsonView>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      redisNo: '',
      pre: '',
      data: [],
      list: [],
      count: 0,
      current: null,
      cellStyle: {
        padding: '2px'
      },
      loading: null
    }
  },
  created: function () {
    this.search()
  },
  methods: {
    search () {
      // this.loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      this.data = []
      this.list = []
      this.count = 0
      const fun = { module: 'RemoteRedis', action: 'GetRedisData', prms: { sysId: this.$route.params.id } }

      if (this.redisNo) fun.prms.redisNo = this.redisNo
      else if (this.comData.redis) fun.prms.redisNo = this.comData.redis
      this.common.request(fun, this.setData.bind(this))
    },
    setData (r) {
      // this.loading.close()
      if (r.code === 0) {
        this.data = r.data
        for (let i = 0; i < this.data.length; i++) {
          this.list.push(this.data[i])
        }
        this.count = this.list.length
      } else this.$message(r.msg)
    },
    rowClick (key) {
      // this.loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      const fun = { module: 'RemoteRedis', action: 'GetRedisData', prms: { sysId: this.$route.params.id } }
	  if (this.redisNo) fun.prms.redisNo = this.redisNo
      fun.prms.key = key
      this.common.request(fun, this.detailCb.bind(this))
    },
    detailCb (r) {
      // this.loading.close()
      this.current = r.data
    },
    del (key) {
      this.loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      const fun = { module: 'RemoteRedis', action: 'DelRedisData', prms: { sysId: this.$route.params.id } }
      if (this.redisNo) fun.prms.redisNo = this.redisNo
      if (key) fun.prms.key = key
      this.common.request(fun, this.callback.bind(this))
    },
    callback (r) {
      this.loading.close()
      if (r.code === 0) this.search()
      else this.$message(r.msg)
    },
    setPhone (key) {
      // this.loading = this.$loading({lock: true,text: 'Loading',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
      this.common.request({ ctrl: '1', action: 'setPhone' }, this.setPhoneCb.bind(this), { server: 'http://localhost:81/' }) // this.$route.params.url
    },
    setPhoneCb (r) {
      this.loading.close()
      if (r.code != 0) this.$message(r.msg)
    },
    filte () {
      this.list = []
      const l = this.data.filter(item => item.indexOf(this.pre) != -1)
      for (let i = 0; i < l.length; i++) {
        this.list.push(l[i])
      }
      this.count = this.list.length
    }
  },
  props: {
    comData: Object
  }
}
</script>

<style scoped="scoped">
  .jfRedisView{
    height: 100%;
    overflow: auto;
    border: 1px solid #ddd;
  }
  .el-container{height: calc(100% - 20px); border-top: 1px solid #ddd;}
  .rdata {height: 600px; overflow: auto;}
  .rdata>div:hover{
        background-color: #ddd;
        cursor: pointer;
    }
  .btnRedis{
    margin-left: 1px;
	margin-top: 4px;
    padding: 3px 3px;
  }
  .tag{
    cursor: pointer;
  }
  .cnt{
    background-color: bisque;
    padding: 10px;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
</style>
