import { render, staticRenderFns } from "./jfGoodsCard.vue?vue&type=template&id=905be35c&scoped=true&"
import script from "./jfGoodsCard.vue?vue&type=script&lang=js&"
export * from "./jfGoodsCard.vue?vue&type=script&lang=js&"
import style0 from "./jfGoodsCard.vue?vue&type=style&index=0&id=905be35c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "905be35c",
  null
  
)

export default component.exports