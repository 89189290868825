<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="2">
          外底色
        </el-col>
        <el-col :span="6">
          <el-color-picker v-model="com.outsideBgColor"></el-color-picker>
        </el-col>

        <el-col :span="2">
          内底色
        </el-col>
        <el-col :span="6">
          <el-color-picker v-model="com.insideBgColor"></el-color-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          文字颜色
        </el-col>
        <el-col :span="6">
          <el-color-picker v-model="com.fontColor"></el-color-picker>
        </el-col>
        <el-col :span="2">
          提示文字
        </el-col>
        <el-col :span="6">
          <el-input v-model="com.placeholder"></el-input>
        </el-col>
      </el-row>
    	<jfExplain v-model="drawer" :name="com.cName || '搜索'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '搜索组件不用于真实搜索，负责展示及跳转，通过设置提示文字表达搜索需求。',
      fieldData:[
        {field: 'placeholder',explain: '提示文本', type: 'string'}
      ]
    }
  },
  methods: {
  },
  props: { com: {Object} }
}
</script>
