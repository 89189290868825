<template>
    <div class='tabs-editor'>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i
						class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item class="margin-tb" label-width="0px">
				<div style="display: flex;margin: 10px 5px;">
					<el-button style="flex: .5;" type="primary" plain size="mini"
						@click="btnVisible = true">tab栏</el-button>
				</div>
			</el-form-item>
			<el-form-item label="标签栏">
					<div style="margin-bottom: 10px;display: flex;align-items:center">
							<span>高亮颜色：</span>
							<el-color-picker v-model="com.activeColor"></el-color-picker>
					</div>
					<div style="margin-bottom: 10px;">
							<div>超出滚动个数：</div>
							<el-input-number size="mini" v-model="com.tabScrool" :min="2"></el-input-number>
					</div>
					<div style="margin-bottom: 10px;display: flex;align-items:center">
							<span>样式：</span>
							<jfStyle v-model="com.tabStyle"></jfStyle>
					</div>
			</el-form-item>
			<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '标签栏'" :fieldData="fieldData"
					:requestData="requestData">
			</jfExplain>
        </el-form>
        <!-- 顶部tab栏 -->
		<el-drawer ref="btmTable" :direction="'btt'" :with-header="false" title="列表设置" :size="btmSize"
			:visible.sync="btnVisible" :append-to-body="true">
			<div class="spliter" @mousedown="mousedown($event, $refs.btmTable)"></div>
			<div class="drw">
				<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
				<el-button type="primary" size="mini" @click="addButton(com.buttons)">添加按钮</el-button>
				<el-button type="warning" class="el-icon-top" size="mini" v-if="currBtn"
					@click="upGo(com.buttons, currBtn)">上移</el-button>
				<el-button type="warning" class="el-icon-bottom" size="mini" v-if="currBtn"
					@click="downGo(com.buttons, currBtn)">下移</el-button>
				<el-table :data="com.buttons" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row
					@current-change="currBtn = $event">
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<div class="el-icon-close" @click="deleteGo(com.buttons, scope.$index)"></div>
							<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
						</template>
					</el-table-column>
					<el-table-column label="标签" width="80">
						<template slot-scope="scope">
							<input v-model="scope.row.label" :style="scope.row == currBtn ? 'color:red;font-size:14px' : ''"
								placeholder="按钮文本"></input>
						</template>
					</el-table-column>
					<el-table-column label="传参字段" width="80">
						<template slot-scope="scope">
							<input v-model="scope.row.params" :style="scope.row == currBtn ? 'color:red;font-size:14px' : ''"
								placeholder="字段名称"></input>
						</template>
					</el-table-column>
					<el-table-column label="颜色" width="50">
						<template slot-scope="scope">
							<el-dropdown @command="scope.row.type = $event">
								<el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="primary">主要</el-dropdown-item>
									<el-dropdown-item command="success">成功</el-dropdown-item>
									<el-dropdown-item command="info">信息</el-dropdown-item>
									<el-dropdown-item command="warning">警告</el-dropdown-item>
									<el-dropdown-item command="danger">危险</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
					<el-table-column label="动作" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.actionType" placeholder="请选择">
								<el-option label="访问接口" value="fun"></el-option>
								<!-- <el-option label="打开页面" value="link"></el-option>
								<el-option label="弹出页面" value="dialog"></el-option>
								<el-option label="滑出页面" value="drawer"></el-option>
								<el-option label="导入" value="import"></el-option>
								<el-option label="导出" value="export"></el-option>
								<el-option label="下载" value="download"></el-option>
								<el-option label="刷新" value="refresh"></el-option>
								<el-option label="返回" value="back"></el-option> -->
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="权限" width="110">
						<template slot-scope="scope">
							<selectorRight v-model="scope.row.rightId"></selectorRight>
						</template>
					</el-table-column>
					<el-table-column label="禁用条件" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.hideByType" placeholder="请选择">
								<el-option label="按条件" value="condition"></el-option>
							</el-select>
							<input v-if="scope.row.hideByType == 'condition'" v-model="scope.row.hideBy"
								placeholder="> < <= >= == !="
								style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
						</template>
					</el-table-column>
					<el-table-column label="后置操作" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.after">
								<el-option label="关闭" value="close"></el-option>
								<el-option label="刷新" value="refresh"></el-option>
								<el-option label="无" value=""></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="控制类型" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.controlType">
								<el-option label="针对选中项(默认)" value="selected"></el-option>
								<el-option label="针对全部项" value="all"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="动作类型" width="250">
						<template slot-scope="scope">
							<selectorPage v-if="['link', 'dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.link" :comData="comData"></selectorPage>
							<selectorPage v-if="['link', 'dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.linkM" :type="'M'" :comData="comData"></selectorPage>
							<el-checkbox v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.backRefresh">关闭刷新</el-checkbox>
							<input v-if="['dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.windowWidth" placeholder="宽度"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
							<selectorFun v-if="['fun', 'import'].includes(scope.row.actionType)" v-model="scope.row.fun"
								:comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
							<input v-if="scope.row.actionType == 'fun'" v-model="scope.row.funTip" placeholder="提示信息"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;" />
							<input v-if="scope.row.actionType == 'download'" v-model="scope.row.link" placeholder="下载地址"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;" />
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
			desc: `标签栏做为一个辅助组件，通常用于多个不同类型的切换，设置接口的参数为设置的字段名label`,
			fieldData: [],
			requestData: [],
			btnVisible: false,
			btmSize: '400px',
			currBtn: null,
        };
    },
    created() {},
    methods: {
        addButton (btns) {
			btns.push({ label: '', type: 'primary',rightId: '0', actionType: 'fun', fun: null, link: '',fields:[] })
		},
		getIndex(arr,row){
			for(let i = 0; i < arr.length; i++){
				if(arr[i] == row) return i
			}
		},
		upGo (arr, row) {
			let index = this.getIndex(arr,row)
			if (index !== 0) {
				arr[index] = arr.splice(index - 1, 1, arr[index])[0]
			} else {
				arr.push(arr.shift())
			}
		},
		downGo (arr, row) {
			let index = this.getIndex(arr,row)
			if (index !== arr.length - 1) {
				arr[index] = arr.splice(index + 1, 1, arr[index])[0]
			} else {
				arr.unshift(arr.splice(index, 1)[0])
			}
		},
		deleteGo (arr, index) {
			arr.splice(index, 1)
		},
		mousedown(evt, sourceDom) {
			let height = parseInt(sourceDom.size)
			let pageY = evt.pageY
			let start = true
			this.$refs.fc.style.display = 'block'
			let that = this
			document.onmousemove = (event) => {
				if (start) {
					// sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
					that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
				}
			};
			document.onmouseup = () => {
				this.$refs.fc.style.display = 'none'
				document.onmousemove = null;
				document.onmouseup = null;
				start = false
				return false;
			}
		}
    },
    props:{com:Object, saveMethod: Function ,comData: Object,}
};
</script>

<style scoped>

</style>
