<template>
	<div class="ctlg-page" :style="[com.style,{height:com.ctlgHeight+'vh'}]">
		<div class="moduleItem h100">
			<div class="left h100" :style="{backgroundColor:com.lBgColor}">
				<div :class="[item.id == leftIndex ? 'l-item-active' : 'l-item']" v-for="(item,index) in menuList" :key='item.id' @click="changeCtlg(item)">
					<div class="module_title">
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="right h100" :style="{backgroundColor:com.rBgColor}">
				<template v-if="leftContent.children && leftContent.children.length > 0">
					<div  v-for="(itm2,k) in leftContent.children" :key="k">
						<template v-if="itm2.pId == leftIndex">
							<div class="class-title">
								<div>{{ itm2.name }}</div>
							</div>
							<el-row style="display: flex;flex-wrap: wrap;color: #777777;">
								<el-col class="ctlg-grid" v-for="(itm3,k3) in itm2.children" :index="itm3.id" :key="k3" @click="itemClick(item)" :span="8" style="margin: 10px 0;">
									<el-image v-if="itm3.imgUrl" style="width: 50px; height: 50px" :src="item.imgUrl" fit="fill"></el-image>
									<i v-else class="el-icon-picture" style="font-size: 35px;"></i>
									<div class="grid-content bg-purple">{{itm3.name}}</div>
								</el-col>
							</el-row>
						</template>
					</div>
				</template>
				<template v-else>
					<div class="h100" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<!-- <uni-icons type="images" size="60"></uni-icons> -->
						<div>暂时为空</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuList: [],
				leftIndex: 0,
				leftContent: {}
			}
		},
		mounted() {
			this.menuList = this.getTree(0,this.com.data)
			this.changeCtlg(this.menuList[0]);
		},
		methods: {
			getTree(pId,arr) {
				let children = [];
				for(var i = 0; i < arr.length; i++) {
					if(arr[i].pId == pId) {
						arr[i].children = this.getTree(arr[i].id,arr)
						children.push(arr[i])
					}
				}
				return children
			},
			changeCtlg(item) {
				this.leftIndex = item.id;
				this.leftContent = item;
			},
		},
		props: {
			com: Object
		}
	}
</script>

<style scoped>
	.com1{
		font-size: 30px;
		color: red;
	}
	.w100 {
		width: 100%;
	}
	.h100 {
		height: 100%;
	}
	.flex {
		display: flex;
		align-items: center;
	}
	.ctlg-page {
		width: 100%;
		height: 100%;
	}
	.moduleItem {
		width: 100%;
		height: 100%;
		display: flex;
		color: #333;
	}
	.moduleItem .left {
		width: 26%;
		overflow: auto;
		background-color: #f8f8f8;
		text-align: center;
		box-sizing: border-box;
	}
	.l-item {
		padding: 10px 0;
	}
	.l-item-active {
		color: #e93b3d;
		background-color: #fff;
		font-size: 34rpx;
		font-weight: 600;
		padding: 15px 0;
	}
	.moduleItem .right {
		width: 74%;
		overflow: auto;
		background-color: #fff;
	}
	.ctlg-grid {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: space-between;
	}
	.ctlg-grid img {
		width: 100%;
		transform: scale(0.8);
		border-radius: 20rpx;
	}
</style>