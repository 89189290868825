<template>
    <div class="popup-content order-content" :style="[com.style]">
        <div class="pay-content flex goods-price">
            <div class="price flex">￥<span class="big-font">{{com.allMoney}}</span></div>
            <div class="pay-type-list">
                <div v-if="com.wx" class="type-item b-b" @click="changePayType(1)">
                    <div class="icon yticon icon-weixinzhifu">
                        <img src="@/assets/img/weixin.png" style="width: 30px;height: 30px;"></img>
                    </div>
                    <div class="con">
                        <span class="tit">微信支付</span>
                        <span>推荐使用微信支付</span>
                    </div>
                    <input type="radio" :checked='com.payType == 1' name="payType" >
                </div>
                <div v-if="com.zfb" class="type-item b-b" @click="changePayType(2)">
                    <div class="icon yticon icon-alipay">
                        <img src="@/assets/img/zhifubao.png" style="width: 30px;height: 30px;"></img>
                    </div>
                    <div class="con">
                        <span class="tit">支付宝支付</span>
                    </div>
                    <input type="radio" :checked='com.payType == 2' name="payType" >
                </div>
            </div>
        </div>
        <div class="footer flex">
            <div class="btn" :style="[{color: com.afterAction.color,backgroundColor:com.afterAction.bgColor}]">确认支付</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {
        changePayType(){}
    },
    props:{com:Object}
};
</script>

<style scoped>
    .popup-content {
        font-size: 16px;
        height: 100%;
        background-color: #fff;
		overflow: auto;
		max-height: 100%;
		padding: 10px 10px 70px;
	}
    .order-content {
		padding: 0;
	}
    .price {
        color: #f2270c;
        text-align: center;
    }
    .price span {
        font-size: 23px;
    }
    .order-content .pay-content {
        flex-direction: column;
        justify-content: center;
        margin: 45px;
    }
    .order-content .footer {
        width: 100%;
        background: #fff;
        justify-content: center;
        height: 50px;
    }
    .order-content .footer .btn {
        padding: 5px 10px;
        width: 60%;
        text-align: center;
        background: #f2270c;
        color: #ffffff;
        border-radius: 25px;
        margin: auto;
    }
	.street {
		font-size: 15px;
		margin: 5px 0;
	}
	.get-coupon {
		padding: 3px 7px;
		background-color: #ff4243;
		color: #fff;
		border-radius: 15px;
	}
	.pay-type-list {
		margin-top: 10px;
		background-color: #fff;
		padding-left: 30px;
	}
    .type-item {
        height: 60px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px;
        font-size: 14px;
        position: relative;
    }
    .type-item .icon {
        width: 50px;
        font-size: 14px;
    }

    .type-item  .type-item .tit {
        margin-bottom: 2px;
    }

    .type-item .con {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
</style>
