<template>
    <div class='jf-column' :style="{backgroundColor:com.bgColor}">
        <div v-for="(item,index) in com.data" :key="index" class="list">
            <img v-if="com.columnType == 1" class="media" :src="item.imgUrl">
            <video v-else-if="com.columnType == 2" class="media" :src="item.videoUrl"
                preload="auto"
                id="my-video"
                webkit-playsinline="true"
                playsinline="true"
                x-webkit-airplay="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                controls="controls"
                x5-video-ignore-metadata="true"
                width="100%"
                height="100%">
             <p>当前视频不支持</p>
            </video>
            <div class="title" :style="{color:com.titleFontColor}">{{ item.title }}</div>
            <div class="subtitle" :style="{color:com.subtitleFontColor}">{{ item.subtitle }}</div>
            <div v-if="com.columnType == 3" class="imgs">
                <img class="media" :src="item.imgUrl">
                <img class="media" :src="item.imgUrl">
                <img class="media" :src="item.imgUrl">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>
    .jf-column {
        background-color: #fff;
        padding: 10px;
    }
    .list {
        margin-bottom:  10px;
    }
    .media {
        width: 100%;
        height: 200px;
        border-radius: 10px;
        overflow: hidden;
    }
    .title {
        font-weight: 600;
    }
    .subtitle {
        color: #999;
        font-size: 16px;
    }
    .imgs {
        height: 200px;
    }
    .imgs :nth-child(1) {
        height: 100%;
        width: 48%;
        float: left;
    }
    .imgs :nth-child(2),
    .imgs :nth-child(3) {
        height: 48%;
        width: 48%;
        float: right;
    }
    .imgs :nth-child(3) {
        margin-top: 2%;
    }
</style>
