<template>
	<div>
		<div>不用设置</div>
	</div>
</template>

<script>
export default {
	props: {
		com: Object
	}
}
</script>

<style scoped>

</style>