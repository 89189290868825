<template>
  <div class="100%">
    <el-row>
      <el-button plain size="mini" @click="add()" type="primary">新建</el-button>
      <el-button plain size="mini" @click="search()" type="primary">刷新</el-button>
    </el-row>
    <el-table :data="list" ref="table" row-key="id" :height="common.tableHeight-100" default-expand-all
		:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="name" label="名称" width="150"></el-table-column>
      <el-table-column prop="path" label="路径" width="200"></el-table-column>
      <el-table-column label="图标" width="150">
		  <template slot-scope="scope">
			  <img v-if="scope.row.icon" class="icon" :src="$route.params.url+scope.row.icon"></img>
		  </template>
      </el-table-column>
      <el-table-column prop="rightTypeName" label="客户端" width="80" align="center"></el-table-column>
      <el-table-column prop="isMenuName" label="是否菜单" width="80" align="center"></el-table-column>
      <el-table-column prop="weight" label="顺序" width="80" align="center"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
          <el-link type="danger" @click="del(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%" @closed="search">
      <component v-bind:is="dlgCom" :list="list" :comData="current" @cancel="cancelEdit"></component>
    </el-dialog>
  </div>
</template>

<script>
import sysRightEdit from './sysRightEdit.vue'
export default {
  components: { sysRightEdit },
  data () {
    return {
      dlgCom: '',
      list: [],
	  tableHeight: window.innerHeight - 100,
      selection: [],
      current: null,
      dialogVisible: false
    }
  },
  created: function () {
    this.search()
  },
  methods: {
    search () {
      const data = { module: 'RemoteDb', action: 'GetRights', prms: { sysId: this.$route.params.id } }
      this.common.request(data, this.getDataCb.bind(this))
    },
    getDataCb (r) {
		if(r.code == 0){
			this.list = this.common.getTree(r.data,0)
		} else this.$message.error(r.msg)
    },
    add () {
      this.dlgCom = 'sysRightEdit'
      this.current = { pId: 0, sysId: this.$route.params.id, name: '', key: '', redis: '', db: '', module: '', expiry: '', memo: '' }
      this.dialogVisible = true
    },
    edit (row) {
      this.dlgCom = 'sysRightEdit'
      this.current = row
      this.dialogVisible = true
    },
    del (row) {
      this.$confirm('确定删除权限[ ' + row.name + ' ]吗?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'RemoteDb', action: 'DelRight', prms: { id: row.id,sysId: this.$route.params.id } }
        this.common.request(data, this.delCallback.bind(this))
      })
    },
    delCallback (r) {
      if (r.code === 0) this.search()
      else this.$message.error(r.msg)
    },
    selectionChange (val) {
      this.selection = val
    },
    cancelEdit () {
		this.search()
      this.dialogVisible = false
    }
  }
}
</script>
<style lang='scss' scoped>
  .top{
    height: 100%;
  }
  
  .icon{
  	  width: 30px;
  	  height: 30px;
  }
</style>
