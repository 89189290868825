<template>
  <div>
    <el-button plain size="mini" type="primary" @click="execSql">执行</el-button>
    <el-input v-model="comData.sql" type="textarea" :rows="10"></el-input>
	<jfTable :comData="data"></jfTable>
    <!-- <el-tabs type="border-card" v-model="active">
      <el-tab-pane :key="item.label" v-for="item in tabs" :label="item.label" :name="item.label">
        <jfTable :comData="item.comData"></jfTable>
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      active: '',
      // tabs: [],
	  data: []
    }
  },
  created: function () {
    this.execSql()
  },
  methods: {
    execSql () {
      if (this.common.$.trim(this.comData.sql) === '') return
      const data = { module: 'RemoteDb', action: 'ExecSql', prms: { sysId: this.$route.params.id, sql: this.comData.sql } }
      data.prms.host = this.$route.params.host
      data.prms.subsys = this.$route.params.subsys
      this.common.request(data, this.callback.bind(this))
    },
    callback (r) {
		this.data = r.data
      // this.tabs = []
      // for (let i = 0; i < r.data.length; i++) {
      //   if (r.data[i].length > 0) this.tabs.push({ label: i + '', comData: r.data[i] })
      // }
    }
  },
  props: {
    comData: { sql: '' }
  }
}
</script>
<style>
</style>
