<template>
	<div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i
						class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item class="margin-tb" label-width="0px">
				<div style="display: flex;">
					<el-button style="flex: 1;" type="primary" plain size="mini"
						@click="btnVisible = true">tab栏</el-button>
					<el-button style="flex: 1;" type="primary" plain size="mini"
						@click="colVisible = true">列设置</el-button>
				</div>
			</el-form-item>
			<el-form-item label="导航栏">
				<div class="rows">
					<el-checkbox v-model="com.payState">待支付</el-checkbox>
					<el-checkbox v-model="com.consignState">待收货</el-checkbox>
					<el-checkbox v-model="com.successState">已完成</el-checkbox>
					<el-checkbox v-model="com.closeState">已取消</el-checkbox>
				</div>
			</el-form-item>
			<el-form-item label="是否需要收货地址">
				<div class="rows">
					<el-switch v-model="com.isAddr" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</div>
			</el-form-item>
			<el-form-item label="圆角">
				<div class="rows">
					<el-input-number v-model="com.borderRadius" :min="0" :max="100" :step="10" controls-position="right"
						size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="卡片高度">
				<div class="rows">
					<el-input-number v-model="com.cardHeight" :min="10" :max="1000" :step="10" controls-position="right"
						size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="背景颜色">
				<div class="rows">
					<el-color-picker v-model="com.bgColor"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="价格颜色">
				<div class="rows">
					<el-color-picker v-model="com.priceColor"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="删除订单接口">
				<div class="rows">
					<selectorFun v-model="com.funRemoveOrder" placement="bottom-end"></selectorFun>
				</div>
			</el-form-item>
			<el-form-item label="更新付款方式接口">
				<div class="rows">
					<selectorFun v-model="com.funUpdateOrder" placement="bottom-end"></selectorFun>
				</div>
			</el-form-item>
			<el-form-item label="取消订单接口">
				<div class="rows">
					<selectorFun v-model="com.funCloseOrder" placement="bottom-end"></selectorFun>
				</div>
			</el-form-item>
			<el-form-item label="确认收货接口">
				<div class="rows">
					<selectorFun v-model="com.funConfirmOrder" placement="bottom-end"></selectorFun>
				</div>
			</el-form-item>
			<el-form-item label="申请退款接口">
				<div class="rows">
					<selectorFun v-model="com.funAfterOrder" placement="bottom-end"></selectorFun>
				</div>
			</el-form-item>
			<el-form-item label="订单详情页">
				<span>链接</span>
				<selectorPage v-model="com.orderDetailPageName" :comData="comData"></selectorPage>
			</el-form-item>
			<el-form-item label="收货地址页">
				<span>链接</span>
				<selectorPage v-model="com.addrPageName" :comData="comData"></selectorPage>
			</el-form-item>
			<el-form-item label="支付页">
				<span>链接</span>
				<selectorPage v-model="com.buyPageName" :comData="comData"></selectorPage>
			</el-form-item>
			<el-form-item label="添加图片">
				<div class="rows">
					<el-button type="primary" size="mini" @click="add()">添加图片</el-button>
				</div>
			</el-form-item>
		</el-form>
		<el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{ padding: '0px' }">
			<el-table-column width="80" label="图片">
				<template slot-scope="scope">
					<selectorImg v-model="scope.row.imgUrl"></selectorImg>
				</template>
			</el-table-column>
			<el-table-column width="90" label="标题">
				<template slot-scope="scope">
					<el-input v-model="scope.row.name"></el-input>
				</template>
			</el-table-column>
			<el-table-column width="90" label="价格">
				<template slot-scope="scope">
					<el-input v-model="scope.row.price"></el-input>
				</template>
			</el-table-column>
			<el-table-column width="150" label="链接">
				<template slot-scope="scope">
					<selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
				</template>
			</el-table-column>
			<el-table-column width="90" label="参数">
				<template slot-scope="scope">
					<el-input v-model="scope.row.query"></el-input>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="70">
				<template slot-scope="scope">
					<div class="el-icon-top" @click="common.up(com.data, scope.$index)"></div>
					<div class="el-icon-bottom" @click="common.down(com.data, scope.$index)"></div>
					<div class="el-icon-close" @click="common.del(com.data, scope.$index)"></div>
				</template>
			</el-table-column>
		</el-table>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '订单列表'" :fieldData="fieldData"
			:requestData="requestData">
		</jfExplain>
		<!-- 顶部tab栏 -->
		<el-drawer ref="btmTable" :direction="'btt'" :with-header="false" title="列表设置" :size="btmSize"
			:visible.sync="btnVisible" :append-to-body="true">
			<div class="spliter" @mousedown="mousedown($event, $refs.btmTable)"></div>
			<div class="drw">
				<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
				<el-button type="primary" size="mini" @click="addButton(com.buttons)">添加按钮</el-button>
				<el-button type="warning" class="el-icon-top" size="mini" v-if="currBtn"
					@click="upGo(com.buttons, currBtn)">上移</el-button>
				<el-button type="warning" class="el-icon-bottom" size="mini" v-if="currBtn"
					@click="downGo(com.buttons, currBtn)">下移</el-button>
				<el-table :data="com.buttons" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row
					@current-change="currBtn = $event">
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<div class="el-icon-close" @click="deleteGo(com.buttons, scope.$index)"></div>
							<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
						</template>
					</el-table-column>
					<el-table-column label="标签" width="80">
						<template slot-scope="scope">
							<input v-model="scope.row.label" :style="scope.row == currBtn ? 'color:red;font-size:14px' : ''"
								placeholder="按钮文本"></input>
						</template>
					</el-table-column>
					<el-table-column label="颜色" width="50">
						<template slot-scope="scope">
							<el-dropdown @command="scope.row.type = $event">
								<el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="primary">主要</el-dropdown-item>
									<el-dropdown-item command="success">成功</el-dropdown-item>
									<el-dropdown-item command="info">信息</el-dropdown-item>
									<el-dropdown-item command="warning">警告</el-dropdown-item>
									<el-dropdown-item command="danger">危险</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
					<el-table-column label="动作" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.actionType" placeholder="请选择">
								<el-option label="访问接口" value="fun"></el-option>
								<el-option label="打开页面" value="link"></el-option>
								<el-option label="弹出页面" value="dialog"></el-option>
								<el-option label="滑出页面" value="drawer"></el-option>
								<el-option label="导入" value="import"></el-option>
								<el-option label="导出" value="export"></el-option>
								<el-option label="下载" value="download"></el-option>
								<el-option label="刷新" value="refresh"></el-option>
								<el-option label="返回" value="back"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="权限" width="110">
						<template slot-scope="scope">
							<!-- <selectorRight v-model="scope.row.rightId"></selectorRight> -->
						</template>
					</el-table-column>
					<el-table-column label="禁用条件" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.hideByType" placeholder="请选择">
								<el-option label="按条件" value="condition"></el-option>
							</el-select>
							<input v-if="scope.row.hideByType == 'condition'" v-model="scope.row.hideBy"
								placeholder="> < <= >= == !="
								style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
						</template>
					</el-table-column>
					<el-table-column label="后置操作" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.after">
								<el-option label="关闭" value="close"></el-option>
								<el-option label="刷新" value="refresh"></el-option>
								<el-option label="无" value=""></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="控制类型" width="110">
						<template slot-scope="scope">
							<el-select v-model="scope.row.controlType">
								<el-option label="针对选中项(默认)" value="selected"></el-option>
								<el-option label="针对全部项" value="all"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="动作类型" width="250">
						<template slot-scope="scope">
							<selectorPage v-if="['link', 'dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.link" :comData="comData"></selectorPage>
							<selectorPage v-if="['link', 'dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.linkM" :type="'M'" :comData="comData"></selectorPage>
							<!-- <el-checkbox v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.backRefresh">关闭刷新</el-checkbox> -->
							<input v-if="['dialog', 'drawer'].includes(scope.row.actionType)"
								v-model="scope.row.windowWidth" placeholder="宽度"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
							<!-- <selectorFun v-if="['fun', 'import'].includes(scope.row.actionType)" v-model="scope.row.fun"
								:comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun> -->
							<input v-if="scope.row.actionType == 'fun'" v-model="scope.row.funTip" placeholder="提示信息"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;" />
							<input v-if="scope.row.actionType == 'download'" v-model="scope.row.link" placeholder="下载地址"
								style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;" />
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-drawer>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			desc: `订单列表为商品购买后的订单做一系列展示，包含(全部、待支付、待收货、已完成、已取消)也可进行勾选是否需要展示，接口需要通过配置的方式进行按需设置。`,
			fieldData: [
				{ field: 'daskNo', explain: '桌号(非必填)', type: 'string' },
				{ field: 'status', explain: '订单状态', type: 'string' },
				{ field: 'couponPay', explain: '优惠金额', type: 'string' },
				{ field: 'payment', explain: '支付金额', type: 'string' },
				{ field: 'payState', explain: '支付状态', type: 'string' },
				{ field: 'consignState', explain: '发货状态', type: 'string' },
				{ field: 'refundState', explain: '有没有售后纠纷', type: 'string' },
				{ field: 'picPath', explain: '商品图片', type: 'string' },
				{ field: 'title/name', explain: '商品名称', type: 'string' },
				{ field: 'valu', explain: '商品规格', type: 'string' },
				{ field: 'totalFee', explain: '当前商品花费', type: 'string' },
				{ field: 'num', explain: '当前商品数量', type: 'string' }
			],
			requestData: [
				{ name: '订单列表接口', module: 'd_order', action: 'getList_m' },
				{ name: '订单删除接口', module: 'd_order', action: 'delete' },
				{ name: '更换收货地址', module: 'd_order', action: 'updateSHDZ' },
				{ name: '取消订单接口', module: 'd_order', action: 'closeOrder_m' },
				{ name: '确认收货接口', module: 'd_order', action: 'updateState' },
				{ name: '申请退款接口', module: 'd_order', action: 'updateAfter' },
				{ name: '支付', module: 'WxPay', action: 'xcxPay' }
			],
			btnVisible: false,
			btmSize: '400px',
			currBtn: null,
		};
	},
	created() { },
	methods: {
		add() {
			this.com.data.push({ name: '???', imgUrl: '', pageName: '', query: '' })
		},
		addButton (btns) {
			btns.push({ label: '', type: 'primary',rightId: '0', actionType: 'fun', fun: null, link: '',fields:[] })
		},
		getIndex(arr,row){
			for(let i = 0; i < arr.length; i++){
				if(arr[i] == row) return i
			}
		},
		upGo (arr, row) {
			let index = this.getIndex(arr,row)
			if (index !== 0) {
				arr[index] = arr.splice(index - 1, 1, arr[index])[0]
			} else {
				arr.push(arr.shift())
			}
		},
		downGo (arr, row) {
			let index = this.getIndex(arr,row)
			if (index !== arr.length - 1) {
				arr[index] = arr.splice(index + 1, 1, arr[index])[0]
			} else {
				arr.unshift(arr.splice(index, 1)[0])
			}
		},
		deleteGo (arr, index) {
			arr.splice(index, 1)
		},
		mousedown(evt, sourceDom) {
			let height = parseInt(sourceDom.size)
			let pageY = evt.pageY
			let start = true
			this.$refs.fc.style.display = 'block'
			let that = this
			document.onmousemove = (event) => {
				if (start) {
					// sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
					that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
				}
			};
			document.onmouseup = () => {
				this.$refs.fc.style.display = 'none'
				document.onmousemove = null;
				document.onmouseup = null;
				start = false
				return false;
			}
		}
	},
	props: { com: Object, saveMethod: Function ,comData: Object,}
};
</script>

<style scoped>
.cell input {
    border: 0;
}
.el-table input {
    border-top: none;
    border-right: none
}
.self{
  height: 100%;
  width: 100%;
  overflow: auto;
  cursor: pointer;
  background-color: #fff;
}
.self::-webkit-scrollbar { width: 0 !important }
.currentCom{
  border-width: 2px;
  border-style: dashed;
  border-color: red;
  background-color: white;
  position: relative;
}
.cell{
  margin: 3px;
  display: inline-block;
  /* border: 1px solid #a5a49f */
  box-sizing: border-box;
  min-width: 45%;
  font-size: 14px;
}
.btn{
  display: inline-block;
  padding-left: 3px;
}
.selectBtn{
  float: right;
}
.topPanel{
  height: 30px;
  width: 100%;
  border: 3px solid #eee;
  background-color: beige;
  box-sizing: border-box;
}
.rbtns{
  /* height: 30px; */
  border-top: 1px solid #c2d9d4;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.rbtn{
  display: inline-block;
  padding-left: 3px;
  margin: 2px;
}
.row{
	border: 3px solid #eee;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3px;
    background-color: #fff;
}
  .cells{
    min-height: 60px;
  }
  .selectBtn{
    float: right;
  }
  .topPanel{
    height: 36px;
    width: 100%;
    border: 3px solid #e8e8e8;
    background-color: #e8e8e8;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px;
  }
  .btnPanel{
	  background-color: #ffffff;
	  /* height: 120px; */
	  border: 1px solid #c2d9d4;
	  margin: 5px;
	  padding: 5px;
  }
  .btn{
    display: inline-block;
    padding: 0 5px;
    margin: 0 2px;
  }
  .searchLabel{
    width: 90px;
    display: inline-block;
	  font-size: 12px;
  }
  .searchBar{
    display: inline-block;
    width: calc(100% - 130px);
  }
  .fieldlabel{
    font-weight: bold;
    padding-right: 5px;
  }
  
  .headBar{
    line-height: 30px;
    border: #7fccdd 1px solid;
    border-radius:10px;
    padding: 5px;
    display: flex;
    flex-wrap:wrap
  }
  .searchBar{
	  display: flex;
  }
  .searchItem{
	  display: flex;
	  background-color: #d4d9e1;
	  text-align: center;
	  width: 180px;
  }
  .primary{	  border: 1px solid #409EFF;}
  .success{	  border: 1px solid #67C23A;}
  .info{	  border: 1px solid #909399;}
  .warning{	  border: 1px solid #E6A23C;}
  .danger{	  border: 1px solid #F56C6C;}
  .file{display: none;}
</style>
