<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题颜色">
                <el-color-picker v-model="com.titleFontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="com.title"></el-input>
            </el-form-item>
            <el-form-item label="图表通用配置">
                <div class="row">
                    <span class="row-item">高度：</span>
          		    <el-input-number v-model="com.height" :min="50" :max="1000" :step="10" controls-position="right" size="mini"></el-input-number>
                </div>
                <div class="row">
                    <span class="row-item">图例显示位置：</span>
                    <el-radio v-model="opts.legend.position" label="bottom">下</el-radio>
                    <el-radio v-model="opts.legend.position" label="top">上</el-radio>
                    <el-radio v-model="opts.legend.position" label="left">左</el-radio>
                    <el-radio v-model="opts.legend.position" label="right">右</el-radio>
                </div>
                <div class="row">
                    <span class="row-item">图例对齐方向：</span>
                    <el-radio v-model="opts.legend.float" label="center">中间</el-radio>
                    <el-radio v-model="opts.legend.float" label="left">左</el-radio>
                    <el-radio v-model="opts.legend.float" label="right">右</el-radio>
                    <el-radio v-model="opts.legend.float" label="top">上</el-radio>
                    <el-radio v-model="opts.legend.float" label="bottom">下</el-radio>
                </div>
            </el-form-item>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="柱状图表配置" name="1" v-if="com.type == 'column'">
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.column.type" label="group">分组柱状图</el-radio>
                        <el-radio v-model="opts.extra.column.type" label="stack">堆叠柱状图</el-radio>
                        <el-radio v-model="opts.extra.column.type" label="meter">温度计式图</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">数据位置：</span>
                        <el-radio v-model="opts.extra.column.labelPosition" label="outside">外部</el-radio>
                        <el-radio v-model="opts.extra.column.labelPosition" label="insideTop">内顶部</el-radio>
                        <el-radio v-model="opts.extra.column.labelPosition" label="center">内中间</el-radio>
                        <el-radio v-model="opts.extra.column.labelPosition" label="bottom">内底部</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">柱宽：</span>
                        <el-input-number v-model="opts.extra.column.width" :min="5" :max="100" :step="5" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">柱间距：</span>
                        <el-input-number v-model="opts.extra.column.seriesGap" :min="0" :max="100" :step="5" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">X轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.xAxis.title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">Y轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.yAxis.data[0].title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">圆角：</span>
                        <el-checkbox v-model="opts.extra.column.barBorderCircle">是否开启</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="条形图表配置" name="2" v-if="com.type == 'bar'">
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.bar.type" label="group">分组柱状图</el-radio>
                        <el-radio v-model="opts.extra.bar.type" label="stack">堆叠柱状图</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">条宽：</span>
                        <el-input-number v-model="opts.extra.bar.width" :min="5" :max="100" :step="5" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">条间距：</span>
                        <el-input-number v-model="opts.extra.bar.seriesGap" :min="0" :max="100" :step="5" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">X轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.xAxis.title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">Y轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.yAxis.data[0].title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">圆角：</span>
                        <el-checkbox v-model="opts.extra.bar.barBorderCircle">是否开启</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="折线图表配置" name="3" v-if="com.type == 'line'">
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.line.type" label="straight">尖角折线模式</el-radio>
                        <el-radio v-model="opts.extra.line.type" label="curve">曲线圆滑模式</el-radio>
                        <el-radio v-model="opts.extra.line.type" label="step">时序图模式</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">线宽：</span>
                        <el-input-number v-model="opts.extra.line.width" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">激活指示点类型：</span>
                        <el-radio v-model="opts.extra.line.activeType" label="none">不启用</el-radio>
                        <el-radio v-model="opts.extra.line.activeType" label="hollow">空心点模式</el-radio>
                        <el-radio v-model="opts.extra.line.activeType" label="solid">实心点模式</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">动画效果方向：</span>
                        <el-radio v-model="opts.extra.line.animation" label="vertical">垂直动画</el-radio>
                        <el-radio v-model="opts.extra.line.animation" label="horizontal">水平动画</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">X轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.xAxis.title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">Y轴标题：</span>
                        <el-input style="width: 200px;" v-model="opts.yAxis.data[0].title"></el-input>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="饼状表配置" name="4" v-if="com.type == 'pie'">
                    <div class="row">
                        <span class="row-item">数据间距：</span>
                        <el-input-number v-model="opts.extra.pie.labelWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线：</span>
                        <el-checkbox v-model="opts.extra.pie.border">是否开启</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线宽度：</span>
                        <el-input-number v-model="opts.extra.pie.borderWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线颜色：</span>
                        <el-color-picker v-model="opts.extra.pie.borderColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="圆环图表配置" name="5" v-if="com.type == 'ring'">
                    <div class="row">
                        <div class="flex">
                            <span class="row-item">主标题：</span>
                            <span>内容：</span><el-input style="width: 200px;" v-model="opts.title.name" placeholder="请输入主标题"></el-input>
                            <span>字体大小：</span><el-input-number v-model="opts.title.fontSize" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                            <span>颜色：</span><el-color-picker v-model="opts.title.color"></el-color-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="flex">
                            <span class="row-item">副标题：</span>
                            <span>内容：</span><el-input style="width: 200px;" v-model="opts.subtitle.name" placeholder="请输入主标题"></el-input>
                            <span>字体大小：</span><el-input-number v-model="opts.subtitle.fontSize" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                            <span>颜色：</span><el-color-picker v-model="opts.subtitle.color"></el-color-picker>
                        </div>
                    </div>
                    <div class="row">
                        <span class="row-item">圆环宽度：</span>
                        <el-input-number v-model="opts.extra.ring.ringWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">圆环填充色：</span>
                        <el-color-picker v-model="opts.extra.ring.centerColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">数据间距：</span>
                        <el-input-number v-model="opts.extra.ring.labelWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线：</span>
                        <el-checkbox v-model="opts.extra.ring.border">是否开启</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线宽度：</span>
                        <el-input-number v-model="opts.extra.ring.borderWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线颜色：</span>
                        <el-color-picker v-model="opts.extra.ring.borderColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="玫瑰图表配置" name="6" v-if="com.type == 'rose'">
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.rose.type" label="area">面积模式</el-radio>
                        <el-radio v-model="opts.extra.rose.type" label="radius">半径模式</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">数据间距：</span>
                        <el-input-number v-model="opts.extra.rose.labelWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线：</span>
                        <el-checkbox v-model="opts.extra.rose.border">是否开启</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线宽度：</span>
                        <el-input-number v-model="opts.extra.rose.borderWidth" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">分割线颜色：</span>
                        <el-color-picker v-model="opts.extra.rose.borderColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="雷达图表配置" name="7" v-if="com.type == 'radar'">
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.radar.gridType" label="radar">蜘蛛网格样式</el-radio>
                        <el-radio v-model="opts.extra.radar.gridType" label="radius">圆形背景网格</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">网格数量：</span>
                        <el-input-number v-model="opts.extra.radar.gridCount" :min="1" :max="10" :step="1" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">网格颜色：</span>
                        <el-color-picker v-model="opts.extra.radar.gridColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">各项标识文案：</span>
                        <el-checkbox v-model="opts.extra.radar.labelShow">是否显示</el-checkbox>
                    </div>
                    <div class="row">
                        <span class="row-item">标识文案颜色：</span>
                        <el-color-picker v-model="opts.extra.radar.labelColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="进度条配置" name="8" v-if="com.type == 'arcbar'">
                    <div class="row">
                        <div class="flex">
                            <span class="row-item">主标题：</span>
                            <span>内容：</span><el-input style="width: 200px;" v-model="opts.title.name" placeholder="请输入主标题"></el-input>
                            <span>字体大小：</span><el-input-number v-model="opts.title.fontSize" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                            <span>颜色：</span><el-color-picker v-model="opts.title.color"></el-color-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="flex">
                            <span class="row-item">副标题：</span>
                            <span>内容：</span><el-input style="width: 200px;" v-model="opts.subtitle.name" placeholder="请输入主标题"></el-input>
                            <span>字体大小：</span><el-input-number v-model="opts.subtitle.fontSize" :min="1" :max="100" :step="2" controls-position="right" size="mini"></el-input-number>
                            <span>颜色：</span><el-color-picker v-model="opts.subtitle.color"></el-color-picker>
                        </div>
                    </div>
                    <div class="row">
                        <span class="row-item">类型：</span>
                        <el-radio v-model="opts.extra.arcbar.type" label="default">半圆弧模式</el-radio>
                        <el-radio v-model="opts.extra.arcbar.type" label="circle">整圆模式</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">动画方向：</span>
                        <el-radio v-model="opts.extra.arcbar.direction" label="cw">顺时针方向</el-radio>
                        <el-radio v-model="opts.extra.arcbar.direction" label="ccw">逆时针方向</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">两端样式：</span>
                        <el-radio v-model="opts.extra.arcbar.lineCap" label="round">圆形线帽</el-radio>
                        <el-radio v-model="opts.extra.arcbar.lineCap" label="square">方形线帽</el-radio>
                        <el-radio v-model="opts.extra.arcbar.lineCap" label="butt">平直边缘</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">渐变：</span>
                        <el-radio v-model="opts.extra.arcbar.linearType" label="none">关闭渐变</el-radio>
                        <el-radio v-model="opts.extra.arcbar.linearType" label="custom">开启渐变</el-radio>
                    </div>
                    <div class="row">
                        <span class="row-item">弧线宽度：</span>
                        <el-input-number v-model="opts.extra.arcbar.width" :min="1" :max="100" :step="5" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">间隔单位px：</span>
                        <el-input-number v-model="opts.extra.arcbar.gap" :min="1" :max="100" :step="1" controls-position="right" size="mini"></el-input-number>
                    </div>
                    <div class="row">
                        <span class="row-item">背景颜色：</span>
                        <el-color-picker v-model="opts.extra.arcbar.backgroundColor"></el-color-picker>
                    </div>
                    <div class="row">
                        <span class="row-item">颜色：</span>
                        <span v-for="(i,index) in color.length" :key="index">
                            <el-color-picker  v-model="color[index]"></el-color-picker>
                        </span>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '图表'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            opts: this.com.opts,
            color: this.com.opts.color,
            activeName: "0",
            types: ['pie','ring',"rose"],
			drawer: false,
			desc: `图表组件可以实现多种类型图表如(折线图、柱状图、条状图、饼状图、圆环图、玫瑰图、雷达图、进度条)，
            如需除了在配置项的现有配置，还可以去uCharts官网(https://www.ucharts.cn/v2/#/)查看其他配置项并修改组件
            里的json。`,
			fieldData:[
				{field: 'categories',explain: 'X轴标题', type: 'string'},
				{field: 'name',explain: '数值标题', type: 'string'},
				{field: 'value',explain: '数值', type: 'number'}
			],
			requestData:[
				{name: '图表数据接口',module: '---', action: '---'}
			]
        };
    },
    mounted() {
        if(this.types.includes(this.com.type)) {
            this.color.length = this.com.data[0].series[0].data.length
            return
        }
        this.color.length = this.com.data[0].series.length
    },
    methods: {
    },
    props:{com:Object}
};
</script>

<style scoped>
    .row {
        margin: 10px;
    }
    .row-item {
        width: 100px;
        display: inline-block;
        border-left: 5px solid #ecf5ff;
        border-radius: 2px;
        padding: 0 5px;
    }
    .flex {
        display: flex;
        align-items: center;
    }
</style>
