<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="新增地址">
		        <selectorFun v-model="com.funAddAddress" placement="bottom-end"></selectorFun>
            </el-form-item>
            <el-form-item label="编辑地址">
		        <selectorFun v-model="com.funUpdateAddress" placement="bottom-end"></selectorFun>
            </el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '新增/编辑地址'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
			drawer: false,
			desc: `新增/编辑地址组件用于自定义新增个人地址列表，需要配置新增/编辑地址接口，组件内集成了地址解析，并选择是否设为默认。`,
			fieldData:[
				{field: 'name',explain: '省市区名称', type: 'string'},
				{field: 'pId',explain: '地区id', type: 'string'}
			],
			requestData:[
				{name: '省市区选择列表接口',module: 's_area', action: 'getList'},
				{name: '新增收货地址',module: 'd_user_addr', action: 'add'},
				{name: '编辑收货地址',module: 'd_user_addr', action: 'update'},
			]
        };
    },
    created() {},
    methods: {},
    components:{},
    props:{
        com:Object
    }
};
</script>

<style scoped>

</style>
