<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item label="图片">
        <!-- <selectorImg v-model="com.data[0].poster"></selectorImg> -->
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="com.data[0].title"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="com.data[0].src"></el-input>
      </el-form-item>
      <el-form-item label="作者">
        <el-input v-model="com.data[0].author"></el-input>
      </el-form-item>
      <el-form-item label="暂停">
        <el-switch v-model="com.data[0].action"></el-switch>
      </el-form-item>
    </el-form>
    <jfExplain v-model="drawer" :desc="desc" :name="com.cName || '音频'" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>
</style>
<script>

export default {
  data () {
    return {
      drawer: false,
      desc: '音频是一个用于音频展示与播放的组件，组件中可以设置音频的地址、封面图、名称、作者……等配置。',
      fieldData:[
        {field: 'src',explain: '音频地址', type: 'string'},
        {field: 'poster',explain: '封面图地址', type: 'string'},
        {field: 'title',explain: '音频名称', type: 'string'},
        {field: 'author',explain: '作者名称', type: 'string'},
      ]
    }
  },
  methods: {
  },
  props: { com: {} }
}
</script>
