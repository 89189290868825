<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="文本">
          <el-input v-model="com.text"></el-input>
        </el-form-item>
        <el-form-item label="店招类型">
          <el-radio-group v-model="com.mode">
            <el-radio :label="1">小店招</el-radio>
            <el-radio :label="2">大店招</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="logo">
          <selectorImg v-model="com.logo"></selectorImg>
        </el-form-item>
        <el-form-item label="logo">
          <selectorImg v-model="com.pic"></selectorImg>
        </el-form-item>
      </el-form>
    	<jfExplain v-model="drawer" :name="com.cName || '店招'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '店招是一个展示组件，图片设置的为背景图，logo则为圆形小图，文本即为店名或其他文本。',
      fieldData:[
        {field: 'text',explain: '文本', type: 'string'},
        {field: 'logo',explain: 'logo图', type: 'string'},
        {field: 'pic',explain: '背景图', type: 'string'}
      ]
    }
  },
  methods: {
  },
  props: { com: {} }
}
</script>
