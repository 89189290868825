<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item label="文本">
        <el-input v-model="com.data[0].text"></el-input>
      </el-form-item>
      <el-form-item label="号码">
        <el-input v-model="com.data[0].number"></el-input>
      </el-form-item>
      <el-form-item label="显示号码">
        <el-checkbox v-model="com.data[0].showNumber"></el-checkbox>
      </el-form-item>
      <el-form-item label="显示头像">
        <el-checkbox v-model="com.data[0].showArrow"></el-checkbox>
      </el-form-item>
    </el-form>
    	<jfExplain v-model="drawer" :name="com.cName || '电话'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '电话组件是一个可以通过配置它的电话号码、文本并可以配置是否要显示号码或头像来展示并拨打所设电话的组件。',
      fieldData:[
        {field: 'text',explain: '文本标题', type: 'string'},
        {field: 'number',explain: '手机号码', type: 'number'},
        {field: 'showNumber',explain: '是否显示号码', type: 'boolean'},
        {field: 'showArrow',explain: '是否显示头像', type: 'boolean'}
      ]
    }
  },
  methods: {
  },
  props: { com: {} }
}
</script>
