<template>
    <div class=''>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="订单详情接口">
		        <selectorFun v-model="com.funOrderDetail" placement="bottom-end"></selectorFun>
            </el-form-item>
            <el-form-item label="订单商品详情接口">
		        <selectorFun v-model="com.funOrderGoodsDetail" placement="bottom-end"></selectorFun>
            </el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '订单详情'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
			drawer: false,
			desc: `订单列表为商品购买后的订单做一系列展示，包含(全部、待支付、待收货、已完成、已取消)也可进行勾选是否需要展示，接口需要通过配置的方式进行按需设置。`,
			fieldData:[
                {field: 'addr',explain: '收货地址', type: 'string'},
                {field: 'addrName',explain: '收货人', type: 'string'},
                {field: 'addrPhone',explain: '收货人手机号', type: 'string'},
                {field: 'payment',explain: '支付金额', type: 'string'},
                {field: 'orderNo',explain: '订单编号', type: 'string'},
                {field: 'payType',explain: '支付方式', type: 'string'},
                {field: 'ctime',explain: '下单时间', type: 'string'},
                {field: 'picPath',explain: '商品图片', type: 'string'},
                {field: 'title/name',explain: '商品名称', type: 'string'},
                {field: 'price',explain: '商品价格', type: 'string'},
                {field: 'param',explain: '商品规格', type: 'string'},
                {field: 'num',explain: '当前商品数量', type: 'number'}
			],
			requestData:[
                {name: '订单详情接口',module: 'd_order', action: 'getById_m'},
                {name: '订单商品详情',module: 'd_order', action: 'getGoodsById_m'}
			]
        };
    },
    created() {},
    methods: {},
    props: {
        com: Object
    }
};
</script>

<style scoped>

</style>
