<template>
    <div class=''>
        <div>
            <el-input v-model="com.path" placeholder="请输入文件路径"></el-input>
        </div>
        <el-button v-if="com.isBack" size="mini" type="warning" plain>返回</el-button>
        <el-button size="mini" type="warning" plain @click="getMyImgs('')">刷新</el-button>
        <el-button v-if="com.isAdd" size="mini" type="warning" plain>添加文件夹</el-button>
        <el-button v-if="com.isUpload" size="mini" type="primary" plain>上传文件</el-button>
        <el-button v-if="com.isDel" size="mini" type="danger" plain>删除</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    components:{},
    props: {
        com: Object
    }
};
</script>

<style scoped>

</style>
