<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
            <el-form-item label="组件说明">
            <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
            </el-form-item>
            <el-form-item label="栏目类型">
                <el-radio v-model="com.columnType" label="1">图片</el-radio>
                <el-radio v-model="com.columnType" label="2">视频</el-radio>
                <el-radio v-model="com.columnType" label="3">多图</el-radio>
            </el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题颜色">
                <el-color-picker v-model="com.titleFontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="副标题颜色">
                <el-color-picker v-model="com.subtitleFontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="添加栏目">
				<el-button type="primary" size="mini" @click="add()">添加栏目</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
			<el-table-column width="150" label="标题">
			<template slot-scope="scope">
				<el-input v-model="scope.row.title"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="150" label="副标题">
			<template slot-scope="scope">
				<el-input v-model="scope.row.subtitle"></el-input>
			</template>
			</el-table-column>
			<el-table-column label="操作" width="70">
			<template slot-scope="scope">
				<div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
				<div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
				<div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
			</template>
			</el-table-column>
		</el-table>
        <jfExplain v-model="drawer" :name="com.cName || '图文栏目'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            desc: '图文栏目组件可以通过选择栏目类型展示不同的展现方式，有图片、视频、多图等，适用于需要展示大图有介绍的时候使用。',
            fieldData:[
                {field: 'imgUrl',explain: '图片地址', type: 'string'},
                {field: 'videoUrl',explain: '视频地址', type: 'string'},
                {field: 'title',explain: '标题', type: 'string'},
                {field: 'subtitle',explain: '副标题', type: 'string'}
            ]
        }
    },
    created() {},
    methods: {
        add() {
            this.com.data.push({"title": "标题","subtitle": "副标题","imgUrl": "https://img0.baidu.com/it/u=3090532544,4109313510&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500","videoUrl": "https://media.w3.org/2010/05/sintel/trailer.mp4"});
        },
    },
    props:{
        com: Object
    }
};
</script>

<style scoped>

</style>
