<template>
  <div class="self">
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明" style="margin-bottom: 10px;">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item class="margin-tb" label="输入框：">
          <div style="display: flex;margin-bottom: 10px;">
            <el-radio-group v-model="com.input.isInput">
                <el-radio-button :label="true">显示</el-radio-button>
                <el-radio-button :label="false">隐藏</el-radio-button>
            </el-radio-group> 
            <jfStyle v-model="com.input.style"></jfStyle>
          </div>
          <div>
            <el-radio-group v-model="com.input.isDisabled">
                <el-radio-button :label="true">可输入</el-radio-button>
                <el-radio-button :label="false">禁用</el-radio-button>
            </el-radio-group> 
          </div>
            <div>
                <span>占位符：</span><el-input v-model="com.input.placeholder" style="width: 150px;margin-bottom: 10px;" placeholder="请输入占位内容" size="mini"></el-input>
            </div>
            <div>
                <span>前缀文本：</span><el-input v-model="com.input.prefix" style="width: 150px;margin-bottom: 10px;" placeholder="请输入前缀文本" size="mini"></el-input><jfStyle v-model="com.input.prefixStyle"></jfStyle>
            </div>
      </el-form-item>
      <el-form-item class="margin-tb" label-width="0px">
          <div style="display: flex;margin-bottom: 10px;">
              <el-button style="flex: .5;" type="primary" plain size="mini"
                  @click="colVisible = true">左侧组件设置</el-button>
          </div>
      </el-form-item>
      <el-form-item class="margin-tb" label-width="0px">
          <div style="display: flex;margin-bottom: 10px;">
              <el-button style="flex: .5;" type="primary" plain size="mini"
                  @click="btnVisible = true">右侧按钮设置</el-button>
          </div>
      </el-form-item>
      <el-form-item class="margin-tb" label-width="0px">
          <div style="display: flex;margin-bottom: 10px;">
              <el-button style="flex: .5;" type="primary" plain size="mini"
                  @click="skuVisible = true">规格底部按钮</el-button>
          </div>
      </el-form-item>
    </el-form>
    <el-drawer ref="btmFieldl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="colVisible"
            :append-to-body="true">
        <div class="spliter" @mousedown="mousedown($event, $refs.btmFieldl)"></div>
        <div class="drw">
            <el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
            <el-button type="primary" size="mini" @click="addField(com.icons)">添加字段</el-button>
            <el-button type="warning" class="el-icon-top" size="mini" v-if="currCol"
                @click="upGo(com.icons, currCol)">上移</el-button>
            <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currCol"
                @click="downGo(com.icons, currCol)">下移</el-button>

            <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
                <el-radio-group v-model="hasToken">
                    <el-radio :label="0">不带</el-radio>
                    <el-radio :label="1">带Token</el-radio>
                </el-radio-group>
                <el-row v-if="com.fun.prms" v-for="(value, key, index) in com.fun.prms" :key="index">
                    <el-col :span="8">{{ key }}</el-col>
                    <el-col :span="8"><el-input v-model="com.fun.prms[key]" placeholder="Token"></el-input></el-col>
                </el-row>
                <el-button plain size="mini" @click="getData" type="primary">提交</el-button>
                <el-input v-model="currentToken" placeholder="Token" v-if="hasToken === 1"></el-input>
            </el-dialog>
            <el-table :data="com.icons" stripe tooltip-effect="dark" style="width: 100%"
                :cellStyle="{ padding: '0px' }" highlight-current-row @current-change="currCol = $event">
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="el-icon-close" @click="deleteGo(com.icons, scope.$index)"></div>
                        <el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="字段" width="150">
                    <template slot-scope="scope">
                        <input style="width: 100%;" v-model="scope.row.field" @blur="scope.row.field = scope.row.field.trim()"
                            :style="scope.row == currCol ? 'color:red;font-size:14px' : ''"
                            placeholder="按钮文本"></input>
                    </template>
                </el-table-column> -->
                <el-table-column label="标签" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.text"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作类型">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.clickType" placeholder="请选择">
                          <el-option key="1" label="跳转" :value="0"></el-option>
                          <el-option key="5" label="请求接口" :value="1"></el-option>
                      </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作行为">
                    <template slot-scope="scope">
                        <selectorPage v-if="scope.row.clickType == 0"
                            v-model="scope.row.linkC" :comData="comData"></selectorPage>
                        <selectorFun v-if="[1,2].includes(scope.row.clickType)" v-model="scope.row.funC"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                        <el-input v-model="scope.row.phone" placeholder="电话号码" v-if="scope.row.icon == '电话'"></el-input>
                        <el-input v-model="scope.row.kefuUrl" placeholder="客服链接" v-if="scope.row.icon == '客服'"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="初始化接口">
                    <template slot-scope="scope">
                        <selectorFun v-model="scope.row.defaultFun"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                    </template>
                </el-table-column>
                <el-table-column label="功能类型" width="110">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.icon" placeholder="请选择">
                                <el-option label="购物车" value="购物车"></el-option>
                                <el-option label="客服" value="客服"></el-option>
                                <el-option label="评论" value="评论"></el-option>
                                <el-option label="电话" value="电话"></el-option>
                                <el-option label="收藏" value="收藏"></el-option>
                                <el-option label="点赞" value="点赞"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                <el-table-column label="后置操作" width="110">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.after">
                            <el-option v-if="scope.row.clickType == 2" label="关闭弹框" value="close"></el-option>
                            <el-option label="返回" value="back"></el-option>
                            <el-option label="跳转页面" value="link"></el-option>
                            <el-option label="无" value=""></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <jfStyle v-model="scope.row.style"></jfStyle>
                        <selectorPage v-if="['link'].includes(scope.row.after)" v-model="scope.row.linkA" :type="'M'" :comData="comData"></selectorPage>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            </el-tabs>
        </div>
    </el-drawer>
    <el-drawer ref="btmFieldl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="btnVisible"
            :append-to-body="true">
        <div class="spliter" @mousedown="mousedown($event, $refs.btmFieldl)"></div>
        <div class="drw">
            <el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
            <el-button type="primary" size="mini" @click="addField(com.buttons)">添加字段</el-button>
            <el-button type="warning" class="el-icon-top" size="mini" v-if="currCol"
                @click="upGo(com.buttons, currCol)">上移</el-button>
            <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currCol"
                @click="downGo(com.buttons, currCol)">下移</el-button>

            <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
                <el-radio-group v-model="hasToken">
                    <el-radio :label="0">不带</el-radio>
                    <el-radio :label="1">带Token</el-radio>
                </el-radio-group>
                <el-row v-if="com.fun.prms" v-for="(value, key, index) in com.fun.prms" :key="index">
                    <el-col :span="8">{{ key }}</el-col>
                    <el-col :span="8"><el-input v-model="com.fun.prms[key]" placeholder="Token"></el-input></el-col>
                </el-row>
                <el-button plain size="mini" @click="getData" type="primary">提交</el-button>
                <el-input v-model="currentToken" placeholder="Token" v-if="hasToken === 1"></el-input>
            </el-dialog>
            <el-table :data="com.buttons" stripe tooltip-effect="dark" style="width: 100%"
                :cellStyle="{ padding: '0px' }" highlight-current-row @current-change="currCol = $event">
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="el-icon-close" @click="deleteGo(com.buttons, scope.$index)"></div>
                        <el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="字段" width="150">
                    <template slot-scope="scope">
                        <input style="width: 100%;" v-model="scope.row.field" @blur="scope.row.field = scope.row.field.trim()"
                            :style="scope.row == currCol ? 'color:red;font-size:14px' : ''"
                            placeholder="按钮文本"></input>
                    </template>
                </el-table-column> -->
                <el-table-column label="标签" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.text"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作类型">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.clickType" placeholder="请选择">
                          <el-option key="1" label="跳转" :value="0"></el-option>
                          <el-option key="5" label="请求接口" :value="1"></el-option>
                          <el-option key="6" label="打开弹框" :value="2"></el-option>
                      </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作行为">
                    <template slot-scope="scope">
                        <selectorPage v-if="scope.row.clickType == 0"
                            v-model="scope.row.linkC" :comData="comData"></selectorPage>
                        <selectorFun v-if="[1,2].includes(scope.row.clickType)" v-model="scope.row.funC"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                    </template>
                </el-table-column>
                <el-table-column label="后置操作" width="110">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.after">
                            <el-option v-if="scope.row.clickType == 2" label="关闭弹框" value="close"></el-option>
                            <el-option label="返回" value="back"></el-option>
                            <el-option label="跳转页面" value="link"></el-option>
                            <el-option label="无" value=""></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <jfStyle v-model="scope.row.style"></jfStyle>
                        <selectorPage v-if="['link'].includes(scope.row.after)" v-model="scope.row.linkA" :type="'M'" :comData="comData"></selectorPage>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            </el-tabs>
        </div>
    </el-drawer>
    <el-drawer ref="btmFieldl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="skuVisible"
            :append-to-body="true">
        <div class="spliter" @mousedown="mousedown($event, $refs.btmFieldl)"></div>
        <div class="drw">
            <el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
            <el-button type="primary" size="mini" @click="addField(com.skuButtons)">添加字段</el-button>
            <el-button type="warning" class="el-icon-top" size="mini" v-if="currCol"
                @click="upGo(com.skuButtons, currCol)">上移</el-button>
            <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currCol"
                @click="downGo(com.skuButtons, currCol)">下移</el-button>

            <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
                <el-radio-group v-model="hasToken">
                    <el-radio :label="0">不带</el-radio>
                    <el-radio :label="1">带Token</el-radio>
                </el-radio-group>
                <el-row v-if="com.fun.prms" v-for="(value, key, index) in com.fun.prms" :key="index">
                    <el-col :span="8">{{ key }}</el-col>
                    <el-col :span="8"><el-input v-model="com.fun.prms[key]" placeholder="Token"></el-input></el-col>
                </el-row>
                <el-button plain size="mini" @click="getData" type="primary">提交</el-button>
                <el-input v-model="currentToken" placeholder="Token" v-if="hasToken === 1"></el-input>
            </el-dialog>
            <el-table :data="com.skuButtons" stripe tooltip-effect="dark" style="width: 100%"
                :cellStyle="{ padding: '0px' }" highlight-current-row @current-change="currCol = $event">
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="el-icon-close" @click="deleteGo(com.skuButtons, scope.$index)"></div>
                        <el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="字段" width="150">
                    <template slot-scope="scope">
                        <input style="width: 100%;" v-model="scope.row.field" @blur="scope.row.field = scope.row.field.trim()"
                            :style="scope.row == currCol ? 'color:red;font-size:14px' : ''"
                            placeholder="按钮文本"></input>
                    </template>
                </el-table-column> -->
                <el-table-column label="标签" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.text"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作类型">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.clickType" placeholder="请选择">
                          <el-option key="1" label="跳转" :value="0"></el-option>
                          <el-option key="5" label="请求接口" :value="1"></el-option>
                      </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作行为">
                    <template slot-scope="scope">
                        <selectorPage v-if="scope.row.clickType == 0"
                            v-model="scope.row.linkC" :comData="comData"></selectorPage>
                        <selectorFun v-if="[1].includes(scope.row.clickType)" v-model="scope.row.funC"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                    </template>
                </el-table-column>
                <el-table-column label="后置操作" width="110">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.after">
                            <el-option label="关闭弹框" value="close"></el-option>
                            <el-option label="返回" value="back"></el-option>
                            <el-option label="跳转页面" value="link"></el-option>
                            <el-option label="无" value=""></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <jfStyle v-model="scope.row.style"></jfStyle>
                        <selectorPage v-if="['link'].includes(scope.row.after)" v-model="scope.row.linkA" :type="'M'" :comData="comData"></selectorPage>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            </el-tabs>
        </div>
    </el-drawer>
    <jfExplain v-model="drawer" :desc="desc" :name="com.cName || '购物导航'" :fieldData="fieldData"></jfExplain>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      desc: "购物导航是一个主要用于实现加入购物车、评论、选择规格、购买的入口组件，可以通过设置接口、地址列表、添加地址等配置完成跳转及展示。",
      fieldData:[
        {field: 'productId',explain: '产品id', type: 'number'},
        {field: 'number',explain: '数量', type: 'string'},
        {field: 'tourId',explain: '分类id', type: 'string'}
      ],
      dialogVisible: false,
      colVisible: false,
      btnVisible: false,
      skuVisible: false,
      btmSize: '400px',
      currBtn: null,
      currCol: null,
    }
  },
  methods: {
    getCols(type){
        if (this.com.fun) {
            let data = { module: 'sysfun', action: 'getDefine', prms: Object.assign({sysId: this.$route.params.id},this.com.fun)}
            this.common.request(data, this.getColsCb.bind(this,type))
        } else {
            this.$message.error('没有设置接口')
        }
    },
    getColsCb (type,r) {
    if (r.code != 0) {
        this.$message.error(r.msg)
        return
    }
    if (r.data[0].length == 0) {
            this.$message.error('接口不存在')
            return
    }
        let define = JSON.parse(r.data[0][0].define)
        if(type=='out')
        for (let j = 0; j < define.out.length; j++) {
        const ctrl = {
            type: 'text',
            field: define.out[j].field,
            label: define.out[j].comment,
            style: {}
        }
        let has = this.com.icons.find(fld=>fld.field==ctrl.field)
        if(!has) this.com.icons.push(ctrl)
        }
        else
        for (let i = 0; i < define.in.length; i++) {
            if (['orderBy', 'page', 'limit', 'appId'].includes(define.in[i].field)) continue
            const prmsField = {
            field: define.in[i].field,
            label: define.in[i].comment,
            type: 'input',
            optionsDataIndex: '',
            options: []
            }
            let has = this.com.searchComs.find(fld=>fld.field==prmsField.field)
            if(!has) this.com.searchComs.push(prmsField)
        }
    },
    addField(arr) {
        const ctrl = {
            type: 'text',
            field: '',
            label: '',
    "position": "right",
    "linkC":"",
            style: {}
        }
        arr.push(ctrl)
    },
    getData() {
        if (!this.dialogVisible) {
            if (!this.com.fun) this.$message.error('没有设置接口')
            else this.dialogVisible = true
            return
        }
        if (this.currentToken.length > 0) localStorage.setItem('testToken', this.currentToken)
        const opt = { server: this.$route.params.url, subsys: this.$route.params.subsys }
        if (this.hasToken) opt.token = this.currentToken
        else opt.withToken = false
        this.common.request(this.com.fun, this.setData.bind(this), opt)
    },
    setData(r) {
        if (r.code == 0) {
            this.com.data = r.data[this.com.listDataIndex].slice(0, 5)
            this.dialogVisible = false
        } else this.$message.error(r.msg)
    },
    addButton(btns) {
        btns.push({ label: '', type: 'primary', rightId: '0', actionType: 'fun', fun: null, link: '', fields: [] })
    },
    getIndex(arr, row) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] == row) return i
        }
    },
    upGo(arr, row) {
        let index = this.getIndex(arr, row)
        if (index !== 0) {
            arr[index] = arr.splice(index - 1, 1, arr[index])[0]
        } else {
            arr.push(arr.shift())
        }
    },
    downGo(arr, row) {
        let index = this.getIndex(arr, row)
        if (index !== arr.length - 1) {
            arr[index] = arr.splice(index + 1, 1, arr[index])[0]
        } else {
            arr.unshift(arr.splice(index, 1)[0])
        }
    },
    deleteGo(arr, index) {
        arr.splice(index, 1)
    },
    mousedown(evt, sourceDom) {
        let height = parseInt(sourceDom.size)
        let pageY = evt.pageY
        let start = true
        this.$refs.fc.style.display = 'block'
        let that = this
        document.onmousemove = (event) => {
            if (start) {
                // sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
                that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
            }
        };
        document.onmouseup = () => {
            this.$refs.fc.style.display = 'none'
            document.onmousemove = null;
            document.onmouseup = null;
            start = false
            return false;
        }
    },
    addParams(parm) {
        if(!parm) parm= []
        parm.push({"formField": "","toField": ""})
        // console.log(parm);
        this.com.params = parm
    }
  },
  props: {
    com: { coms: Array },
    comData: Object,
    saveMethod: Function
  }
}
</script>

