<template>
  <div class="bottomFooter" :style="{background:com.bgColor}">
    <ul>
      <li
        class="fl"
        :class="'bottom'+ com.data.length"
        v-for="(item, index) in com.data"
        :key="index"
        :style="{color:item.color}"
      >
        <p class="footerImg">
          <img :src="$route.params.url+item.imgUrl" :onerror="common.imgOnError" />
        </p>
        <p>{{item.text}}</p>
      </li>
      <p></p>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  methods:{
	  
  },
  props: {
    com: Object
  }
}
</script>

<style scoped>

  .bottomFooter {
    width: 375px;
    /* position: absolute; */
    /* bottom: 0; */
    height: 60px;
    /* background: #fff; */
    /* z-index: 9; */
    /* border-top: 1px solid #eee; */

    ul {
      li {
        width: 50%;
        text-align: center;
        font-size: 12px;
        color: rgb(51, 51, 51);

        .footerImg {
          img {
            height: 28px;
            width: 28px;
            display: block;
            margin: 6px auto;
          }
        }
      }

      .bottom2 {
        width: 50%;
      }
      .bottom3 {
        width: 33.33%;
      }
      .bottom4 {
        width: 25%;
      }
      .bottom5 {
        width: 20%;
      }
    }
  }
</style>
