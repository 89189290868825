<template>
  <div class="self">
    <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="上级">
            <el-cascader v-model="comData.pId" :options="list" :show-all-levels="false"
            :props="{value:'id',label:'name',checkStrictly:true}"
            @change="pChange"></el-cascader>
          </el-descriptions-item>
          <el-descriptions-item label="名称">
            <el-input v-model="comData.name"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="规则路径">
            <!-- <el-input v-model="comData.path"></el-input> -->
			<selectorPage v-model="comData.path"></selectorPage>
          </el-descriptions-item>
          <el-descriptions-item label="客户端">
			<el-radio-group v-model="comData.rightType" size="mini">
				<el-radio-button :label="1">通用</el-radio-button>
				<el-radio-button :label="2">PC</el-radio-button>
				<el-radio-button :label="3">手机</el-radio-button>
			</el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="顺序">
			  <el-input-number v-model="comData.weight"></el-input-number>
          </el-descriptions-item>
		  <el-descriptions-item label="是否菜单">
			  <el-radio-group v-model="comData.isMenu" size="mini">
				<el-radio-button :label="1">是</el-radio-button>
				<el-radio-button :label="0">否</el-radio-button>
			  </el-radio-group>
		  </el-descriptions-item>
		  <el-descriptions-item label="图标">
				<selectorImg v-model="comData.icon"></selectorImg>
		  </el-descriptions-item>
		</el-descriptions>
		<el-button @click="cancel" type="warning" plain size="mini" icon="el-icon-folder-delete">取消</el-button>
        <el-button @click="save" type="warning" plain size="mini" icon="el-icon-folder-checked">保存</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      action: ''
    }
  },
  created: function () {
  },
  methods: {
	pChange(v){
		this.comData.pId = v[v.length-1]
	},
    save () {
		this.comData.sysId = this.$route.params.id;
      const data = { module: 'RemoteDb', action: "SaveRight", prms: this.comData }
      this.common.request(data, this.saveCallback.bind(this))
    },
    saveCallback (r) {
      if (r.code === 0) {
        this.$message({ message: '保存成功', type: 'success' })
        this.$emit('cancel', '')
      } else this.$message.error(r.msg)
    },
    cancel () {
      this.$emit('cancel', '')
    }
  },
  props: {
    comData: Object,
    list: Array
  }
}
</script>

<style>
  .self{
  	margin: 3px;
  	padding: 5px;
  	width: 660px;
  	border: 1px solid #CCCCCC;
  	box-sizing: border-box;
  }
  .el-form-item{
  	width: 300px;
  }
  .el-select{
    width: 100%;
  }
</style>
