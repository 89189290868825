<template>
    <div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item label="地址列表页">
				<span>链接</span>
				<selectorPage v-model="com.addressPageName" :comData="comData"></selectorPage>
			</el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '地址入口'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
			desc: `地址入口组件主要用作地址的展示，可作为入口组件使用，配置链接跳转地址列表页。`,
			fieldData:[],
            requestData:[]
        };
    },
    created() {},
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>

</style>
