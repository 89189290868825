<template>
	<div>
		<div class="com1" :style="[com.style]">
			<div class="menu-ls" v-for="(itemP,index) in com.data" :key="index">
				<div class="title">{{ itemP.name }}</div>
				<div class="menu-child">
					<div class="child-ls" v-for="(itemC,i) in itemP.children" :key="i">
						<i class="el-icon-menu" style="font-size: 28px;"></i>
						<div class="menu-name">{{ itemC.name }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  props: {
		com: Object
	  }
	}
</script>

<style scoped>
	.com1{
		padding: 0 10px;
	}
	.menu-ls {
		background: #fff;
		border-radius: 5px;
		padding: 10px;
		box-sizing: border-box;
	}
	.title {
		font-size: 16px;
		font-weight: 600;
	}
	.menu-child {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}
	.child-ls {
		flex: 1;
		text-align: center;
		color: #999;
		font-size: 16px;
	}
	.menu-name {
		margin-top: 3px;
	}
</style>