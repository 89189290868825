<template>
    <div class='city' :style="[com.style]">
      <el-card class="box-card">
        <div v-for="(o,i) in list" :key="i" class="text">
          <div class="title item">{{ o.letter }}</div>
          <div class="list">
            <div v-for="(item,j) in o.data" :key="j" class="item">
              <!-- <el-radio v-model="com.city" :label="item" v-if="com.isCheckbox"></el-radio>  -->
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
</template>

<script>
import cityJson from "@/utils/city.js"
export default {
    data() {
        return {
          list: []
        };
    },
    created() {
      this.list = cityJson.cityJson
    },
    methods: {},
    components:{},
    props: {
      com: Object
    }
};
</script>

<style scoped>
.city {
  overflow: auto;
}
.item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.title {
  background-color: #f7f7f7;
}
.list {
  padding: 0 10px;
  font-size: 16px;
}
.city /deep/ .el-card__body {
  padding: 0 !important;
}
</style>
