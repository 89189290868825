<template>
  <el-popover width="250" trigger="click" @show="search">
    <el-radio-group v-model="type" size="mini" @change="redioChanged">
      <el-radio-button label="x">虚拟</el-radio-button>
      <el-radio-button label="s">物理</el-radio-button>
    </el-radio-group>
    <el-cascader v-if="type == 'x'" v-model="names" :options="pageList" clearable separator="-" :props="{ label: 'name', value: 'name' }" @change="changed" class="sj"></el-cascader>
    <el-input v-if="type == 's'" v-model="text" @change="textChanged" class="sj"></el-input>
    <el-button :type="type=='x'?'text':'primary'" size="mini" slot="reference" :title="link" :style="frame=='out'?'':'border:1px solid red'">{{link?.trim()||'设置'}}</el-button>
	</el-popover>
</template>

<script>
export default {
  data () {
    return {
      type: 'x',
      frame: 'current',
      pageList: [],
      names: [],
      text: ''
    }
  },
  created: function () {
    if (this.link) {
      if (this.link.indexOf('-') !== -1) this.names = this.link.split('-')
      this.text = this.link
    }
  },
  methods: {
	  search () {
	    let fun = { module: 'ui', action: 'getList', prms: { sysId: this.$route.params.id } }
	    this.common.request(fun, this.setData.bind(this))
	  },
	  setData (r) {
	    if (r.code === 0) {
	      r = r.data[0]
	      this.pageList = []
	      for (var i = 0; i < r.length; i++) {
	        r[i].ui = JSON.parse(unescape(r[i].json))
	        const fil = this.pageList.filter(item => item.name === r[i].type)
	        if (fil.length === 0) {
	          const node = { name: r[i].type, children: [r[i]] }
	          this.pageList.push(node)
	        } else {
	          fil[0].children.push(r[i])
	        }
	      }
	    }
	  },
    changed (value) {
      this.text = value.join('-')
      this.setValue(value.join('-'))
    },
    textChanged (value) {
      this.names = []
      this.setValue(value)
    },
    redioChanged () {
      this.setValue(this.link)
    },
    setValue (link) {
      this.$emit('setLink', link || '')
    }
  },
  model: {
    prop: 'link',
    event: 'setLink'
  },
  props: {
    link: [Object, String],
    comData: Object
  }
}
</script>

<style scoped="scoped">
  .self{display: inline-block;}
  .sj{
    width: 99%;
  }
</style>
