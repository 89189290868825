import $ from 'jquery'
import axios from 'axios'
export default {
	version: '1.02',
	tableHeight: window.innerHeight - 80, 
  $: $,
  serverUrl: config.serverUrl,
  // serverUrl:'https://www.bjapp11.com/jfql/',
  request (pram, callback, option) {
    const opt = {
      server: this.serverUrl,
      subsys: 'jfql',
      files: [],
      withToken: true,
      token: localStorage.getItem('jfDevToken'),
      file: config.interface
    }
	
    Object.assign(opt, option)
    if (opt.withToken) pram.token = opt.token
    pram.subsys = opt.subsys
	
	let formData = new FormData()
	for (let i = 0; i < opt.files.length; i++) {
	  formData.append('files' + i, opt.files[i])
	}
	formData.append('data', JSON.stringify(pram))
	// axios.post(opt.server + opt.file,formData).then(function (r) {
	// 	if(r.status == 200) {
	// 	r = r.data
 //        if ((r.code === 2 || r.code === 3 || r.code === 7) && (pram.subsys == 'jfql')) {
 //          this.router.push({ path: '/login' }, () => {}, () => {})
 //        } else if (callback) callback(r)
	// 	} else {console.log(r)}
 //      }.bind(this));
	// return
	
    $.ajax({
      url: opt.server + opt.file,
      type: 'post',
      dataType: 'json',
      crossDomain: true,
      xhrFields: { withCredentials: true },
      data: formData,
      contentType: false,
      processData: false,
      success: function (r) {
        if ((r.code === 2 || r.code === 3 || r.code === 7) && (pram.subsys == 'jfql')) {
          this.router.push({ path: '/login' }, () => {}, () => {})
        } else if (callback) callback(r)
      }.bind(this),
      error: function (r) {
        console.log(r)
      }
    })
    delete pram.token
  },
  imgOnError : 'this.src="' + require('@/assets/img/default.png') + '"',
  getImgUrl (url) {
    if (url === null || url === '' || url === 'undefined') return require('@/assets/img/default.png')
    else if (url.indexOf('://') == -1) return this.serverUrl + url
    else return url
  },
  imgError (e) {
    e.target.src = require('@/assets/img/default.png')
  },
  up (arr, index) {
    if (index !== 0) {
      arr[index] = arr.splice(index - 1, 1, arr[index])[0]
    } else {
      arr.push(arr.shift())
    }
  },
  down (arr, index) {
    if (index !== arr.length - 1) {
      arr[index] = arr.splice(index + 1, 1, arr[index])[0]
    } else {
      arr.unshift(arr.splice(index, 1)[0])
    }
  },
  del (arr, index) {
    arr.splice(index, 1)
  },
  getProperty (obj, path) {
    const arr = path.split('.')
    let sub = obj
    for (let i = 0; i < arr.length; i++) {
      sub = sub[arr[i]]
    }
    return sub
  },
  downTxt (name, data) {
    const urlObject = window.URL || window.webkitURL || window
    const export_blob = new Blob([data])
    const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    save_link.href = urlObject.createObjectURL(export_blob)
    save_link.download = name
    const ev = document.createEvent('MouseEvents')
    ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    save_link.dispatchEvent(ev)
  },
	loadScript(url, callback) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      // 处理IE
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            callback();
          }
        }
      } else {
        // 处理其他浏览器的情况
        script.onload = function () {
          callback();
        }
      }
      script.src = url;
      document.body.append(script);
    },
	getTree(arr,pId){
	  	let newArr = []
	  	for(let i = 0; i < arr.length; i++){
	  		if(arr[i].pId == pId){
	  			newArr.push(arr[i])
	  			let childs = this.getTree(arr,arr[i].id)
	  			if(childs.length>0) arr[i].children = childs
	  		}
	  	}
	  	return newArr
	  },
    // 获取日期
    getTime(){
    var date = new Date(),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
    minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
    second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    month >= 1 && month <= 9 ? (month = "0" + month) : "";
    day >= 0 && day <= 9 ? (day = "0" + day) : "";
    var timer = year + '-' + month + '-' + day;
    return timer;
    },
    getNowTime(){
    var date = new Date(),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
    minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
    second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    month >= 1 && month <= 9 ? (month = "0" + month) : "";
    day >= 0 && day <= 9 ? (day = "0" + day) : "";
    var timer = hour + ':' + minute + ':' + second;
    return timer;
    },
	industry:[
		{name:'企业服务',types:[{name:'法律'},{name:'工商'},{name:'财务'},{name:'人力资源'},{name:'会展'},{name:'公关'},{name:'调查'},{name:'管理咨询'},{name:'活动策划'},{name:'办公'}]},
		{name:'教育培训',types:[{name:'幼教早教'},{name:'素质培训'},{name:'职业培训'},{name:'课程付费'},{name:'高等教育'},{name:'语言培训'},{name:'学科辅导'},{name:'学校'},{name:'教培官网'}]},
		{name:'网络IT',types:[{name:'IT'},{name:'网络'},{name:'软件'},{name:'科技'}]},
		{name:'广告传媒',types:[{name:'广告'},{name:'文化传媒'},{name:'包装印刷'}]},
		{name:'食品饮料',types:[{name:'食品饮料'},{name:'茶叶酒水'},{name:'蔬菜水果'},{name:'生鲜'},{name:'奶茶蛋糕'},{name:'农产品'}]},
		{name:'百货',types:[{name:'电商'},{name:'保健品'},{name:'百货'}]},
		{name:'酒店、餐饮、旅游票务',types:[{name:'餐厅'},{name:'饮品'},{name:'旅游票务'},{name:'酒店'},{name:'餐饮官网'},{name:'酒旅官网'}]},
		{name:'房产、物业、运输贸易',types:[{name:'房地产'},{name:'物业管理'},{name:'搬家快递'},{name:'运输'},{name:'餐饮官网'},{name:'贸易'}]},
		{name:'汽车、同城、信息平台',types:[{name:'同城本地'},{name:'汽车汽配'},{name:'汽美/汽修店'},{name:'汽车官网'},{name:'出行/代驾'},{name:'相亲平台'}]},
		{name:'丽人、美容、医疗养生',types:[{name:'美妆护肤'},{name:'美容院'},{name:'美发店'},{name:'美甲美睫'},{name:'洗浴按摩'},{name:'理疗养生'},{name:'丽人官网'}]},
		{name:'体育、健身、休闲娱乐',types:[{name:'健身俱乐部'},{name:' 瑜伽馆'},{name:'舞蹈工作室'},{name:'运动场馆'},{name:'轰趴馆'},{name:'酒吧'},{name:'密室'},{name:'剧本杀'},{name:'其他娱乐'},{name:' 健康娱乐官网'}]},
		{name:'家政、维修、上门服务',types:[{name:'家政保洁'},{name:' 保姆月嫂'},{name:'干洗店'},{name:'维修服务'}]},
		{name:'建筑、家居、设计服务',types:[{name:' 装修'},{name:'家居家纺'},{name:'建材商城'},{name:'建筑建材'},{name:'家私家具'},{name:'家居官网'},{name:'甲醛处理'},{name:'室内设计'},{name:' 园林设计'},{name:' 展览设计'}]},
		{name:'机械、五金、灯光照明',types:[{name:'五金商城'},{name:' 机械官网'},{name:'机械商城'},{name:'安防监控'},{name:'仪器器材'},{name:'医疗器械'},{name:'灯光照明'},{name:'五金官网'}]},
		{name:'婚庆、摄影、生活咨询',types:[{name:'婚庆'},{name:' 摄影'},{name:'摄影店'},{name:'投资理财'},{name:'心理咨询'}]},
		{name:'服饰、箱包、户外用品',types:[{name:'服装'},{name:' 鞋帽箱包'},{name:'珠宝饰品'},{name:'纺织辅料'},{name:'户外用品'},{name:'服饰官网'}]},
		{name:'母婴、宠物、鲜花',types:[{name:'母婴专区'},{name:' 月子会所'},{name:'宠物服务'},{name:'宠物用品'},{name:'户外用品'},{name:'鲜花植物'}]},
		{name:'数码、电器、小商品',types:[{name:'手机数码'},{name:' 电脑'},{name:'电器'},{name:'玩具乐器'},{name:'文具'},{name:'数码电器官网'}]},
		{name:'林牧养殖、环保',types:[{name:'农资农科'},{name:' 养殖牲畜'},{name:'环保回收'}]},
		{name:'医院、政务、机构组织',types:[{name:'医院'},{name:' 体检机构'},{name:'牙科口腔'},{name:' 政务民生'},{name:'机构组织'}]},
	]
}
