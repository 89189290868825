<template>
    <div>
      <el-form label-width="90px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="轮播图显示">
          <div class="rows">
            <el-switch
              v-model="com.showSwiper"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item label="轮播图高度">
          <div class="rows">
            <el-input-number v-model="com.swiperHeight" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="轮播图边距">
          <div class="rows">
            <el-input-number v-model="com.swiperMargin" :min="0" :max="24" label="边距(最大24)" controls-position="right" size="mini"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="轮播图间隔（秒）">
          <div class="rows">
            <el-input-number v-model="com.interval" :min="1" :max="5" label="宽度(最大5)" controls-position="right" size="mini"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="价格标题">
          <div class="rows">
            <el-input v-model="com.priceTitle"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="添加图片">
          <div class="rows">
            <el-button type="primary" size="mini" @click="add">添加图片</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-table :data="com.data[0].swiperData" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
        <el-table-column width="80" label="图片">
          <template slot-scope="scope">
            <selectorImg v-model="scope.row.imgUrl"></selectorImg>
          </template>
        </el-table-column>
        <el-table-column width="180" label="链接">
          <template slot-scope="scope">
            <selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
            <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
            <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
          </template>
        </el-table-column>
      </el-table>
		  <jfExplain v-model="drawer" :desc="desc" :name="com.cName || '商品详情'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
  </template>
  <style>
  
  </style>
  <script>
  export default {
    data () {
      return {
        drawer: false,
        desc: '商品详情如名所示用于商品的详情展示，包含了商品轮播图、标题、价格、规格选择、加入购物车、商品详情介绍、收货地址信息、下单支付……等功能，动态设置接口为商品详情接口，可配置轮播图的高度、间隔等，价格后缀也可自定义。',
        fieldData:[
          {field: 'image',explain: '轮播图照片逗号分隔url', type: 'string'},
          {field: 'video',explain: '轮播图视频', type: 'string'},
          {field: 'price',explain: '售卖价', type: 'string'},
          {field: 'oldPrice',explain: '原价', type: 'string'},
          {field: 'title',explain: '标题', type: 'string'},
          {field: 'addr',explain: '详细地址', type: 'string'},
          {field: 'addrName',explain: '姓名', type: 'string'},
          {field: 'addrPhone',explain: '手机号码', type: 'string'},
          {field: 'street',explain: '省市区', type: 'string'},
          {field: 'serviceType',explain: '商品服务', type: 'string'},
          {field: 'content',explain: '商品详情介绍富文本', type: 'string'},
          {field: 'name',explain: '商品规格名称', type: 'string'}
        ],
        requestData:[
          {name: '商品详情接口',module: 'd_goods_ctlg', action: 'getByPid'},
          {name: '商品规格接口',module: 'd_goods_spec', action: 'getByGoodsId_m'},
          {name: '默认收货地址接口',module: 'd_user_addr', action: 'getListByStatus'},
          {name: '加入购物车接口',module: 'd_goods_cart', action: 'add'},
          {name: '订单表添加',module: 'd_order', action: 'add'},
          {name: '订单商品添加',module: 'd_order', action: 'add_m'},
          {name: '使用优惠券',module: 'd_order', action: 'updatePayment'},
          {name: '优惠券列表',module: 'd_coupon', action: 'getList_m'},
          {name: '优惠券领取',module: 'd_user_coupon', action: 'add'},
          {name: '支付',module: 'WxPay', action: 'xcxPay'}
        ]
      }
    },
    methods: {
      add () {
        this.com.data[0].swiperData.push({ imgUrl: '', pageId: 0 })
      }
    },
    props: { com: Object, comData: Object }
  }
  </script>
  