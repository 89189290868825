<template>
  <el-table :data="comData" stripe tooltip-effect="dark">
    <el-table-column :label="y" show-overflow-tooltip v-for="(y, yIndex) in cols" :key="yIndex">
      <template slot-scope="scope">
        <div>{{scope.row[y]}}</div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data () {
    return {
      cols: []
    }
  },
  created: function () {
    if (this.comData.length > 0) this.cols = Object.keys(this.comData[0])
  },
  methods: {
  },
  props: {
    comData: Array
  },
  watch: {
    comData: {
      handler: function (newValue, oldValue) {
        if (newValue.length > 0) this.cols = Object.keys(newValue[0])
      }
    }
  }
}
</script>

<style>
</style>
