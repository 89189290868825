<template>
  <div class="self">
    <template>
      <div v-for="(item, index) in com.coms" :key="index" :class="'row label' + com.labelPosition"
        :style="{ width: (com.labelWidth + com.ctrlWidth) * (item.span || 1) - 10 + 'px' }">
        <div class="label" :style="{ width: com.labelWidth + 'px' }">{{ item.label }}:</div>

        <div v-if="item.type == 'label'">只读</div>

        <el-input class="item" v-if="item.type == 'input'" :type="item.inputType" :rows="item.rows"
          size="mini"></el-input>

        <img class="item" v-if="item.type == 'image'" src="../../../assets/img/default.png" />

        <div class="filePicker" v-if="item.type == 'filePicker'">+</div>

        <el-date-picker class="item" v-if="item.type == 'date'" size="mini" v-model="date" type="date"
          placeholder="选择日期"></el-date-picker>

        <el-time-select class="item" v-if="item.type == 'time'" size="mini"
          :picker-options="{ start: '08:30', step: '00:15', end: '18:30' }" placeholder="选择时间"></el-time-select>

        <el-date-picker class="item" v-if="item.type == 'datetime'" size="mini" type="datetime"
          placeholder="选择日期时间"></el-date-picker>

        <el-select class="item" v-if="item.type == 'select'" v-model="temp" size="mini" placeholder="请选择"></el-select>

        <el-radio-group class="item" v-if="item.type == 'radio'" v-model="temp" size="mini">
          <el-radio label="选择框1"></el-radio>
          <el-radio label="选择框2"></el-radio>
        </el-radio-group>

        <el-checkbox-group v-if="item.type == 'checkbox'" class="item" v-model="ck">
          <el-checkbox label="选择框1"></el-checkbox>
          <el-checkbox label="选择框2"></el-checkbox>
        </el-checkbox-group>

        <div v-if="item.type == 'lookup'" class="item" style="background-color: white;">
          <input style="width: calc(100% - 50px);" /><el-button icon="el-icon-search" size="mini"></el-button>
        </div>
        <div class="item mapPicker" v-if="item.type == 'mapPicker'">
          <i class="el-icon-add-location">地址选择</i>
        </div>
        <div class="item mapPicker" v-if="item.type == 'coupon'">
          <i class="el-icon-s-ticket">优惠券选择</i>
        </div>
        <div class="item mapPicker" v-if="item.type == 'address'">
          <i class="el-icon-add-location">地址信息选择</i>
        </div>
      </div>

      <div class="buttonPanel" :style="[com.footerStyle,{'bottom':com.bottomNum}]">
        <div class="" :style="[com.leftText?.style || {}]">{{ com.leftText?.prefix || '' }} {{ com.leftText?.value || '' }}</div>
        <div  style="display: flex;justify-content: flex-end;">
          <el-button type="button"  v-if="!btn.hide" v-for="(btn, idx) in com.buttons" :key="idx" size="mini" class="btn" :style="[btn.style]">{{ btn.label }}</el-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  data() {
    return {
      temp: '',
      ck: [],
      date: '',
      time: ''
    }
  },
  methods: {
		getBinding(){
		  return [{label: '底部文本',field:'bottomText'},{label:'订单商品',field:'model.goods'},{label:'订单id',field:'model.orderId'},{label:'清空优惠券',field:'initCoupon()'}]
		}
  },
  props: {
    com: Object,
    clientType: String
  }
}
</script>

<style scoped="scoped">
.self {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}

.labeltop {
  display: '';
}

.labelleft {
  display: flex;
}

.item>input {
  padding: 3px;
}

.label {
  height: 30px;
  width: 25%;
  display: inline-block;
  padding: 6px;
  box-sizing: border-box;
}

.row {
  height: 40px;
  font-size: 16px;
  /* background-color: #b9b9b9; */
}

.item {
  width: 74%;
  /* border: 1px solid #b9b9b9; */
  display: block;
}

.buttonPanel {
  margin: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filePicker {
  height: 40px;
  width: 40px;
  font-size: 50px;
  font-weight: bold;
  background-color: aliceblue;
  text-align: center;
  line-height: 1;
}

.mapPicker {
  background-color: aliceblue;
  font-size: 12px;
  line-height: 3;
}
.btn{
  width: 75px;
  height: 30px;
  border: 1px solid #409EFF;
  border-radius: 10px;
  display: inline-block;
}
.primary{	  border: 1px solid #409EFF;}
.success{	  border: 1px solid #67C23A;}
.info{	  border: 1px solid #909399;}
.warning{	  border: 1px solid #E6A23C;}
.danger{	  border: 1px solid #F56C6C;}
</style>
