<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="com.data[0].nickname"></el-input>
      </el-form-item>
      <el-form-item label="头像">
        <selectorImg v-model="com.data[0].header"></selectorImg>
      </el-form-item>
    </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '个人信息'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '个人信息用来展示用户的头像与昵称。',
      fieldData:[
        {field: 'nickname',explain: '用户名称', type: 'string'},
        {field: 'header',explain: '用户头像', type: 'string'}
      ]
    }
  },
  methods: {
  },
  props: { com: {} }
}
</script>
