export default {
  getHtml (jfList) {
    const html = `<template>
  <div>
    <div class="btnBar">
        <el-row>
          ${this.getSearch(jfList)}
          <el-col :span="6">
            <el-button plain size="mini" @click="search()" type="primary">搜索</el-button>
            <el-button plain size="mini" @click="add" type="primary">添加</el-button>
            <el-button plain size="mini" @click="search()" type="primary">刷新</el-button>
          </el-col>
        </el-row>
    </div>
    <el-table :data="list" :height="tableHeight">
      ${this.getColumn(jfList)}
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
          <el-link type="danger" @click="del(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :pager-count="pagerCount" layout="prev, pager, next" :current-page.sync="currentPage" @current-change="search" :total="total"></el-pagination>
    <el-dialog class="dlg" title="提示" v-if="dvEdit" :visible.sync="dvEdit" :close-on-click-modal="false" :show-close="false" width="50%" @closed="search">
      <orgEdit :currentId="currentId" @cancel="cancelEdit"/>
    </el-dialog>
  </div>
</template>

<script>
  import orgEdit from './orgEdit.vue'
export default {
  components:{orgEdit},
  data () {
    return {
      list: [],
      currentId: null,
      dvEdit: false,
      tableHeight: window.innerHeight - 135,
      pageSize: 10,
      pagerCount: 7,
      currentPage: 1,
      total: 0,
      prms: {
        status: ''
      }
    }
  },
  created: function () {
    this.search()
  },
  methods: {
    search () {
      let data = {module: '${jfList.fun.module}',action:'${jfList.fun.action}',prms:{page:(this.currentPage-1)*this.pageSize,limit:this.pageSize}}
      Object.assign(data.prms,this.prms)
      this.common.request(data, this.callback.bind(this))
    },
    callback (r) {
      this.list = r.data[0]
      this.total = r.data[1][0].count
    },
    add () {
      this.currentId = ''
      this.dvEdit = true
    },
    edit (row) {
      this.currentId = row.id
      this.dvEdit = true
    },
    del (row) {
      this.$confirm('确定删除车辆[ ' + row.num + ' ]吗?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
        let data = {module: '${jfList.fun.module}', action: 'delete', 'prms': {id: row.id}}
        this.common.request(data, this.delCallback.bind(this))
      })
    },
    delCallback (r) {
      if(r.code !== 0) this.$message.error(r.msg);
      else if(r.data[0].count === 0) this.$message.error('无法删除')
      else this.search()
    },
    format(row, column, cellValue, index) {
      switch(cellValue){
        case '0':
        return '正常'
        case '1':
        return '停用'
      }
    },
    cancelEdit () {
      this.dvEdit = false
      this.search()
    }
  }
}
</script>
<style lang='scss' scoped>
  .ttl{
    background-color: #7fccdd;
    height: 30px;
    font-size: large;
    text-align: center;
    color: #134062;
  }
  .btnBar{
    margin: 5px;
    padding: 5px;
    border: #7fccdd 1px solid;
    border-radius:10px;
  }
  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }
  .dlg /deep/.el-dialog__header {
    padding: 5px 20px 6px;
    text-align: center;
    background: #afdcdc;
}
  .dlg /deep/.el-dialog__body {
    padding: 0px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
</style>`
    return html
  },
  getSearch (jfList) {
    const span = parseInt(18 / jfList.prmsSet.length)
    let str = ''
    for (let i = 0; i < jfList.prmsSet.length; i++) {
      str += `
          <el-col :span="${span}">
            <el-input v-model="info.${jfList.prmsSet[i].name}"/>
          </el-col>`
    }
    return str
  },
  getColumn (jfList) {
    let str = ''
    for (let i = 0; i < jfList.cols.length; i++) {
      str += `
      <el-table-column prop="${jfList.cols[i].fieldName}" label="${jfList.cols[i].label}" width="200"/>`
    }
    return str
  }
}
