<template>
	<div>
		<img :src="$route.params.url+com.img"/>
		<div class="com1">{{com.text}}</div>
		<div class="com1">{{com.text2}}</div>
		<div class="com1">{{com.text3}}</div>
	</div>
</template>

<script>
	export default {
	  props: {
		pageObj: Object,
		com: Object
	  }
	}
</script>

<style scoped>
	.com1{
		font-size: 30px;
		color: red;
	}
</style>