<template>
	<div>
		<button @click="com.text=com.text+'BBB-'">{{com.text}}</button>
		<jfPageSelector v-model="com.text" :comData="comData"></jfPageSelector>
		<funSelector v-model="com.fun" :comData="comData" placement="bottom-end"></funSelector>
		<qlImgMgr v-model="com.img"></qlImgMgr>
	</div>
</template>

<script>
export default {
	props: {
		pageObj: Object,
		com: Object
	}
}
</script>

<style scoped>
	.com2{
		font-size: 20px;
		color: blue;
	}
</style>