<template>
	<div>
		<el-form label-width="90px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item label="组件高度(单位vh)">
				<div class="rows">
					<el-input-number v-model="com.ctlgHeight" :min="0" :max="100" :step="10" controls-position="right" size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="左侧栏背景颜色">
				<div class="rows">
					<el-color-picker v-model="com.lBgColor"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="右侧栏背景颜色">
				<div class="rows">
					<el-color-picker v-model="com.rBgColor"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="全部选项">
				<span>显示</span>
				<el-checkbox v-model="com.couponShow"></el-checkbox>
				<!-- <span>链接</span>
				<selectorPage v-model="com.couponPageName" :comData="comData"></selectorPage> -->
			</el-form-item>
			<el-form-item label="下单">
				<span>显示</span>
				<el-checkbox v-model="com.buyShow"></el-checkbox>
				<span>链接</span>
				<selectorPage v-model="com.buyPageName" :comData="comData"></selectorPage>
			</el-form-item>
			<el-form-item label="菜品分类列表">
				<selectorFun v-model="com.dishTypeList" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="菜品列表">
				<selectorFun v-model="com.dishList" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="菜品规格">
				<selectorFun v-model="com.dishSku" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="添加购物车">
				<selectorFun v-model="com.cartAdd" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="购物车列表">
				<selectorFun v-model="com.cartList" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="购物车数量更新">
				<selectorFun v-model="com.cartUpdate" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="购物车删除">
				<selectorFun v-model="com.cartDelete" placement="bottom-end"></selectorFun>
			</el-form-item>
			<el-form-item label="购物车数量更新">
				<selectorFun v-model="com.cartUpdate" placement="bottom-end"></selectorFun>
			</el-form-item>
		</el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '新增/编辑地址'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
	</div>
</template>

<script>
export default {
    data() {
        return {
			drawer: false,
			desc: `点餐列表用于点餐系统，包含了(菜品的类目、列表、规格展示、购物车展示、优惠券、支付下单……)，使用时仅需在接口处设置菜品类目列表接口即可，其余接口为固定接口，使用时根据情况而定。`,
			fieldData:[
				{field: 'name',explain: '分类名称', type: 'string'},
				{field: 'image',explain: '菜品图片', type: 'string'},
				{field: 'name',explain: '菜品名称', type: 'string'},
				{field: 'price',explain: '菜品价格', type: 'string'},
				{field: 'ge',explain: '菜品是否有规格1：有 0：没有', type: 'string'}
			],
			requestData:[
				{name: '餐品分类接口',module: 'dc_category', action: 'getList_m'},
				{name: '当前分类菜单接口',module: 'dc_dish', action: 'getByCId_m'},
				{name: '菜品规格接口',module: 'dc_dish_flavor', action: 'getList_m'},
				{name: '添加购物车接口',module: 'dc_dish_cart', action: 'add_m'},
				{name: '购物车列表接口',module: 'dc_dish_cart', action: 'getList_m'},
				{name: '购物车数量更新接口',module: 'dc_dish_cart', action: 'update'},
				{name: '购物车删除接口(可多个删除)',module: 'dc_dish_cart', action: 'delete_m'},
				{name: '订单创建接口',module: 'dc_orders', action: 'add'},
				{name: '下单菜品详情接口',module: 'dc_orders', action: 'add_m'},
				{name: '下单优惠券使用接口',module: 'dc_orders', action: 'updatePayment'},
			]
        };
    },
	props: {
		com: Object,
		comData: Object
	}
}
</script>

<style scoped>

</style>