<template>
    <div class="jf-order-detail" :style="[com.style]">
		<div class="header flex wrap">
			<div class="fl">
				<div class="address">{{com.addr}}</div>
				<div class="user-info">{{com.addrName}} {{com.addrPhone}}</div>
			</div>
		</div>
		<div class="wrap">
			<div class="content flex" v-for="(it) in com.data" :key="it.id" @click="goDetail(it)">
				<div class="listImg left h100 flex">
					<img class="h100" :src="common.getImgUrl(it.picPath)" v-if="it.picPath"/>
					<!-- <uni-icons type="image" size="60" v-else></uni-icons> -->
				</div>
				<div class="right flex">
					<div class="r-title">
						<div class="goods-name show-ellipsis">{{it.title}}</div>
						<div class="small-font show-ellipsis">{{it.param}}</div>
					</div>
					<div class="r-price">
						<div class="goods-price">
							￥<span class="big-font">{{it.price}}</span>
						</div>
						<div class="small-font">数量 x{{it.num}}</div>
					</div>
				</div>
			</div>
			<div class="detail">
				<div class="parm-item flex">
					<div class="small-font">实付款</div>
					<div>合计￥<span class="big-font">{{com.payment}}</span></div>
				</div>
				<div class="parm-item flex">
					<div class="small-font">订单编号</div>
					<div class="">{{com.orderNo}}</div>
				</div>
				<div class="parm-item flex">
					<div class="small-font">支付方式</div>
					<div class="">{{com.payType || '待支付'}}</div>
				</div>
				<div class="parm-item flex">
					<div class="small-font">支付时间</div>
					<div class="">{{com.paymentTime|| '---'}}</div>
				</div>
				<div class="parm-item flex">
					<div class="small-font">下单时间</div>
					<div class="">{{com.ctime}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    props: {
        com: Object
    }
};
</script>

<style scoped>
    .h100 {
		height: 100%;
	}
	.flex {
		display: flex;
		align-items: center;
	}
	.wrap {
		background-color: #fff;
		border-radius: 10px;
		padding: 10px 15px;
	}
	.jf-order-detail {
		padding: 10px;
		height: 100%;
		background-color: #f7f7f7;
	}
	.header {
		width: 100%;
		min-height: 80px;
		justify-content: space-between;
		font-size: 12px;
		margin: 0 0 10px 0;
        box-sizing: border-box;
		.street {
			margin: 5px 0;
			font-size: 15px;
		}
		.user-info {
			color: #888;
		}
		.address {
			font-size: 14px;
			margin: 5px 0;
		}
	}
	.content {
		height: 100px;
		margin-bottom: 5px;
	    .left {
			width: 30%;
			margin: 0 5px 0 0;
			image {
				transform: scale(0.8);
				border-radius: 7px;
			}
	    }
	    .right {
	        width: 70%;
	        justify-content: space-between;
			.r-title {
				width: 140px;
			}
	        .r-price {
	            text-align: right;
	        }
			.small-font {
			    font-size: 14px;
				color: #888;
			}
	    }
	}
	.big-font {
	    font-size: 18px;
	}
	.detail {
		border-top: 1px solid #f2f2f2;
        font-size: 16px;
		.parm-item {
			padding: 10px 0;
			justify-content: space-between;
			.small-font {
				color: #888;
			}
		}
	}
	.show-ellipsis {
	    overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* 显示的行数 */
		-webkit-box-orient: vertical;
	}
</style>
