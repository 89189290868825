<template>
	<div class="jf-demo">
		<el-cascader
			:size="com.cascaderSize"
		    v-model="com.cascaderValue"
			placeholder="com.placeholder"
		    :options="com.data"></el-cascader>
	</div>
</template>

<script>
	export default {
	  props: {
		com: Object
	  }
	}
</script>

<style scoped>
	.com1{
		font-size: 30px;
		color: red;
	}
</style>