<template>
    <div class='' :style="[com.style]">
      <div id="container"></div>
    </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
    data() {
        return {
          map:null,
          AMap: null,
          config: {}
        };
    },
    mounted() {
      this.config = this.com.data[0]
      this.initAMap();
    },
    unmounted() {
      // this.map?.destroy();
    },
    methods: {
      initAMap() {
        window._AMapSecurityConfig = {
          securityJsCode: "71667f46203e5deadf49612d38385f1d",
        };
        AMapLoader.load({
          key: "6d6a60ea3bdf500469fb584fa5ae2b1c", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
        })
          .then((AMap) => {
            this.AMap= AMap
            this.resetMap(AMap)
          })
          .catch((e) => {
            console.log(e);
          });
      },
      resetMap(AMap) {
        var position = new AMap.LngLat(this.config.longitude || 116.397428, this.config.latitude || 39.90923);
        this.map = new AMap.Map("container", {
            zoom: this.config.zoom || 13,
            center: position,
            resizeEnable: true,
            dragEnable: false, //是否拖拽
        });
        if(!this.config.isShowImg) return
        var marker = new AMap.Marker({
            position: position,
            icon: new AMap.Icon({
              size: new AMap.Size(25, 35), // 图标大小
              image: this.config.markerImg ? this.$route.params.url+this.config.markerImg : "https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              imageSize: new AMap.Size(25, 35) // 根据图标大小调整
            }),
            anchor: 'bottom-center',
        });
        this.map.add(marker);
      }
    },
    watch:{
      'com.data': {
        handler: function(newVal,oldVal){
          this.config = newVal[0]
          this.resetMap(this.AMap)
        },
        deep: true
      }
    },
    props: {
      com:Object
    }
};
</script>

<style scoped>
  #container {
    width: 100%;
    height: 100%;
  }
</style>
