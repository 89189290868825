<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
            <el-form-item label="组件说明">
                <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
            </el-form-item>
            <el-form-item label="标题">
		        <el-input v-model="com.title"></el-input>
            </el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题颜色">
                <el-color-picker v-model="com.titleFontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="进度条设置">
                <div>
                    <span>进度：</span>
                    <el-input-number v-model="com.data[0].progressVal" controls-position="right" :min="0" :max="100"></el-input-number>
                </div>
                <div>
                    <span>高度：</span>
                    <el-input-number v-model="com.progressHeight" controls-position="right" :min="1" :max="100"></el-input-number>
                    <span>颜色：</span>
                    <el-color-picker v-model="com.progressColor" show-alpha></el-color-picker>
                </div>
            </el-form-item>
        </el-form>
        <jfExplain v-model="drawer" :name="com.cName || '进度条'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            desc: '进度条组件可通过设置标题内容来展示对应的进度值，可设置不同样式的进度条灵活展示UI界面。',
            fieldData:[
                {field: 'progressVal',explain: '进度条进度值', type: 'number'}
            ]
        };
    },
    created() {},
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>

</style>
