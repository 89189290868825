<template>
  <div class="self" :style="[com.style]">
    <div class="left">
      <div class="f cart" v-if="!item.hide" v-for="(item,index) in com.icons" :key="index" :style="[item.style,{'color':item.color,'background':item.backgroundColor}]">
        <span :class="handleType(item.icon)"></span>
        <p>{{ item.text }}</p>
      </div>
    </div>
    <div>
      <span style="font-size: 16px;" :style="[com.input.prefixStyle]">{{ com.input.prefix }}</span>
      <input v-if="com.input.isInput" class="inp-value" v-model="com.input.value" :placeholder="com.input.placeholder" :style="[com.input.style,{'pointer-events': com.input.isDisabled ? 'auto' : 'none'}]">
    </div>
    <div class="f btns">
        <p  v-if="!item.hide" v-for="(item,index) in com.buttons" :key="index" :style="[item.style,{'color':item.color,'background':item.backgroundColor}]">{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  methods: {
    handleType(icon) {
      switch (icon) {
        case '购物车':
          return 'el-icon-shopping-cart-full'
          break;
        case '客服':
          return 'el-icon-chat-dot-round'
          break;
        case '评论':
          return 'el-icon-chat-line-round'
          break;
        case '电话':
          return 'el-icon-phone-outline'
          break;
        case '收藏':
          return 'el-icon-star-off'
          break;
        case '点赞':
          return 'el-icon-thumb'
          break;
        default:
          break;
      }
    },
		getBinding(){
		  return [{label: '商品详情',field:'goods'}]
		}
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>

<style scoped>
.self{height: 50px;background: #fff;width: 100%;padding: 3px 10px; box-sizing: border-box;display: flex;align-items: center;}
.f{float: left; text-align: center;}
.f span{font-size: 20px;}
.f p{font-size: 14px;margin: 0;}
.cart{width: 50px;}
.comment{width: 40px; }
.left {
  height: 100%;
  flex-shrink: 0;
  display: flex;
}
.inp-value {
  width: 150px;
  height: 30px;
  margin: 0 10px;
  background: #DAD9D9;
  border: none;
  border-radius: 15px;
  padding: 0 5px;
  font-size: 14px;
}
.btns{width: auto; border-radius: 100px; overflow: hidden;display: flex;flex: 1;flex-shrink: 0;align-items: center;}
.btns p{ flex: 1; padding: 5px 10px 5px; }
</style>
