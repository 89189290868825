<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
            <el-form-item label="组件说明">
                <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
            </el-form-item>
            <el-form-item label="跳转依赖字段">
                <el-input v-model="com.field" size="mini" style="width: 130px;"></el-input>
            </el-form-item>
            <el-form-item label="类型一">
                <span>链接</span>
                <selectorPage v-model="com.lineOneType" :comData="comData"></selectorPage>
            </el-form-item>
            <el-form-item label="类型二">
                <span>链接</span>
                <selectorPage v-model="com.linkTwoType" :comData="comData"></selectorPage>
            </el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题">
		        <el-input v-model="com.title" size="mini" style="width: 130px;"></el-input>
            </el-form-item>
            <el-form-item label="列数">
                <el-input-number v-model="com.column" :min="1" :max="4" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
            </el-form-item>
            <el-form-item label="添加代办">
				<el-button type="primary" size="mini" @click="add()">添加代办</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
			<el-table-column width="150" label="标题">
			<template slot-scope="scope">
				<el-input v-model="scope.row.msg"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="150" label="数量">
			<template slot-scope="scope">
				<el-input v-model="scope.row.count"></el-input>
			</template>
			</el-table-column>
			<el-table-column label="操作" width="70">
			<template slot-scope="scope">
				<div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
				<div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
				<div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
			</template>
			</el-table-column>
		</el-table>
        <jfExplain v-model="drawer" :name="com.cName || '数字面板'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            desc: '数字面板是一个事项统计组件，可以展示如待办事项、已办事项等功能，可灵活使用。跳转可在链接设置，多个类型跳转需按情况而定。',
            fieldData:[
                {field: 'count',explain: '数量', type: 'number'},
                {field: 'msg',explain: '标题', type: 'string'},
                {field: 'pageName',explain: '跳转路径', type: 'string'}
            ]
        };
    },
    created() {},
    methods: {
        add() {
            this.com.data.push({msg:"待**",count:0});
        },
    },
    props:{
        com: Object
    }
};
</script>

<style scoped>

</style>
