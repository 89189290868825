<template>
  <div class="funself">
    <el-row>
        <el-button class="btn" plain size="mini" @click="newItem.visible = true" type="primary">新建</el-button>
    <el-button class="btn" plain size="mini" @click="panelImport.visible=true" type="warning">导入</el-button>
        <el-button class="btn" plain size="mini" @click="search" type="warning">刷新</el-button>
    </el-row>
    <el-tree
      :data="funList"
      node-key="id"
      @node-click="funNodeClick">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.action }}</span>
        <span>
			<i v-if="node.isLeaf" class="el-icon-document-copy" @click.stop="copyFun(node, data)"></i>
			<i v-if="node.isLeaf" class="el-icon-delete" @click.stop="del(node,data)"></i>
        </span>
      </span>
    </el-tree>
	<el-dialog title="拷贝" :visible.sync="diaCopy" width="40%" :close-on-click-modal="false">
		<div style="color:red;font-size: 24px; text-align:center;">操作一定要谨慎！</div>
		<el-table ref="table" :data="sysList" @selection-change="selectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="name" label="名称" width="120"></el-table-column>
		</el-table>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="diaCopy = false">取 消</el-button>
		  <el-button type="primary" @click="copy">确 定</el-button>
		</div>
	</el-dialog>
    <el-dialog :title="newItem.title" :visible.sync="newItem.visible" width="350px" @close="dgClose" :close-on-click-modal="false">
      <el-descriptions :column="1" title="" border>
        <el-descriptions-item label="模块" label-width="80px">
          <el-input v-model="newItem.module" autocomplete="off"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="操作" label-width="80px">
          <el-input v-model="newItem.action" autocomplete="off"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newItem.visible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
	<el-dialog :title="panelImport.title" top="5vh" v-if="panelImport.visible" :visible.sync="panelImport.visible" :close-on-click-modal="false" width="90%">
		<importFun @close="search();panelImport.visible=false"></importFun>
	</el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
		loading: null,
      newItem: {title: '新建接口', module: '', action: '', visible: false },
      panelImport: { title: '导入模块', name: '', type: '', visible: false },
      funList: [],
	  diaCopy: false,
	  sysList: [],
	  selection: null
    }
  },
  created: function () {
    this.search()
  },
  methods: {
	  selectionChange(val) {
		  	if (val.length > 1) { 
		  		this.$refs.table.clearSelection(); 
		  		this.$refs.table.toggleRowSelection(val.pop()); 
		  	} else if(val.length == 1){
				this.selection = val[0].id;
			}
	  },
    search () {
      const data = { module: 'sysfun', action: 'getList', prms: { sysId: this.$route.params.id, page: 0, limit: 10000 } }
      this.common.request(data, this.setFunData.bind(this))
	  
	  let fun = { module: 'sys', action: 'getList', prms: {} }
	  this.common.request(fun, this.sysListCb.bind(this))
    },
	sysListCb(r){
		if(r.code == 0){
			this.sysList = r.data[0]
		}
	},
    setFunData (data) {
      this.funList = this.getFunTree(data.data[0])
    },
	copy(){
		if(this.selection){
			const fun = { module: 'sysfun', action: 'copy', prms: { from: this.$route.params.id, to:this.selection } }
			this.common.request(fun, this.copyCb.bind(this))
		}
	},
	copyCb(r){
		if(r.code == 0){
			this.$message.success('拷贝成功')
			this.diaCopy = false
		} else this.$message.error(r.msg)
	},
    copyFun (node, nodeData) {
      this.newItem.title = '复制接口'
      this.newItem.visible = true
      this.newItem.fromId = nodeData.id
      this.newItem.module = nodeData.module
    },
    add () {
      const data = { module: 'sysfun', action: 'add', prms: { sysId: this.$route.params.id, module: this.newItem.module, action: this.newItem.action } }
      if (this.newItem.title === '复制接口') {
        data.action = 'copy'
        data.prms.fromId = this.newItem.fromId
      }
	  this.common.request(data, this.callback.bind(this))
    },
    del (node,nodeData) {
      this.$confirm('确定删除接口[ ' + nodeData.module + '-' + nodeData.action + ' ]吗?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'sysfun', action: 'delete', prms: { id: nodeData.id,sysId: this.$route.params.id } }
        this.common.request(data, this.delCb.bind(this,node,nodeData))
        this.$emit('del', nodeData)
      })
    },
	delCb(node,data,r){
		if(r.code == 0){
			const parent = node.parent;
			const children = parent.data.children || parent.data;
			const index = children.findIndex(d => d.id === data.id);
			children.splice(index, 1);
		}
	},
    callback (data) {
      if (data.code === 0) {
        this.search()
        this.newItem.visible = false
      } else this.$message.error(data.msg)
    },
    publish () {
		this.loading = this.$loading({ lock: true, text: '正在发布', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      const data = { module: 'RemoteRedis', action: 'PublishFun', prms: { sysId: this.$route.params.id } }
      this.common.request(data, this.publishCallback.bind(this))
    },
    publishCallback (data) {
		this.loading.close()
      if (data.code === 0) this.$message({ message: '发布成功！', type: 'success' })
      else this.$message.error(data.msg)
    },
    funNodeClick (data, node) {
      if (node.isLeaf) {
        this.$emit('select', data)
      }
    },
    getFunTree (data) {
      const arr = []
      for (var i = 0; i < data.length; i++) {
        const fil = arr.filter(item => item.action === data[i].module)
        if (fil.length === 0) {
          const node = { action: data[i].module, children: [data[i]] }
          arr.push(node)
        } else {
          fil[0].children.push(data[i])
        }
      }
      return arr
    },
    dgClose () {
      this.newItem.module = ''
      this.newItem.action = ''
    }
  },
  props: {
    sysId: String
  }
}
</script>

<style scoped="scoped">
	.funself{height: 100%; overflow: auto;}
	.funself::-webkit-scrollbar { width: 0 !important }
  .btn{
    margin-left: 1px;
    padding: 3px 3px;
  }
</style>
