<template>
  <div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="圆角">
          		<el-input-number v-model="com.borderRadius" :min="0" :max="100" :step="10" controls-position="right" size="mini"></el-input-number>
            </el-form-item>
            <el-form-item label="图片高度">
		        <el-input-number v-model="com.imgHeight" :min="10" :max="1000" :step="10" controls-position="right" size="mini"></el-input-number>
            </el-form-item>
			<el-form-item label="价格标题">
			<div class="rows">
				<el-input v-model="com.priceTitle"></el-input>
			</div>
			</el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="价格颜色">
		        <el-color-picker v-model="com.priceColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="添加商品">
				<el-button type="primary" size="mini" @click="add()">添加商品</el-button>
            </el-form-item>
        </el-form>
		<el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
			<el-table-column width="80" label="图片">
			<template slot-scope="scope">
				<selectorImg v-model="scope.row.imgUrl"></selectorImg>
			</template>
			</el-table-column>
			<el-table-column width="90" label="标题">
			<template slot-scope="scope">
				<el-input v-model="scope.row.title"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="90" label="价格">
			<template slot-scope="scope">
				<el-input v-model="scope.row.price"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="90" label="原价">
			<template slot-scope="scope">
				<el-input v-model="scope.row.oldPrice"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="150" label="链接">
			<template slot-scope="scope">
				<selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
			</template>
			</el-table-column>
			<el-table-column width="90" label="参数">
			<template slot-scope="scope">
				<el-input v-model="scope.row.query"></el-input>
			</template>
			</el-table-column>
			<el-table-column label="操作" width="70">
			<template slot-scope="scope">
				<div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
				<div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
				<div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
			</template>
			</el-table-column>
		</el-table>
		<el-row>
			<el-col :span="5">
				是否显示原价
			</el-col>
			<el-col :span="5">
				<el-switch
				v-model="com.isPrice"
				active-color="#13ce66"
				inactive-color="#ff4949">
				</el-switch>
			</el-col>
		</el-row>
		<jfExplain v-model="drawer" :name="com.cName || '商品列表'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style scoped="scoped">

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '商品列表是一个类似瀑布流的列表组件，通过配置接口及字段，来实现组件的展示，有分页效果；也可配置链接来实现跳转页面，一般配合商品详情页来使用。',
      fieldData:[
        {field: 'title',explain: '商品标题', type: 'string'},
        {field: 'price',explain: '商品价格', type: 'string'},
        {field: 'proPrice',explain: '促销价', type: 'string'},
        {field: 'oldPrice',explain: '原价', type: 'string'},
        {field: 'image',explain: '商品图片', type: 'string'},
      ]
    }
  },
  methods: {
    add () {
      this.com.data.push({ title: '???', imgUrl: '', pageName: '', query: '' })
    }
  },
  props: { com: {}, comData: Object }
}
</script>
