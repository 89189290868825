<template>
    <div class="container">
        <div class='addAddress'>
            <div class="pad30">
                <div class='address_val acea-row borderRadius15'>
                    <textarea v-model="info.addressValue" auto-height
                        placeholder="粘贴地址信息，自动拆分姓名、电话和地址" placeholder-class='placeholder' style="width:100%;" />
                </div>
                <div class="btn-handler">
                    <div class="btn" @click="addressValue = ''">清空</div>
                    <div class="btn">解析地址</div>
                </div>
            </div>
            <div class="pad30 mt-22">
                <div class='list borderRadius15'>
                    <div class='item acea-row row-between-wrapper'>
                        <div class='name'>姓名</div>
                        <input type='text' placeholder='请输入姓名' name='addrName' v-model="info.addrName" />
                    </div>
                    <div class='item acea-row row-between-wrapper'>
                        <div class='name'>联系电话</div>
                        <input type='number' placeholder='请输入联系电话' name="addrPhone" v-model='info.addrPhone' />
                    </div>
                    <div class='item acea-row row-between-wrapper'>
                        <div class='name'>所在地区</div>
                        <input type='text' placeholder='请选择地区' name='street' v-model="info.street" />
                        <div style="display: flex;justify-content: flex-end;">
                            <!-- <pickerArea :com="com" @chooseAddress="chooseAddress"></pickerArea> -->
                        </div>
                    </div>
                    <div class='item acea-row row-between-wrapper'>
                        <div class='name'>详细地址</div>
                        <div class="address">
                            <input type='text' placeholder='请填写具体地址' name='addr' v-model='info.addr' class="detail" />
                        </div>
                    </div>
                    <div class='item acea-row row-between-wrapper'>
                        <div class="status" style="width:100%;display: flex;justify-content: flex-end;align-items: center;">
                            设为默认地址<el-switch style="transform: scale(0.8);" color="#dd524d" v-model="info.status" />
                        </div>
                    </div>
                </div>
            </div>
			<div style="display: flex;justify-content: center;">
				<button class='keepBnt bg-color' form-type="submit">立即保存</button>
			</div>
        </div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            info: this.com.data[0]
        };
    },
    created() {},
    methods: {},
    components:{},
    props: {
        com: Object
    }
};
</script>

<style scoped>
	.container{
		background-color: #f5f5f5;
		height: 100%;
		padding-top:15px;
	}
	input {
        border: none;
    }
	.pad30 {
		padding: 0 15px
	}
	
	.p_center {
		text-align: center;
	}
	
	.acea-row {
		display: flex;
	}
	.row-middle {
		align-items: center
	}
	.row-between-wrapper {
		align-items: center;
		justify-content: space-between
	}
	.borderRadius15 {
		border-radius: 7px !important;
	}
	.fontcolor{
		color: var(--view-theme);
	}
	.addAddress .list {
		background-color: #fff;
	}
	
	.addAddress .list .item {
		padding: 15px;
		border-top: 1px solid #eee;
		position: relative;
	}
	
	.addAddress .list .item .detail{
		width: 184px;
	}
	
	.addAddress .list .item .location{
		position: absolute;
		right: 13px;
		top: 50%;
		margin-top: -20px!important;
		font-size: 12px;
		text-align: center;
	}
	
	.addAddress .list .item .icon-dizhi{
		 font-size: 18px!important;
	}
	
	.addAddress .list .item .name {
		width: 97px;
		font-size: 15px;
		color: #333;
	}
	
	.addAddress .list .item .address {
		flex: 1;
	}
	
	.addAddress .list .item .address .addressCon{
		width: 180px;
	}
	
	.addAddress .list .item .address .addressCon .tip{
		font-size: 10px;
		margin-top: 2px;
	}
	
	.addAddress .list .item input {
		flex: 1;
		font-size: 15px;
	}
	
	.placeholder {
		color: #ccc;
	}
	
	.addAddress .list .item .picker {
		width: 215px;
		font-size: 15px;
	}
	
	.addAddress .list .item .iconfont {
		font-size: 15px;
		margin-top: 2px;
	}
	
	.addAddress .default {
		padding: 0 15px;
		height: 45px;
		background-color: #fff;
	}
	
	.addAddress .default checkbox {
		margin-right: 7px;
	}
	
	.addAddress .keepBnt {
		width: 245px;
		height: 43px;
		border-radius: 25px;
		text-align: center;
		line-height: 43px;
		margin: 25px auto;
		font-size: 16px;
		color: #fff;
        border: none;
	}
	.mt-22{
		margin-top: 11px;
	}
	.bg-color {
		background-color: #dd524d;
	}
	.address_val{
		padding: 5px 15px;
		min-height: 75px;
		background-color: #fff;
	}
	.btn-handler {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 15px 0 0;
		.btn {
			padding: 5px 10px;
			border: 1px solid #ccc;
			border-radius: 15px;
			background: #fff;
			opacity: 0.8;
			margin-left: 5px;
		}
	}
</style>
