<template>
  <div>
    <p>ffffffffffffffffffffffffff</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  methods: {
	  search () {
		  this.common.navigateTo({ pageName: 'ff' })
	  }
  },
  props: {
    pageObj: Object,
    com: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style>

</style>
