import { render, staticRenderFns } from "./jfImEditor.vue?vue&type=template&id=40fa8f93&scoped=true&"
import script from "./jfImEditor.vue?vue&type=script&lang=js&"
export * from "./jfImEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fa8f93",
  null
  
)

export default component.exports