<template>
    <div class="jf-column-chart" :style="[com.style]">
        <div class="wrap" :style="{ backgroundColor: com.bgColor }">
            <div class="title" :style="{ color: com.titleFontColor }">{{ com.title }}</div>
            <div class="chart" :style="{height: com.height+'px'}">
                <qiun-vue-ucharts :type="com.type" :opts="opts" :chartData="chartData"/>
            </div>
        </div>
    </div>
</template>

<script>
import qiunVueUcharts from '@qiun/vue-ucharts'

export default {
    components: {
        qiunVueUcharts
    },
    data() {
        return {
            chartData: this.com.data[0],
            //您可以通过修改 config-ucharts.js 文件中下标为 ['column'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
            opts: this.com.opts
        };
    },
    mounted() {
    },
    methods: {},
    props: { com: Object }
};
</script>

<style scoped>
/* 请根据实际需求修改父元素尺寸，组件自动识别宽高 */
.jf-column-chart {
    padding: 10px;
    box-sizing: border-box;
}

.wrap {
    padding: 10px;
    border-radius: 10px;
}

.wrap .title {
    padding: 5px 0;
    font-weight: 600;
}

.wrap .chart {
    padding: 5px 0;
}
</style>