<template>
	<div :class="'jfDragPanel scale_'+clientType" @mousemove.stop.prevent="move" @drop.stop="drop($event)" @dragover.stop="allowDrop($event)" @mousedown="panelMousedown">
		<div :class="current.isSelect(com.coms, index)?'selected':''" tabindex="1" style="overflow: hidden;" v-for="(item, index) in com.coms" :key="index" :style="item.style"
		  @mousedown.stop="mousedown($event,item,index,'moving')"
		  @mouseup.stop="dragend"
		  @keydown.stop="keydown"
		  @keyup.stop="keyup"
		  >
			<component v-bind:is="item.name" :com.sync="item" :current="current" :clientType="clientType" :saveMethod="saveMethod"></component>
			<div v-if="current.isSelect(com.coms, index)" class="rb" @mousedown.stop="mousedown($event,item,index,'sizing')" @mouseup.stop="dragend"/>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	// components:window.mycom,
	
  data () {
    return {
      posStart: { x: 0, y: 0 },
      sizing: false,
      moving: false
    }
  },
  methods: {
    allowDrop (e) {
    	e.preventDefault()
    },
    drop (e) {
    	let com = e.dataTransfer.getData('json')
    	const type = e.dataTransfer.getData('type')
    	if (com) {
    	  com = JSON.parse(unescape(com))
		  // if(type == '测试组件') com=window.mycom.coms[com.name+'Data'].data
		  // let funs = []
		  // let keys = Object.keys(com)
		  // for(let i = 0; i < keys.length; i++){
			 //  if(Array.isArray(com[keys[i]])) funs.push({fun:null,dataName:keys[i]})
		  // }
		  // com.funs = funs
    	  // com.id = comId
    	  // let newItem = $.extend(true, this.baseComData.data(), com)
    	  // const newItem = Object.assign({ style: {}, fun: null, link: '' }, com)
        let max = 0;
        for(let i = 0; i < this.com.coms.length; i++){
          if(this.com.coms[i].name == com.name){
        console.log(this.com.coms[i].id,com.name);
            let id = parseInt(this.com.coms[i]?.id.replace(com.name,''))
            if(id > max) max = id
          }
        }
    	  const newItem = Object.assign({ style: {}, fun: null, link: '', id: com.name+(max+1)}, com)
        // newItem.style.left = e.offsetX + 'px'
        // newItem.style.top = e.offsetY + 'px'
    	  this.com.coms.push(newItem)
        this.$emit('add', newItem)
    	  this.current.init()
        this.current.select(this.com.coms, this.com.coms.length - 1)
        e.preventDefault()
      }
    },
    panelMousedown (e) {
      if (this.current.coms === this.com.coms) this.current.init()
    },
    mousedown (e, com, index, type) {
      if (e.ctrlKey) {
        this.current.toggle(this.com.coms, index)
      } else {
        if (!this.current.isSelect(this.com.coms, index)) {
          this.current.init()
          this.current.select(this.com.coms, index)
        }
      }
      this.posStart.x = e.clientX
      this.posStart.y = e.clientY
      this[type] = true
    },
    move (e) {
      if (this.sizing) this.rbDrag(e)
      if (this.moving) this.drag(e)
    },
    drag (e) {
      if (!e.clientX || !e.clientX) return
      const els = $(this.$el).children('.selected')
      // debugger
      for (let i = 0; i < els.length; i++) {
        const el = els[i]
        const x = e.clientX - this.posStart.x + parseInt(el.style.left)
        const y = e.clientY - this.posStart.y + parseInt(el.style.top)
        el.style.left = x + 'px'
        el.style.top = y + 'px'
      }
      this.posStart.x = e.clientX
      this.posStart.y = e.clientY
    },
    dragend (e) {
      const els = $(this.$el).children()
      for (let i = 0; i < this.current.selection.length; i++) {
        const index = this.current.selection[i]
        const el = els[index]
        const com = this.current.coms[index]
        com.style.left = el.style.left
        com.style.top = el.style.top
        com.style.width = el.style.width
        com.style.height = el.style.height
      }
      this.moving = false
      this.sizing = false
    },
    rbDrag (e) {
      if (!e.clientX || !e.clientX) return
      const els = $(this.$el).children('.selected')
      for (let i = 0; i < els.length; i++) {
        const el = els[i]
        const width = el.style.width === '' ? el.offsetWidth : parseInt(el.style.width)
        const height = el.style.height === '' ? el.offsetHeight : parseInt(el.style.height)
        const x = e.clientX - this.posStart.x + width
        const y = e.clientY - this.posStart.y + height
        el.style.width = x + 'px'
        el.style.height = y + 'px'
      }
      this.posStart.x = e.clientX
      this.posStart.y = e.clientY
    },
    keydown (event) {
      event.stopPropagation()
      event.preventDefault()
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (!e || !this.current.selection.length === 0) return
      if (e.keyCode === 46) {
        this.current.del()
        return
      }
      if (![37, 38, 39, 40].includes(e.keyCode)) return
      const step = e.ctrlKey ? 10 : 1
      const els = $(this.$el).children('.selected')
      for (let i = 0; i < els.length; i++) {
        const st = els[i].style
        switch (e.keyCode) {
          case 37:
            if (e.shiftKey) st.width = els[i].offsetWidth - step + 'px'
            else st.left = parseInt(st.left) - step + 'px'
            break
          case 38:
            if (e.shiftKey) st.height = els[i].offsetHeight - step + 'px'
            else st.top = parseInt(st.top) - step + 'px'
            break
          case 39:
            if (e.shiftKey) st.width = els[i].offsetWidth + step + 'px'
            else st.left = parseInt(st.left) + step + 'px'
            break
          case 40:
            if (e.shiftKey) st.height = els[i].offsetHeight + step + 'px'
            else st.top = parseInt(st.top) + step + 'px'
            break
        }
      }
    },
    keyup (event) {
      this.dragend()
    }
  },
  props: {
    com: Object,
    current: Object,
	clientType:String,
	saveMethod: Function
  }
}
</script>

<style>
	.jfDragPanel{
	all: initial;
	display: block;
	height: 5000px;
    overflow: hidden;
	background-color: #999999;
    border: 1px #C0C4CC solid;
    box-sizing:border-box;
    position: relative;
	transform-origin:0 0;
	/* transform: scale(0.8); */
	}
	.scale_pc{width: 125%;transform: scale(0.8);}
	.scale_m{width: 100%;transform: scale(1);}
  .selected{
    border: 1px red solid;
    box-sizing:border-box;
  }
  .selected:focus{outline: none;}
  .lt{}
  .lm{}
  .lb{}
  .mt{}
  .mb{}
  .rt{}
  .rm{}
  .rb{
    border: 1px solid red;
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 5px;
    height: 5px;
    cursor: nw-resize;
  }
  .temp{
		display: flex;
		height: 80px;
		font-size: 20px;
		background-color: coral;
		justify-content: center;
		align-items: center;
	}
</style>
