<template>
    <div class=''>
        <el-drawer
        title="组件说明"
        :visible.sync="drawer"
        direction="rtl" @close="close" @closed="close">
            <div class="container" style="padding: 20px;">
                <div class="title">组件名称: {{ name }}</div>
                <div class="title">组件描述</div>
                <div class="desc">{{ desc }}</div>
                <div class="title">字段说明</div>
                <el-table :data="fieldData" stripe style="width: 100%">
                    <el-table-column prop="field" label="字段"></el-table-column>
                    <el-table-column prop="explain" label="说明"></el-table-column>
                    <el-table-column prop="type" label="类型"></el-table-column>
                </el-table>
                <template v-if="requestData && requestData.length > 0">
                    <div class="title">接口说明</div>
                    <el-table :data="requestData" stripe style="width: 100%">
                        <el-table-column prop="name" label="接口名"></el-table-column>
                        <el-table-column prop="module" label="module"></el-table-column>
                        <el-table-column prop="action" label="action"></el-table-column>
                    </el-table>
                </template>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: this.value
        };
    },
    watch: {
        value(newVal) {
            this.drawer = newVal
        }
    },
    methods: {
        close() {
            this.$emit('input', this.drawer)
        }
    },
    props:{
        value: Boolean,
        fieldData: Array,
        desc: String,
        name: String,
        requestData:Array
    }
};
</script>

<style scoped>
    .desc {
        font-size: 14px;
        padding: 10px;
        background: #f2f2f4;
        margin: 5px 0 20px;
        border-radius: 10px;
        text-indent: 3ch;
    }
    .title {
        font-size: 16px;
        font-weight: 600;
        margin: 10px 0;
    }
</style>
