<template>
	<div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item label="中心点-经度">
				<div class="rows">
					<el-input-number v-model="com.data[0].longitude" controls-position="right" size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="中心点-纬度">
				<div class="rows">
					<el-input-number v-model="com.data[0].latitude" controls-position="right" size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="缩放等级">
				<div class="rows">
					<el-input-number v-model="com.data[0].zoom" :min="3" :max="20" controls-position="right" size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="标点图">
				<div class="rows">
					<el-radio-group v-model="com.data[0].isShowImg">
            <el-radio-button :label="true">显示</el-radio-button>
            <el-radio-button :label="false">隐藏</el-radio-button>
          </el-radio-group>
				</div>
				<div class="rows">
					<selectorImg v-model="com.data[0].markerImg"></selectorImg>
				</div>
			</el-form-item>
    </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '地图'" :desc="desc" :fieldData="fieldData"></jfExplain>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			desc: '地图组件用于位置展示，通常在公司介绍、服务详情……等场景使用',
			fieldData:[
				{field: 'latitude',explain: '纬度', type: 'string'},
				{field: 'longitude',explain: '经度', type: 'number'},
			]
		}
	},
	props: {
		com: Object
	}
}
</script>

<style scoped>

</style>