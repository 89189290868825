<template>
  <div class="audioBox">
    <img src="https://www.bjapp11.com/svr/upload/lv/5.jpg"/>
    <!-- <img :src="$route.params.url+com.data[0].poster" :onerror="common.imgOnError" /> -->
    <div class="bi">
      <div class="phoneText">{{com.data[0].title||'标题'}}</div>
      <div class="phoneText">{{com.data[0].author||'作者'}}</div>
          <audio>
            <source :src="com.data[0].src" type="audio/ogg" />
            <source :src="com.data[0].src" type="audio/mpeg" />
            不支持音频
          </audio>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>

<style scoped>
  .audioBox{
    display: flex;
    flex-direction: row;
    background-color: azure;
  }
  .audioBox>img{
    height: 50px;
    width: 50px;
  }
  .bi{

  }
  .phoneText{
    padding: 5px 15px;
  }
</style>
