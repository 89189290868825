<template>
    <div class='jf-logout-editor'>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="按钮文本">
          <el-input v-model="com.title" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="弹框提示文本">
          <el-input v-model="com.detail" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="背景颜色">
          <el-color-picker v-model="com.bgColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="文本颜色">
          <el-color-picker v-model="com.fontColor"></el-color-picker>
        </el-form-item>
      </el-form>
      <jfExplain v-model="drawer" :name="com.cName || '退出登录'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
          drawer: false,
          desc: '退出登录，如名所示只用于退出登录状态使用，可配置按钮文本、提示文本及样式等。',
          fieldData:[]
        }
    },
    created() {},
    methods: {},
    props:{
        com: Object
    }
};
</script>

<style scoped>

</style>
