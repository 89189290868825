<template>
    <div class="imgTemplate">
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="图文类型">
          <div class="ImgText fl" @click="Imgchnge(0)">
            <img src="@/assets/img/pic-g02.png" />
            <p>左图右文</p>
            <span class="select" v-if="com.mode === 0">
              <i class="el-icon-check"></i>
            </span>
          </div>
          <div class="ImgText fl" @click="Imgchnge(1)">
            <img src="@/assets/img/pic-g04.jpg" />

            <p>右图左文</p>
            <span class="select" v-if="com.mode === 1">
              <i class="el-icon-check"></i>
            </span>
          </div>
          <div class="ImgText fl" @click="Imgchnge(2)">
            <img src="@/assets/img/pic-g03.png" />
            <p>上下图文</p>
            <span class="select" v-if="com.mode === 2">
              <i class="el-icon-check"></i>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="配置">
          <el-row>
            <el-col :span="6">
              背景颜色<el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-col>
            <el-col :span="6">
              字体颜色<el-color-picker v-model="com.fontColor"></el-color-picker>
            </el-col>
            <el-col :span="12">
              透明度<el-input-number v-model="com.opcity" :min="0" controls-position="right" size="mini" :step="10"></el-input-number>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="添加图片">
          <el-button type="primary" @click="add()">添加图片</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
        <el-table-column width="80" label="图片">
          <template slot-scope="scope">
            <selectorImg v-model="scope.row.imgUrl"></selectorImg>
          </template>
        </el-table-column>
        <el-table-column width="120" label="标题">
          <template slot-scope="scope">
            <el-input v-model="scope.row.title"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="300" label="简介">
          <template slot-scope="scope">
            <el-input v-model="scope.row.introduction"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
            <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
            <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
          </template>
        </el-table-column>
      </el-table>
    	<jfExplain v-model="drawer" :name="com.cName || '图文'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '图文组件是一种列表展示组件，可以设置为多种样式的布局样式，需设置图文的图片地址、标题与简介。',
      fieldData:[
        {field: 'imgUrl',explain: '图片', type: 'string'},
        {field: 'title',explain: '标题', type: 'string'},
        {field: 'introduction',explain: '简介', type: 'string'}
      ]
    }
  },
  props: {
    com: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    Imgchnge (index) {
      this.com.mode = index
    },
    add () {
      this.com.data.push({ bgColor: '', fontColor: '', opcity: 100, imgUrl: '', title: '', introduction: '' })
    }
  }
}
</script>
