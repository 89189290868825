<template>
	<div>
		<el-descriptions title="数据库" :column="2" border>
		  <el-descriptions-item label="地址">
			  <div>{{sysConfig.host}}</div>
		  </el-descriptions-item>
		  <el-descriptions-item label="端口">
			  <div>{{sysConfig.port}}</div>
		  </el-descriptions-item>
		  <el-descriptions-item label="数据库">
			  <div>{{sysConfig.dbname}}</div>
		  </el-descriptions-item>
		  <el-descriptions-item label="用户名">
			  <div>{{sysConfig.username}}</div>
		  </el-descriptions-item>
		  <el-descriptions-item label="密码">
			  <div>{{sysConfig.password}}</div>
		  </el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="小程序" :column="1" border>
		  <el-descriptions-item label="当前状态">
			  <span style="margin-right: 20px;">{{xcxStatus}}</span>
			  <el-button type="primary" size="mini" @click="getAuthorizationQrCode">部署到自家小程序</el-button>
			  <!-- <el-link v-if="xcxStatus=='未授权'" type="primary" :href="sysConfig.link">授权</el-link> -->
		  </el-descriptions-item>
		</el-descriptions>
		<el-descriptions  v-if="xcxStatus!='未授权'" title="微信支付" :column="1" border>
		  <el-descriptions-item label="商户号(mchId)">
			  <input class="ipt" v-model="sysConfig.mchId"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="密钥V3">
			  <input class="ipt" v-model="sysConfig.apiV3Key"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="证书序列号(serial_no)">
			  <input class="ipt" v-model="sysConfig.serial_no"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="证书文件(.pem)">
			  <div style="display: inline-block;width: 200px;">{{sysConfig.mch_private_key?sysConfig.mch_private_key:'未上传'}}</div>
			  <el-button style="margin-left: 15px;" size="mini" @click="$refs.fileup.click()">上传</el-button>
			  <input ref="fileup" style="margin-left: 15px;display: none;" class="ipt" type="file" @change="fileChange"></input>
		  </el-descriptions-item>
		  <el-descriptions-item label="">
			  <el-button :disabled="!sysConfig.host" @click="saveConfig" type="primary" plain size="mini">保存</el-button>
		  </el-descriptions-item>
		</el-descriptions>
	</div>
</template>

<script>
	export default {
	  data () {
	    return {
			loading: null,
			sysConfig: {},
			xcxStatus: ''
		}
	  },
	  created: function () {
		this.getSysConfig()
		this.xcxStatus = this.$route.params.xcxStatus
	  },
	  methods: {
		getSysConfig(){
			const fun = { module: 'RemoteFile', action: 'GetConfig', prms: {sysId: this.$route.params.id}}
			this.common.request(fun, this.getSysConfigCb.bind(this))
		},
		getSysConfigCb(r){
			if(r.data[0]) this.sysConfig = r.data[0]
		},
		fileChange(e){
			let that = this
			try {
				var file = e.target.files[0]
				var reader = new FileReader();
				reader.onload = function () {
					that.sysConfig.mch_private_key = '就绪'
					that.sysConfig.mpk = this.result
				}
				reader.readAsText(file);
				e.srcElement.value = ""
				console.log(this.sysConfig)
			} catch (e) {
				alert('读取出错啦！' + e.toString())
			}
		},
		saveConfig(){
			this.sysConfig.sysId = this.$route.params.id
			const fun = { module: 'RemoteFile', action: 'SetConfig', prms: this.sysConfig }
			this.common.request(fun, this.saveCallback.bind(this))
		},
		getAuthorizationQrCode(){
			const fun = { module: 'RemoteFile', action: 'GetAuthorizationQrCode', prms: {sysId: this.$route.params.id}}
			this.common.request(fun, this.getAuthorizationQrCodeCb.bind(this))
		},
		getAuthorizationQrCodeCb(r){
			console.log(r)
			window.open(r.data[0].link, '_blank');
		}
	  }
	}
</script>

<style>
</style>