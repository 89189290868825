<template>
  <div>
    <el-button plain size="mini" type="warning" @click="search">刷新</el-button>
    <el-button plain size="mini" type="primary" @click="add">添加</el-button>
    <el-table :data="fields" highlight-current-row @current-change="currentChange">
      <el-table-column label="" width="30">
        <template slot-scope="scope">
          <i class="el-icon-key" v-if="scope.row.Key"></i>
        </template>
      </el-table-column>
      <el-table-column label="字段" width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.Field" :class="scope.row.onEdit?'inputEdit':''" :readonly="!scope.row.onEdit"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="100">
        <template slot-scope="scope">
          <el-autocomplete v-model="scope.row.Type" :class="scope.row.onEdit?'inputEdit':''" :fetch-suggestions="querySearch" :readonly="!scope.row.onEdit"></el-autocomplete>
        </template>
      </el-table-column>
      <el-table-column label="可空" width="60">
        <template slot-scope="scope">
           <el-switch v-model="scope.row.Null" active-value="YES" inactive-value="NO" :disabled="!scope.row.onEdit"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="默认值" width="50">
        <template slot-scope="scope">
          <el-input v-model="scope.row.Default" :class="scope.row.onEdit?'inputEdit':''" :readonly="!scope.row.onEdit"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="自增" width="120">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.Extra" v-if="scope.row.Type.substring(0,3) === 'int'" active-value="auto_increment" inactive-value=" " :disabled="!scope.row.onEdit"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.Comment" :class="scope.row.onEdit?'inputEdit':''" :readonly="!scope.row.onEdit"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-checkbox v-if="scope.row.onEdit" label="allDb" v-model="allDb"></el-checkbox> -->
          <el-link plain size="mini" type="primary" v-if="!scope.row.onEdit" @click="edit(scope.row)">编辑</el-link>
          <el-link plain size="mini" type="success" v-if="scope.row.onEdit" @click="search">取消</el-link>
          <el-link plain size="mini" type="success" v-if="scope.row.onEdit" @click="save(scope.row)">保存</el-link>
          <el-link plain size="mini" type="danger"  v-if="!scope.row.onEdit" @click="del(scope.row)">删除</el-link>
          <el-link plain size="mini" :type="scope.row.Key?'warning':'primary'"  v-if="!scope.row.onEdit" @click="setKey(scope.row)">主键</el-link>
          <el-link plain size="mini" icon="el-icon-top" type="warning"  v-if="!scope.row.onEdit" @click="setOrder(scope,'up')">上移</el-link>
		  <el-link plain size="mini" icon="el-icon-bottom" type="warning"  v-if="!scope.row.onEdit" @click="setOrder(scope,'down')">下移</el-link>
		  <!-- <el-link plain size="mini" type="primary" v-if="!scope.row.onEdit" @click="toAllDb(scope.row)">allDb</el-link> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      allDb: false,
      fields: [],
	  curr: null
    }
  },
  created: function () {
    this.search()
  },
  methods: {
    search () {
      const data = { module: 'RemoteDb', action: 'GetFields', prms: {sysId: this.$route.params.id, tableName: this.comData.Field } }
      this.common.request(data, this.setPageData.bind(this))
    },
    setPageData (data) {
      this.fields = []
      for (let i = 0; i < data.data.length; i++) {
        data.data[i].old = data.data[i].Field
        data.data[i].onEdit = false
        data.data[i].isNew = false
      }
      this.fields = data.data
    },
    add (row) {
      const data = { Field: '', Type: '', Null: 'YES', Key: '', Default: null, Extra: '', Comment: '', onEdit: true, isNew: true }
      this.fields.splice(1,0,data)
    },
    edit (row) {
      row.onEdit = true
    },
    cancel (scope, row) {
      if (row.isNew) {
        this.fields.splice(scope.$index, 1)
      }
      row.onEdit = false
    },
    save (row) {
      const data = { module: 'RemoteDb', action: 'UpdateFields', prms: { sysId: this.$route.params.id, allDb: this.allDb, tableName: this.comData.Field, row: row } }
      this.common.request(data, this.saveCallback.bind(this, row))
    },
    saveCallback (row, data) {
      if (data.code === 0) {
        row.old = row.Field
        row.onEdit = false
        row.isNew = false
      } else this.$message.error(data.msg)
    },
	currentChange(row){
		this.curr = row
	},
	setOrder(scope,dir)
	{
		let setOrder
		if(dir == 'up') setOrder = scope.$index == 0 ? 'after '+this.fields[this.fields.length-1].Field:scope.$index == 1 ? 'first' : 'after '+this.fields[scope.$index-2].Field
		else setOrder = scope.$index == this.fields.length-1 ? 'first' : 'after '+this.fields[scope.$index+1].Field
		const data = { module: 'RemoteDb', action: 'UpdateFields', prms: { sysId: this.$route.params.id, tableName: this.comData.Field,setOrder: setOrder,row: scope.row}}
		this.common.request(data, this.search.bind(this))
	},
    del (row) {
      this.$confirm('此操作将永久删除该字段和数据, 是否继续?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'RemoteDb', action: 'DropField', prms: { sysId: this.$route.params.id, tableName: this.comData.Field, field: row.Field } }
        this.common.request(data, this.delCallback.bind(this))
      })
    },
    delCallback (data) {
      if (data.code === 0) {
        this.search()
      } else this.$message.error(data.msg)
    },
    setKey (row) {
      const data = { module: 'RemoteDb', action: 'SetPrimaryKey', prms: { sysId: this.$route.params.id, tableName: this.comData.Field, row: row } }
      this.common.request(data, this.setKeyCallback.bind(this))
    },
    setKeyCallback (data) {
      if (data.code === 0) {
        this.search()
      } else this.$message.error(data.msg)
    },
    toAllDb (row) {
      const fun = { module: 'RemoteDb', action: 'UpdateFields', prms: { sysId: this.$route.params.id, allDb: true, tableName: this.comData.Field, row: row } }
      this.common.request(fun, this.allDbCb.bind(this))
    },
    allDbCb (r) {
      if (r.code !== 0) this.$message.error(r.msg)
    },
	querySearch(queryString, cb) {
		let restaurants = [{value:'int(11)'},{value:'varchar(255)'},{value:'date'},{value:'time'},{value:'datetime'},{value:'decimal(10,2)'},{value:'mediumtext'}]
		// let restaurants = [{value:'string'},{value:'phone'},{value:'email'},{value:'pwd'},{value:'int'},{value:'number'},{value:'time'},{value:'date'},{value:'datetime'},{value:'url'},{value:'idNumber'},{value:'ip'}]
		var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
		cb(results);
	},
	createFilter(queryString) { 
		return (restaurant) => {
			return (queryString != restaurant.value) && (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
		};
	}
  },
  props: {
    comData: { Field: '' }
  }
}
</script>

<style>
  .el-table td, .el-table th {
  padding: 0px;
  }
  .el-table .cell{
    padding: 0px;
  }
  .cell input{ border: 0; }
  .inputEdit{ border: 1px solid #6fb9d9; }
  .el-input__inner {padding: 0px;}
</style>
