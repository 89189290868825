<template>
  <div class="self">
    <el-form :inline="true" label-width="100px" class="demo-ruleForm" size="mini">
          <el-form-item label="上级">
            <el-cascader v-model="model.pId" :options="list" :show-all-levels="false" :clearable="true"
            :props="{value:'id',label:'name',checkStrictly:true}"
            @change="pChange"></el-cascader>
          </el-form-item>
		  <el-form-item label="名称">
            <el-input v-model="model.name" @blur="model.name = model.name.trim()"></el-input>
          </el-form-item>
          <el-form-item label="规则路径">
			<selectorPage v-model="model.path"></selectorPage>
          </el-form-item>
          <el-form-item label="客户端">
            <el-select v-model="model.rightType" placeholder="请选择">
              <el-option label="通用" value="1"></el-option>
              <el-option label="PC" value="2"></el-option>
              <el-option label="手机" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否菜单">
			  <el-radio-group v-model="model.isMenu" size="mini">
			    <el-radio-button :label="1">是</el-radio-button>
			    <el-radio-button :label="0">否</el-radio-button>
			  </el-radio-group>
          </el-form-item>
          <el-form-item label="顺序">
			  <el-input-number v-model="model.weight"></el-input-number>
          </el-form-item>
          <el-form-item label="图标">
			<selectorImg v-model="model.icon"></selectorImg>
          </el-form-item>
		</el-form>
        <el-button @click="save" type="warning" plain size="mini" icon="el-icon-folder-checked">保存</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
		list: [],
		model: {
			pId: '0',
			name:'',
			path:'',
			rightType:'1',
			isMenu:'1',
			weight:'2',
			icon:''
		}
    }
  },
  created: function () {
	  if(this.comData.right){
		this.model = Object.assign({},this.comData.right)
	  }
	  const fun = { module: 'RemoteDb', action: 'GetRights', prms: { sysId: this.$route.params.id } }
	  this.common.request(fun, this.getDataCb.bind(this))
  },
  methods: {
	  pChange(v){
	  	this.model.pId = v[v.length-1]
	  },
	  getDataCb (r) {
	  	if(r.code == 0){
	  		this.list = this.getTree(r.data,0)
	  	} else this.$message.error(r.msg)
	  },
	  getTree(arr,pId){
	  	let newArr = []
	  	for(let i = 0; i < arr.length; i++){
	  		if(arr[i].pId == pId){
	  			newArr.push(arr[i])
	  			let childs = this.getTree(arr,arr[i].id)
	  			if(childs.length>0) arr[i].children = childs
	  		}
	  	}
	  	return newArr
	  },
    save () {
		this.model.sysId = this.$route.params.id;
		const data = { module: 'RemoteDb', action: "SaveRight", prms: this.model }
		this.common.request(data, this.saveCb.bind(this))
    },
    saveCb (r) {
      if (r.code === 0) {
		  if(!this.model.id) this.model.id = r.data.id
        this.$message({ message: '保存成功', type: 'success',duration: 600 })
		this.$emit('labelChange','RT-'+this.model.name)
		this.comData.treeSet.search()
      } else this.$message.error(r.msg)
    },
    cancel () {
      this.$emit('cancel', '')
    }
  },
  props: {
    comData: Object
  }
}
</script>

<style>
  .self{
  	margin: 3px;
  	padding: 5px;
  	width: 660px;
  	border: 1px solid #CCCCCC;
  	box-sizing: border-box;
  }
  .el-form-item{
  	width: 300px;
  }
  .el-select{
    width: 100%;
  }
</style>
