<template>
    <div class='self' :style="[com.style]">
        <div class="header" v-if="contact && Object.keys(contact).length != 0">
			<div class="header-l">
				<div class="address">{{contact.street}}</div>
				<div class="street">{{contact.addr}}</div>
				<div class="user-info">{{contact.addrName}} {{contact.addrPhone}}</div>
			</div>
			<div class="header-r">
                <i class="el-icon-arrow-right"></i>
			</div>
		</div>
		<div class="header" v-else>
			<div class="header-l flex">
                <i class="el-icon-plus"></i>
				<div>请选择你的收货地址</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            contact: this.com.contact
        };
    },
    created() {},
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
    .self {
        background-color: #fff;
        padding: 0 20px;
    }
    .header {
		display: flex;
		width: 100%;
		min-height: 80px;
		font-size: 12px;
		margin: 0 0 10px 0;
		justify-content: space-between;
		align-items: center;
		border-bottom: 5px solid #eee;
	}
	.street {
		font-size: 16px;
		margin: 5px 0;
	}
</style>
