<template>
	<div id="userInfo" class="userInfo">
	  <div class="infoLeft">
		  <img :src="$route.params.url+com.data[0].header" :onerror="common.imgOnError"/>
	  </div>
	  <div class="infoRight">
		  <div class="userName">{{com.data[0].nickname}}</div>
	  </div>
	</div>
</template>

<script>
export default {
	  data () {
	    return {
      data: null
    }
	  },
	  created: function () {
	  },
	  methods: {
		  search () {
			  this.common.navigateTo({ pageName: 'ff' })
		  }
	  },
	  props: {
    pageObj: Object,
	    com: {
	      type: Object,
	      default () {
	        return {}
	      }
	    }
	  }
}
</script>

<style>
	.userInfo{
		width: 100%;
		border: 1px #C8C7CC solid;
		height: 70px;
		line-height:70px;
		display: flex;
	}

	.infoLeft{
		float:left;
		width:50px;
		height: 50px;
		border-radius: 50%;
		border: 3px solid #eee;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	.infoLeft img{
		width:50px;
		height: 50px;
	}
	.infoRight{
		float:right;
		height: 20px;
		width:100px;
	}
</style>
