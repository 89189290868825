<template>
  <div class="TheTitle" :style="[com.style,{backgroundColor:com.bgColor}]">
    <div class="TheTitle_box">
      <div class="i-title" v-if="com.leftState">
        <img :src="$route.params.url+com.imgUrl" class="titleImg fl" v-if="com.imgUrl!==''"/>
        <p
          class="fl-title"
          :style="{color:com.fontColor}"
        >{{com.leftTitle ||'标题'}}</p>
      </div>
      <p
        class="fc-title i-title"
        v-if="com.centerState"
        :style="{color:com.fontColor}"
      >{{com.centerTitle}}</p>
      <p class="fr-title i-title" v-if="com.rightState">
        <a href="javascript:void">
          <span class="titelColor" :style="{color:com.fontColor}">{{com.rightTitle ||'标题'}}</span>
        </a>
      </p>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>
<style scoped>
  .fl-title{text-align: left;}
	.fr-title{text-align: right;}
  a {text-decoration: none;}
	.fc-title {
		padding: 20rpx;
		border-bottom: 5rpx solid #eee;
		text-align: center;
		color: #555;
		font-size: 36rpx;
		font-weight: 600;
	}
	.TheTitle {
	  width: 100%;
	  padding: 11px 10px 11px 12px;
	  box-sizing: border-box;
    background-color: #fff;
	}
	.TheTitle .TheTitle_box {
	  /* padding: 0 10px; */
	  display: flex;
	  align-items: initial;
	}
  .i-title {
    flex: 1;
  }
	.TheTitle .TheTitle_box .titleImg {
	  width: 18px;
	  height: 18px;
	  margin: 1px 7px 0 -3px;
	  float: left;
	}
	.TheTitle .TheTitle_box p {
	  font-size: 14px;
	  color: #333333;
	}
	.TheTitle .TheTitle_box .titelColor {
	  font-size: 12px;
	  letter-spacing: 0;
	  color: #666;
	}
  p {
    margin: 0;
  }
</style>
