<template>
    <div class='jf-calendar' :style="[com.style]">
        <div class="wrap" :style="{backgroundColor:com.bgColor}">
            <div class="title" :style="{color:com.titleFontColor}">{{ com.title }}</div>
            <div class="calendar">
                <el-calendar v-model="com.data[0].date"></el-calendar>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {
		getBinding(){
		  return [{label: '日期',field:'date'}]
		}
    },
    props:{com:Object}
};
</script>

<style scoped>
    .jf-calendar {
        padding: 10px;
        box-sizing: border-box;
    }
    .wrap {
        padding: 10px;
        border-radius: 10px;
    }
    .wrap .title {
        padding: 5px 0;
        font-weight: 600;
    }
    .wrap .calendar {
        padding: 5px 0;
    }
</style>
