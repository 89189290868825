<template>
    <div class="imgTemplate">
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
      </el-form>
      <label class="prompt">图片模板样式</label>
      <div class="imgBox clear">
        <div class="ImgText fl" @click="setStyle(0);">
          <img src="@/assets/img/picSet0.png" :onerror="common.imgOnError"/>
          <p>单列</p>
          <span class="select" v-if="com.groupStyle === 0">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(1);">
          <img src="@/assets/img/picSet1.png" :onerror="common.imgOnError"/>
          <p>双列</p>
          <span class="select" v-if="com.groupStyle === 1">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(2);">
          <img src="@/assets/img/picSet2.png" :onerror="common.imgOnError"/>
          <p>三列</p>
           <span class="select" v-if="com.groupStyle === 2">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(3);">
          <img src="@/assets/img/picSet3.jpg" :onerror="common.imgOnError"/>
          <p>四列</p>
           <span class="select" v-if="com.groupStyle === 3">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(4);">
          <img src="@/assets/img/picSet4.jpg" :onerror="common.imgOnError"/>
          <p>五列</p>
           <span class="select" v-if="com.groupStyle === 4">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(5);">
          <img src="@/assets/img/picSet5.png" :onerror="common.imgOnError"/>
          <p>左一右二</p>
           <span class="select" v-if="com.groupStyle === 5">
            <i class="el-icon-check"></i>
          </span>
        </div>
        <div class="ImgText fl" @click="setStyle(6);">
          <img src="@/assets/img/picSet6.png" :onerror="common.imgOnError"/>
          <p>左四右一</p>
           <span class="select" v-if="com.groupStyle === 6">
            <i class="el-icon-check"></i>
          </span>
        </div>
      </div>
      </br>
      <el-button class="btn" plain size="mini" @click="add" type="primary">添加图片</el-button>
      <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
        <el-table-column width="100" label="图片">
          <template slot-scope="scope">
            <selectorImg v-model="scope.row.imgUrl"></selectorImg>
          </template>
        </el-table-column>
        <el-table-column width="160" label="链接">
          <template slot-scope="scope">
            <selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
            <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
            <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
          </template>
        </el-table-column>
      </el-table>
    	<jfExplain v-model="drawer" :name="com.cName || '图片集'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
  data () {
    return {
      indexActive: 0,
      isShow: false,
      current: null,
      drawer: false,
      desc: '图片集是一个图片展示组件，通过配置可以完成多种样式的展示，需要设置图片地址及跳转地址。',
      fieldData:[
        {field: 'imgUrl',explain: '图片', type: 'string'},
        {field: 'pageName',explain: '调转路径', type: 'number'}
      ]
    }
  },
  methods: {
    setStyle (index) {
      this.com.groupStyle = index
    },
    add (type) {
      this.com.data.push({ imgUrl: '', pageName: '' })
    }
  },
  props: {
    com: Object,
    comData: Object
  }
}
</script>

<style scoped>
.imgTemplate {
  .prompt {
    font-size: 12px;
    color: #495060;
  }
  .select {
    height: 22px;
    width: 22px;
    position: absolute;
    top: 39px;
    right: 1px;
    background: #57a3f3;
    border-radius: 100% 0 0 0;
    opacity: 1;
    i {
      color: #fff;
      margin-left: 1px;
      font-size: 20px;
    }
  }
  .select {
    border: 2px solid #57a3f3;
  }
  .imgBox {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top;
    .ImgText {
      position: relative;
      width: 83px;
      margin: 0 10px 5px 0;

      p {
        text-align: center;
        color: #999;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
}
</style>
