<template>
	<div>
		<el-row class="row">
		  <el-col :span="4"><div class="grid-content bg-purple">尺寸</div></el-col>
		  <el-button type="primary" plain size="medium" @click="com.cascaderSize = 'mini'">小</el-button>
		  <el-button type="primary" plain size="medium" @click="com.cascaderSize = 'small'">中</el-button>
		  <el-button type="primary" plain size="medium" @click="com.cascaderSize = 'medium'">大</el-button>
		</el-row>
		<el-row class="row">
		  <el-col :span="4"><div class="grid-content bg-purple">提示文本</div></el-col>
		  <el-input v-module="com.placeholder"></el-input>
		</el-row>
		<el-row>
			<button @click="com.data[0].name='BBB-'">{{com.cascaderSize}}</button>
		</el-row>
	</div>
</template>

<script>
export default {
	props: {
		pageObj: Object,
		com: Object
	}
}
</script>

<style scoped>
	.row {
		display: flex;
		align-items: center;
		padding: 10px 0;
	}
</style>