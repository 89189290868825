<template>
    <div class='jf-role-editor'>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="com.title" size="mini"></el-input>
        </el-form-item>
      </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '切换角色'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
          drawer: false,
          desc: '切换角色组件已联调pc端角色切换接口，登录后可直接使用。',
          fieldData:[
            {field: 'name',explain: '角色名称', type: 'string'},
            {field: 'id',explain: '角色id', type: 'number'}
          ]
        }
    },
    created() {},
    methods: {},
    props:{
        com: Object
    }
};
</script>

<style scoped>

</style>
