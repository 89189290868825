<template>
    <div class='commission' :style="[com.style]">
        <div class="wrap" :style="{ backgroundColor: com.bgColor }">
            <div class="title">{{ com.title }}</div>
            <div class="grid">
                <div class="grid-item" v-for="(item,index) in com.data" :key="index" :style="{width:column+'%'}">
                    <div class="grid-box">
                        <div>{{ item.count }}</div>
                        <div>{{ item.msg }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            column: 100 / this.com.column
        };
    },
    created() {},
    methods: {},
    props:{
        com: Object
    }
};
</script>

<style scoped>
    .commission {
        padding: 10px;
    }
    .wrap {
        padding: 10px;
        border-radius: 10px;
        background: #fff;
    }
    .title {
        border-left: 4px solid #2979ff;
        padding: 0 10px;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
    }
    .grid-item {
        margin: 10px 0;
    }
    .grid-box {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ccc;
        text-align: center;
        margin: 0 auto;
    }
</style>
