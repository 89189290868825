<template>
	<div class="content" :style="[com.style]">
		<div class="wrap">
			<div class="list flex" v-for="(item, index) in com.data" :key="index">
				<div class="">
					<div class="wrapper">
						<div class="address-box">
							<div class="address gray">{{item.street}}</div>
							<div class="detail">{{item.addr}}</div>
						</div>
						<div class="u-box">
							<div class="name">{{item.addrName}}</div>
							<div class="mobile gray">{{item.addrPhone}}</div>
							<div class="">
                                <el-switch v-model="item.check" active-text="默认"></el-switch>
							</div>
						</div>
					</div>
				</div>
				<div class="">
					<div class="del">删除</div>
                    <i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>
		<div class="footer flex">
			<button class="add-btn" @tap="editor()">新增地址</button>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    components:{},
    props: {
        com: Object
    }
};
</script>

<style scoped>
    .h100 {
		height: 100%;
	}
	.flex {
		display: flex;
		align-items: center;
	}
	.content {
		font-size: 16px;
		position: relative;
		height: 100%;
		padding: 10px;
		background-color: #f9f9f9;
		.wrap{
			background-color: #fff;
			border-radius: 10px;
		}
		.list {
			justify-content: space-between;
			padding: 10px;
			position: relative;
			.detail {
				font-size: 16px;
				margin: 5px 0;
			}
		}
		.gray {
			color: gray;
		}
		.del {
            position: absolute;
            right: 5px;
            bottom: 5px;
            padding: 5px;
            border-radius: 14px;
		}
	}
	.footer {
		position: absolute;
		bottom: 200px;
		left: 0;
		height: 35px;
		width: 100%;
		justify-content: center;
	}
	.add-btn {
		width: 80%;
		background-color: #ff4243;
		color: #fff;
		border-radius: 30px;
        padding: 10px 0;
	}
	.mobile {
		margin: 0 10px 0 5px;
	}
	.u-box {
		display: flex;
		align-items: center;
	}
</style>
