<template>
  <div class="Divider" :class="'Divider_List'+com.type" :style="[com.style,{borderTop:com.type == 2 ? com.style.height+ ' solid '+ com.color :  com.style.height+ ' dashed '+ com.color}]"></div>
</template>



<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>
<style scoped>
  .Divider {
    height: 100%;
	  display: flex;
	  align-items: initial;
	  justify-content: flex-start;
	  flex-direction: column;
	}
 .Divider_List1 {
    height: 100%;
	}
 .Divider_List2 {
    height: 100%;
	}
</style>
