<template>
  <div>
    <el-table v-if="comData.table.Comment" :data="tabs" stripe style="width: 100%" row-class-name="rowH">
      <el-table-column width="80" label="生成接口">
        <template slot-scope="scope">
            <el-checkbox v-model="scope.row.generate"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="80" label="生成页面">
        <template slot-scope="scope">
            <el-checkbox v-if="scope.row.action == 'add'" v-model="scope.row.page" label="新建页"></el-checkbox>
            <el-checkbox v-if="scope.row.action == 'update'" v-model="scope.row.page" label="编辑页"></el-checkbox>
            <el-checkbox v-if="scope.row.action == 'getById'" v-model="scope.row.page" label="详情页"></el-checkbox>
            <el-checkbox v-if="scope.row.action == 'getList'" v-model="scope.row.page" label="列表页"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作" width="80"></el-table-column>
      <el-table-column width="80" label="Token">
        <template slot-scope="scope">
          <el-checkbox :checked="true" @change="selectAll(scope.row, $event)" v-if="scope.row.generate && scope.row.action !== 'delete'">全选</el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Token">
        <template slot-scope="scope">
          <el-checkbox-group v-if="scope.row.generate && scope.row.action !== 'delete'" v-model="scope.row.fields">
            <el-checkbox class="w25" v-for="(sub, index) in fields" :key="index" :label="sub.Field"></el-checkbox>
          </el-checkbox-group>
        </template>
      </el-table-column>
    </el-table>
	<div v-else class="noComment">该表没有备注信息</div>
    <el-button v-if="comData.table.Comment" plain size="mini" type="primary" @click="generate">生成</el-button>
  </div>
</template>
<script>
export default {
  data () {
    return {
		loading:null,
      fields: [],
      active: 'add',
      tabs: [
        { action: 'add', generate: true, page: true, fields: [], ui: '' },
        { action: 'delete', generate: true, page: true, fields: [], ui: '' },
        { action: 'update', generate: true, page: true, fields: [], ui: '' },
        { action: 'getById', generate: true, page: true, fields: [], ui: '' },
        { action: 'getList', generate: true, page: true, fields: [], ui: '' }
      ]
    }
  },
  created: function () {
	  if(!this.comData.table.Comment == '') this.getFields()
  },
  methods: {
    getFields () {
      const data = { module: 'RemoteDb', action: 'GetFields', prms: { sysId: this.$route.params.id, tableName: this.comData.table.Name } }
      this.common.request(data, this.callback.bind(this))
    },
    callback (r) {
      this.fields = r.data
      for (let i = 0; i < this.tabs.length; i++) {
        this.selectAll(this.tabs[i], true)
      }
    },
    selectAll (row, sel) {
      if (sel) {
        for (let j = 0; j < this.fields.length; j++) {
			if(row.action == 'add' && ['id','utime','uuser','status'].includes(this.fields[j].Field)) continue
			if(row.action == 'update' && ['id','ctime','cuser','depPath'].includes(this.fields[j].Field)) continue
			if(row.action == 'getById' && ['depPath'].includes(this.fields[j].Field)) continue
			if(row.action == 'getList' && ['ctime','cuser','utime','uuser','memo','depPath'].includes(this.fields[j].Field)) continue
			row.fields.push(this.fields[j].Field)
        }
      } else row.fields = []
    },
    generate () {
		this.loading = this.$loading({ lock: true, text: '正在生成', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i]
        if (tab.action == 'add') tab.ui = this.getUiEdit(true)
        if (tab.action == 'update') tab.ui = this.getUiEdit(false)
        if (tab.action == 'getById') tab.ui = this.getUiDetail()
        if (tab.action == 'getList') tab.ui = this.getUiList()
      }
      const data = {
        module: 'FunGenerator',
        action: 'Generate',
        prms: {
          sysId: this.$route.params.id,
          tableName: this.comData.table.Name,
          actions: this.tabs,
          Comment: this.comData.table.Comment,
		  fields: this.fields
        }
      }
      this.common.request(data, this.generateCallback.bind(this))
    },
    generateCallback (r) {
		this.loading.close()
      if (r.code === 0) this.$message({ message: '生成成功！', type: 'success' })
      else this.$message.error(r.msg)
    },
    getUiEdit (isNew) {
      const ui = {
        coms: [
          {
            style: { position: 'relative', left: '0', right: '0' },
			labelWidth: 120,
			ctrlWidth: 220,
			colCount: 2,
			labelPosition: "left",
            dataIndex: 0,
            data: [],
            link: '',
            name: 'jfForm',
			fun: isNew ? null :{ prms: { id: '' }, module: this.comData.table.Name, action: 'getById' },
            coms: [],
			buttons:[
				{ label: '返回', type: 'warning',rightId: '0', actionType: 'back', fun: null, link: '',fields:[] },
				{ label: '保存', type: 'primary',rightId: '0', actionType: 'fun', fun: {prms: {},module: this.comData.table.Name,action: isNew ?'add':'update'}, link: '',fields:[],after: 'close'}
			]
          }
        ]
      }
      const index = isNew ? 0 : 2
      const checkFields = this.tabs[index].fields
      for (let i = 0; i < checkFields.length; i++) {
		  if(['cuser','ctime','uuser','utime','depPath'].includes(checkFields[i])) continue
        const field = this.fields.find(item => item.Field == checkFields[i])
        ui.coms[0].buttons[1].fun.prms[field.Field] = ''
		let com = { type: 'input', options: 1, field: field.Field, label: field.Comment, rows: '1' }
		if(checkFields[i] == 'status') {
			com.type = 'radio'
			com.options = [{value: '正常',text: '正常'},{value: '禁用',text: '禁用'}]
		}
        ui.coms[0].coms.push(com)
      }
      return escape(JSON.stringify(ui))
    },
    getUiDetail () {
      const ui = {
        fun: null,
        coms: [
          {
            style: { position: 'relative', left: '0', right: '0' },
			labelWidth: 120,
			ctrlWidth: 220,
			colCount: 2,
			labelPosition: "left",
            fun: { prms: { id: '' }, module: this.comData.table.Name, action: 'getById' },
			dataIndex: 0,
            data: [],
            type: 'complex',
            link: '',
            name: 'jfForm',
            coms: [],
			buttons:[
				{ label: '返回', type: 'warning',rightId: '0', actionType: 'back', fun: null, link: '',fields:[] },
			]
          }
        ]
      }
      const checkFields = this.tabs[3].fields
      for (let i = 0; i < checkFields.length; i++) {
        const field = this.fields.find(item => item.Field == checkFields[i])
        ui.coms[0].coms.push({ type: 'label', field: field.Field, label: field.Comment })
      }
      return escape(JSON.stringify(ui))
    },
    getUiList () {
      const ui = {
        fun: null,
        coms: [
          {
            style: { position: 'relative', left: '0', right: '0' },
            fun: { prms: { page: 0, limit: 10 }, module: this.comData.table.Name, action: 'getList' },
            data: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }],
            type: 'complex',
            link: this.comData.table.Comment + '-详情',
            name: 'jfList',
            pageDataIndex: 1,
            listDataIndex: 0,
            paging: true,
            searchComs: [],
            buttons: [
              {
                label: '刷新',
                type: 'warning',
                actionType: 'refresh',
                fun: null,
                link: '',
              	rightId: '0'
              },
			  {
                label: '添加',
                type: 'primary',
                actionType: 'dialog',
                fun: null,
                link: this.comData.table.Comment + '-新建',
				rightId: '0',
				after: 'refresh'
              },
              {
                label: '删除',
                type: 'danger',
                actionType: 'fun',
                fun: { prms: { id: '' }, module: this.comData.table.Name, action: 'delete' },
                link: '',
                funTip: '确定要删除吗？',
				rightId: '0',
				after: 'refresh'
              }
            ],
            rowButtons: [
              {
                label: '编辑',
                type: 'primary',
                actionType: 'dialog',
                fun: null,
                link: this.comData.table.Comment + '-编辑',
				rightId: '0',
				after: 'refresh'
              },
              {
                label: '删除',
                type: 'danger',
                actionType: 'fun',
                fun: { prms: { id: '' }, module: this.comData.table.Name, action: 'delete' },
                link: '',
				rightId: '0',
                funTip: '确定要删除吗？'
              }],
            cols: []
          }
        ]
      }
      const checkFields = this.tabs[4].fields
      for (let i = 0; i < checkFields.length; i++) {
		  if(checkFields[i] == 'id') continue
        const field = this.fields.find(item => item.Field == checkFields[i])
        ui.coms[0].cols.push({ type: 'text', field: field.Field, label: field.Comment, style: {} })
      }
      return escape(JSON.stringify(ui))
    }
  },
  props: {
    comData: Object
  }
}
</script>
<style>
  .w25{width: 120px;margin-top: 10px;}
  .btn{margin-left: 1px;padding: 3px 3px;}
  .rowH{height: 70px;}
  .noComment{color: red;font-size: 20px;}
</style>
