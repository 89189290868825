<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
            <el-form-item label="组件说明">
            <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
            </el-form-item>
            <el-form-item label="背景颜色">
		        <el-color-picker v-model="com.bgColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="com.title"></el-input>
            </el-form-item>
            <el-form-item label="标题颜色">
                <el-color-picker v-model="com.titleFontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label="移动端配置">
                <div>
                    <span>显示方式：</span>
                    <el-radio v-model="com.insert" :label="true">插入</el-radio>
                    <el-radio v-model="com.insert" :label="false">弹窗</el-radio>
                </div>
                <div>
                    <span>日期范围：</span>
                    <el-switch v-model="com.range"></el-switch>
                    <span>显示农历：</span>
                    <el-switch v-model="com.lunar"></el-switch>
                    <span>显示月份背景：</span>
                    <el-switch v-model="com.showMonth"></el-switch>
                </div>
                <div>
                    <span>选择可选范围：</span>
                    <el-date-picker v-model="com.startDate" value-format="yyyy-MM-dd" align="right" type="date" placeholder="可选开始日期" :picker-options="pickerOptions"></el-date-picker>
                    <el-date-picker v-model="com.endDate" value-format="yyyy-MM-dd" align="right" type="date" placeholder="可选结束日期" :picker-options="pickerOptions"></el-date-picker>
                </div>
            </el-form-item>
        </el-form>
        <jfExplain v-model="drawer" :name="com.cName || '日历'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            desc: '日历组件用于日期的展示与选择，移动端有插入与弹窗两种模式，默认日期为今天，格式yyyy-mm-dd，也可配置多种显示模式和选择范围。',
            fieldData:[
                {field: 'date',explain: '日期', type: 'string'}
            ],
            pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                    }, 
                    {
                        text: '昨天',
                        onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                    }, 
                    {
                        text: '一周前',
                        onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        };
    },
    created() {
        this.com.startDate = this.common.getTime() || ''
    },
    methods: {},
    props:{com:Object}
};
</script>

<style scoped>

</style>
