<template>
  <div class="search_box" :style="[com.style]">
    <div
      class="searchList"
      :style="{ backgroundColor: com.outsideBgColor }"
    >
      <div class="searchHot" :style="{ backgroundColor: com.insideBgColor }">
        <i style="color: #999;" class="el-icon-search"></i>
        <span class="searchLabel" :style="{ color: com.fontColor }">{{
          com.placeholder || "搜索"
        }}</span>
        <i class="el-icon-full-screen scanning"></i>
        <i class="el-icon-camera camera"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  created: function() {},
  props: {
    pageObj: Object,
    com: Object,
  },
};
</script>
<style scoped>
  .search_box {
    height: 40px;
  }
  .search_box .searchList {
    display: flex;
    position: relative;
    align-items: center;
    background-color: #F1F1F1;
    height: 100%;
  }
  .searchHot {
    width: 97%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 18px;
    display: flex;
    align-items: center;
  }
  .searchLabel{
    max-width:250px;
    height:15px;
    font-size:16px;
    /* justify-content: flex-start; */
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:500;
    color:#666666;
    line-height:15px;
    position: absolute;
    top: 8px;
    left: 36px;

  }
  .search-icon {
    position: absolute;
    left: 12px;
    top: 0;
    height: 100%;
  }
  .scanning {
    position: absolute;
    right: 45px;
    top: 5px;
    color: #999;
  }
  .camera {
    position: absolute;
    right: 12px;
    top: 5px;
    height: 100%;
    color: #999;
  }
  .search_box .iconLeft{
    margin-right: 5px;
  }
  .img {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -33px;
  margin-top: -27px;
  }
</style>
