<template>
  <div class="jf-im" :style="[com.style]">
    <div class="box">
        <div class="header">
            <div class="h-left"> {{ '<' }} </div>
            <div class="h-center">李四</div>
            <div class="h-center">···</div>
        </div>
        <div class="col" v-for="(item,index) in com.data" :key="index">
            <img :class="item.type == 'form' ? 'l-img' : 'r-img'" :src="item.avatar" alt="">
            <div :class="item.type == 'form' ? 'left' : 'right'">{{ item.content }}</div>
            <div style="clear: both;"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  props: { com: Object },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  overflow: hidden;
}
.header {
    height: 50px;
    display: flex;
    background: #f8f8f8;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.col {
    padding: 10px;
}
.l-img,.r-img {
    width: 45px;
    height: 45px;
    border-radius: 3px;
}
.l-img {
    float: left;
}
.r-img {
    float: right;
}

.left,
.right {
    text-align: center;
    min-width: 50px;
    padding: 10px 5px;
    max-width: 212px;
    border-radius: 5px;
    background-color: #95ec69;
    position: relative;
    min-height: 40px;
    font-size: 16px;
    box-sizing: border-box;
}

.left {
    float: left;
    margin-left: 10px;
    padding-left: 5px;
    background-color: #fff;
    clear: right;
}

.right {
  float: right;
  margin-right: 10px;
  padding-left: 5px;
}

.left::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  /* 边框宽度为5px 颜色透明(也就是隐藏) */
  border: 5px solid transparent;
  /* 箭头向左 则右边框显示 */
  border-right-color: #fff;
  /* 在div左边展示 偏移量为 边框宽度*2 即5*2px */
  left: -10px;
  /*垂直居中计算*/
  /*如果有高度 则(父元素高度 - 子元素高度 )/2 */
  /*如果是边框 则(父元素高度 - 边框宽度*2 )/2 */
  /* (40-5*2)/2=15 */
  top: 15px;
}

.right::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  /* 边框宽度为5px 颜色透明(也就是隐藏) */
  border: 5px solid transparent;
  /* 箭头向右 则左边框显示 */
  border-left-color: #95ec69;
  /* 在div右边展示 偏移量为 边框宽度*2 即5*2px */
  right: -10px;
  /*垂直居中计算*/
  /*如果有高度 则(父元素高度 - 子元素高度 )/2 */
  /*如果是边框 则(父元素高度 - 边框宽度*2 )/2 */
  /* (40-5*2)/2=15 */
  top: 15px;
}
</style>
