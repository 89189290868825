<template>
  <div class="recruit_box" :style="[com.style]">
    <div
      :class="'recruitList'+com.mode"
    >
      <!-- <img :src="'https://www.bjapp11.com/svr/upload/lv/3.jpg'"/> -->
      <img :src="$route.params.url+com.pic" :onerror="common.imgOnError" />
      <div class="recruit_title">
        <span>{{com.text||'商城'}}</span>
      </div>
      <div class="recruit_logo">
        <!-- <img :src="'https://www.bjapp11.com/svr/upload/lv/3.jpg'"/> -->
        <img :src="$route.params.url+com.logo" :onerror="common.imgOnError" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      serverUrl: this.common.serverUrl,
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>

<style>
	.recruit_box {
	  position: relative;
	}
	.recruit_box .recruitList1 {
	  width: 100%;
	  height: 90px;
	}
	.recruit_box .recruitList1 img {
	  height: 100%;
	  width: 100%;
	}
	.recruit_box .recruitList1 .recruit_title {
	  position: absolute;
	  left: 74px;
	  bottom: 36px;
	  font-size: 18px;
	  color: #fff;
	  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	}
	.recruit_box .recruitList1 .recruit_logo {
	  position: absolute;
	  left: 12px;
	  bottom: 21px;
	  width: 48px;
	  height: 48px;
	  padding: 1px;
	  border: 1px solid #ececec;
	  border-radius: 2px;
	}
	.recruit_box .recruitList2 {
	  height: 120px;
	}
	.recruit_box .recruitList2 img {
	  height: 100%;
	  width: 100%;
	}
	.recruit_box .recruitList2 .recruit_title {
	  position: absolute;
	  left: 0;
	  top: 84px;
	  width: 100%;
	  font-size: 16px;
	  color: #fff;
	  text-align: center;
	}
	.recruit_box .recruitList2 .recruit_logo {
	  position: absolute;
	  left: 50%;
	  top: 24px;
	  margin-left: -26px;
	  height: 52px;
	  width: 52px;
	  border-radius: 50%;
	  border: 1px solid #ececec;
	  padding: 1px;
	  overflow: hidden;
	}
	.recruit_box .recruitList2 .recruit_logo img {
	  height: 100%;
	  width: 100%;
	}
</style>