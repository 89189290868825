<template>
    <div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
	  	</el-form>
		<div>不用设置</div>
    	<jfExplain v-model="drawer" :name="com.cName || '短视频'" :desc="desc" :fieldData="fieldData"></jfExplain>
	</div>
</template>

<script>
export default {
    data() {
        return {
			drawer: false,
			desc: '短视频是一个仿抖音的视频滑动播放的基础功能组件，可展示视频的展示、点赞、收藏、评论……',
			fieldData:[
				{field: 'src',explain: '视频地址', type: 'string'},
				{field: 'name',explain: '视频作者名称', type: 'string'},
				{field: 'title',explain: '视频标题', type: 'string'},
				{field: 'head',explain: '视频作者头像', type: 'string'},
				{field: 'praise',explain: '视频点赞数量', type: 'number'},
				{field: 'praiseStatus',explain: '是否已点赞', type: 'boolean'},
				{field: 'comment',explain: '视频评论数量', type: 'number'},
				{field: 'collect',explain: '视频收藏数量', type: 'number'},
				{field: 'collectStatus',explain: '是否已收藏', type: 'boolean'},
				{field: 'share',explain: '视频分享数量', type: 'number'}
			]
        };
    },
    props: {
        com:Object
    },
    methods: {},
    components:{}
};
</script>

<style scoped>

</style>
