export default class{
  constructor(){
	  this.coms = []
	  this.selection = []
  }
  init (coms) {
    this.coms = coms
    this.selection = []
  }
  isSelect (coms, index) {
    const slt = coms === this.coms && this.selection.includes(index)
    return slt
  }
  selected (com) {
    for (let i = 0; i < this.selection.length; i++) {
      if (com === this.coms[this.selection[i]]) return true
    }
    return false
  }
  select (coms, index) {
    if (coms === this.coms) {
      if (!this.selection.includes(index)) this.selection.push(index)
    } else {
      this.coms = coms
      this.selection = []
      this.selection.push(index)
    }
  }
  deselect (coms, index) {
    if (coms === this.coms) {
      const i = this.selection.indexOf(index)
      if (i !== -1) this.selection.splice(i, 1)
    } else {
      this.coms = coms
      this.selection = []
    }
  }
  toggle (coms, index) {
    if (coms === this.coms) {
      const i = this.selection.indexOf(index)
      if (i === -1) this.selection.push(index)
      else this.selection.splice(i, 1)
    } else {
      this.coms = coms
      this.selection = []
      this.selection.push(index)
    }
  }
  move (coms, direction, distance) {
    if (coms !== this.coms) return false
    for (let i = 0; i < this.selection.length; i++) {
      const index = this.selection[i]
      const old = this.coms[index].style[direction].toInt()
      this.coms[index].style[direction] = old + distance + 'px'
    }
  }
  alignLeft () {
    if (this.selection.length === 0) return
    let value = 10000
    for (let i = 0; i < this.selection.length; i++) {
      const v = this.coms[this.selection[i]].style.left.toInt()
      if (value > v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.coms[this.selection[i]].style.left = value + 'px'
    }
  }
  alignTop () {
    if (this.selection.length === 0) return
    let value = 10000
    for (let i = 0; i < this.selection.length; i++) {
      const v = this.coms[this.selection[i]].style.top.toInt()
      if (value > v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.coms[this.selection[i]].style.top = value + 'px'
    }
  }
  alignRight () {
    let value = -10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.left.toInt() + com.style.width.toInt()
      if (value < v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      const width = this.coms[this.selection[i]].style.width.toInt()
      this.coms[this.selection[i]].style.left = value - width + 'px'
    }
  }
  alignBottom () {
    let value = -10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.top.toInt() + com.style.height.toInt()
      if (value < v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      const height = this.coms[this.selection[i]].style.height.toInt()
      this.coms[this.selection[i]].style.top = value - height + 'px'
    }
  }
  alignMiddle () {
    let value = 10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.top.toInt() + com.style.height.toInt() / 2
      if (value > v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      const height = this.coms[this.selection[i]].style.height.toInt()
      this.coms[this.selection[i]].style.top = value - height / 2 + 'px'
    }
  }
  alignCenter () {
    let value = 10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.left.toInt() + com.style.width.toInt() / 2
      if (value > v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      const width = this.coms[this.selection[i]].style.width.toInt()
      this.coms[this.selection[i]].style.left = value - width / 2 + 'px'
    }
  }
  sameWidth () {
    let value = -10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.width.toInt()
      if (value < v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.coms[this.selection[i]].style.width = value + 'px'
    }
  }
  sameHeight () {
    let value = -10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const v = com.style.height.toInt()
      if (value < v) value = v
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.coms[this.selection[i]].style.height = value + 'px'
    }
  }
  sameSize () {
    let w = -10000
    let h = -10000
    for (let i = 0; i < this.selection.length; i++) {
      const com = this.coms[this.selection[i]]
      const height = com.style.height.toInt()
      const width = com.style.width.toInt()
      if (w < width) w = width
      if (h < height) h = height
    }
    for (let i = 0; i < this.selection.length; i++) {
      this.coms[this.selection[i]].style.width = w + 'px'
      this.coms[this.selection[i]].style.height = h + 'px'
    }
  }
  del () {
    this.selection.sort()
    this.selection.reverse()
    for (let i = 0; i < this.selection.length; i++) {
      this.coms.splice(this.selection[i], 1)
    }
    this.selection = []
  }
}
