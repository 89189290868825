<template>
    <div class="coupon" :style="[com.style]">
        <div class="content">
            <template v-if="list.length > 0">
                <div class="card" v-for="(item,index) in list" :key="item.id">
                    <div class="card-left">
                        <div class="">{{item.type}}</div>
                    </div>
                    <div class="card-right">
                        <div class="card-info">{{item.des}}</div>
                        <strong class="card-time" v-if="item.type == '折扣券'">{{item.value * 10}}折</strong>
                        <strong class="card-time" v-if="item.type == '满减券'">满{{item.term}}减{{item.value}}</strong>
                        <strong class="card-time" v-else>面值：￥{{item.value}}</strong>
                        <strong class="card-time">有效值至 {{item.endTime}}</strong>
                        <div v-if="Boolean(item.ucId) == false" class="footer-btn footer">立即领取</div>
                        <checkbox v-else class="footer round" :value="index" :checked="item.check" @click.stop="handlerCheck(index)"/>
                    </div>
                </div>
            </template>
            <div class="empty" v-else>
                <div class="">暂无优惠券</div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            list: []
        };
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.list = this.com.data[0];
            this.list.forEach(item => {
                if(item.type == '折扣券') {
                    item.value = item.value / 100;
                }
                this.$set(item, 'check', false);
            });
        }
    },
    props:{com:Object}
};
</script>

<style scoped>
    .content {
        font-size: 14px;
        background-color: #fff;
		padding: 10px;
		overflow: auto;
		position: relative;
	}
	.card{
	  display: flex;
	  align-items: center;
	  width: 100%;
	  padding: 10px 0;
	  background: #fff;
	  border-radius: 8px;
	  margin: 0 0 5px;
	  -webkit-mask-image: radial-gradient(circle at 88px 4px, transparent 4px, red 4.5px),radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
	  -webkit-mask-size: 100%, 2px 4px;
	  -webkit-mask-repeat: repeat, repeat-y;
	  -webkit-mask-position: 0 -4px, 87px;
	  -webkit-mask-composite: source-out;
	  mask-composite: subtract;
	  background: linear-gradient(45deg, orange, red);
	}
	.card-left{
	  width: 88px;
	  text-align: center;
	  font-size: 22px;
	  color: #fff;
	}
	.card-right{
	  padding: 16px 12px;
	  display: flex;
	  flex: 1;
	  flex-direction: column;
	  position: relative;
	}
	.card-info{
	  margin: 0;
	  font-size: 16px;
	  line-height: 20px;
	  color:#fff;
	}
	.card-time{
	  font-size: 12px;
	  line-height: 16px;
	  font-weight: normal;
	  color: #fff;
	  margin-top: 4px;
	}
	.footer {
		position: absolute;
		right: 10px;
		bottom: 35%;
	}
	.footer-btn {
		padding: 3px 5px;
		border-radius: 15px;
		background-color: #fff;
		color: #ff4243;
	}
	.empty {
		position: absolute;
		top: 100px;
		left: 0;
		width: 100%;
		text-align: center;
	}
</style>
