<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item label="文本">
        <el-input v-model="com.text" placeholder="请输入占位内容"></el-input>
      </el-form-item>
      <el-form-item label="对齐方式">
          <el-radio-group v-model="com.align">
              <el-radio-button :label="'left'">左对齐</el-radio-button>
              <el-radio-button :label="'center'">居中</el-radio-button>
              <el-radio-button :label="'right'">右对齐</el-radio-button>
          </el-radio-group>
      </el-form-item>
    </el-form>
    <jfExplain v-model="drawer" :name="com.cName || '占位'"  :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '占位是一个用于元素之间的分隔组件，组件中可以通过设置组件的宽高、背景颜色及文本来实现占位。',
      fieldData:[]
    }
  },
  methods: {
  },
  props: { com: {} }
}
</script>
