<template>
	<div>
		<div class="fc" ref="fc"></div>
		<el-drawer ref="btmTable" :direction="'btt'" :with-header="false" title="列表设置" :size="btmSize" :visible.sync="btnVisible" :append-to-body="true">
			<div class="spliter" @mousedown="mousedown($event,$refs.btmTable)"></div>
		<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
      <el-button type="primary" size="mini" @click="addButton(com.buttons)">添加按钮</el-button>
	  <el-button type="warning" class="el-icon-top" size="mini" v-if="currBtn" @click="upGo(com.buttons,currBtn)">上移</el-button>
	  <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currBtn" @click="downGo(com.buttons,currBtn)">下移</el-button>
      <el-table :data="com.buttons" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row @current-change="currBtn=$event">
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="el-icon-close" @click="deleteGo(com.buttons,scope.$index)"></div>
			<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="标签" width="80">
          <template slot-scope="scope">
			<input v-model="scope.row.label" :style="scope.row==currBtn?'color:red;font-size:14px':''" placeholder="按钮文本"></input>
          </template>
        </el-table-column>
        <el-table-column label="颜色" width="50">
          <template slot-scope="scope">
            <el-dropdown @command="scope.row.type=$event">
              <el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="primary">主要</el-dropdown-item>
                <el-dropdown-item command="success">成功</el-dropdown-item>
                <el-dropdown-item command="info">信息</el-dropdown-item>
                <el-dropdown-item command="warning">警告</el-dropdown-item>
                <el-dropdown-item command="danger">危险</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="动作" width="110">
          <template slot-scope="scope">
            <el-select v-model="scope.row.actionType" placeholder="请选择">
               <el-option label="访问接口" value="fun"></el-option>
               <el-option label="打开页面" value="link"></el-option>
               <el-option label="弹出页面" value="dialog"></el-option>
               <el-option label="滑出页面" value="drawer"></el-option>
               <el-option label="导入" value="import"></el-option>
               <el-option label="导出" value="export"></el-option>
               <el-option label="下载" value="download"></el-option>
               <el-option label="刷新" value="refresh"></el-option>
               <el-option label="返回" value="back"></el-option>
             </el-select>
          </template>
        </el-table-column>
		<el-table-column label="权限" width="110">
			<template slot-scope="scope">
				<selectorRight v-model="scope.row.rightId"></selectorRight>
			</template>
		</el-table-column>
		<el-table-column label="禁用条件" width="110">
			<template slot-scope="scope">
				<el-select v-model="scope.row.hideByType" placeholder="请选择">
				   <el-option label="按条件" value="condition"></el-option>
				 </el-select>
				<input v-if="scope.row.hideByType == 'condition'" v-model="scope.row.hideBy" placeholder="> < <= >= == !=" style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;"/>
			</template>
		</el-table-column>
		<el-table-column label="后置操作" width="110">
			<template slot-scope="scope">
				<el-select v-model="scope.row.after">
					<el-option label="关闭" value="close"></el-option>
					<el-option label="刷新" value="refresh"></el-option>
					<el-option label="无" value=""></el-option>
				</el-select>
			</template>
		</el-table-column>
		<el-table-column label="控制类型" width="110">
			<template slot-scope="scope">
				<el-select v-model="scope.row.controlType">
					<el-option label="针对选中项(默认)" value="selected"></el-option>
					<el-option label="针对全部项" value="all"></el-option>
				</el-select>
			</template>
		</el-table-column>
        <el-table-column label="动作类型" width="250">
          <template slot-scope="scope">
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.link" :comData="comData"></selectorPage>
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.linkM" :type="'M'" :comData="comData"></selectorPage>
			<!-- <el-checkbox v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.backRefresh">关闭刷新</el-checkbox> -->
			<input v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.windowWidth" placeholder="宽度" style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;"/>
			<selectorFun v-if="['fun','import'].includes(scope.row.actionType)" v-model="scope.row.fun" :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
			<input v-if="scope.row.actionType=='fun'" v-model="scope.row.funTip" placeholder="提示信息" style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;"/>
            <input v-if="scope.row.actionType=='download'" v-model="scope.row.link" placeholder="下载地址" style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;"/>
          </template>
        </el-table-column>
      </el-table>
	  </div>
    </el-drawer>
    
	<el-drawer ref="btmbtnl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="rowVisible" :append-to-body="true">
		<div class="spliter" @mousedown="mousedown($event,$refs.btmbtnl)"></div>
    	<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
      <el-button type="primary" size="mini" @click="addButton(com.rowButtons)">添加按钮</el-button>
	  <el-button type="warning" class="el-icon-top" size="mini" v-if="currRow" @click="upGo(com.rowButtons,currRow)">上移</el-button>
	  <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currRow" @click="downGo(com.rowButtons,currRow)">下移</el-button>
	  <el-table :data="com.rowButtons" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row @current-change="currRow=$event">
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="el-icon-close" @click="deleteGo(com.rowButtons,scope.$index)"></div>
			<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="标签" width="80">
          <template slot-scope="scope">
			<input v-model="scope.row.label" :style="scope.row==currRow?'color:red;font-size:14px':''" placeholder="按钮文本"></input>
          </template>
        </el-table-column>
        <el-table-column label="颜色" width="50">
          <template slot-scope="scope">
            <el-dropdown @command="scope.row.type=$event">
              <el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="primary">主要</el-dropdown-item>
                <el-dropdown-item command="success">成功</el-dropdown-item>
                <el-dropdown-item command="info">信息</el-dropdown-item>
                <el-dropdown-item command="warning">警告</el-dropdown-item>
                <el-dropdown-item command="danger">危险</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="动作" width="110">
          <template slot-scope="scope">
            <el-select v-model="scope.row.actionType" placeholder="请选择">
               <el-option label="访问接口" value="fun"></el-option>
               <el-option label="打开页面" value="link"></el-option>
               <el-option label="弹出页面" value="dialog"></el-option>
               <el-option label="滑出页面" value="drawer"></el-option>
             </el-select>
          </template>
        </el-table-column>
		<el-table-column label="权限" width="110">
			<template slot-scope="scope">
				<selectorRight v-model="scope.row.rightId"></selectorRight>
			</template>
		</el-table-column>
		<el-table-column label="禁用条件" width="110">
			<template slot-scope="scope">
				<el-select v-model="scope.row.hideByType" placeholder="请选择">
				   <el-option label="未修改" value="unchanged"></el-option>
				   <el-option label="按条件" value="condition"></el-option>
				 </el-select>
				<input v-if="scope.row.hideByType == 'condition'" v-model="scope.row.hideBy" placeholder="> < <= >= == !=" style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;"/>
			</template>
		</el-table-column>
		<el-table-column label="后置操作" width="110">
			<template slot-scope="scope">
				<el-select v-model="scope.row.after">
					<el-option label="关闭" value="close"></el-option>
					<el-option label="刷新" value="refresh"></el-option>
					<el-option label="状态还原" value="recover"></el-option>
					<el-option label="加载子集" value="loadChild"></el-option>
					<el-option label="移除" value="remove"></el-option>
					<el-option label="无" value=""></el-option>
				</el-select>
			</template>
		</el-table-column>
        <el-table-column label="动作类型" width="400">
          <template slot-scope="scope">
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.link" :comData="comData"></selectorPage>
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.linkM" :comData="comData"></selectorPage>
			<!-- <el-checkbox v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.backRefresh">关闭刷新</el-checkbox> -->
			<input v-if="['dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.windowWidth" placeholder="宽度" style="display: inline-block;border: 1px solid #b8b8b8;width: 100px;padding: 2px;"/>
			<selectorFun v-if="scope.row.actionType=='fun'" v-model="scope.row.fun" :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
            <input v-if="scope.row.actionType=='fun'" v-model="scope.row.funTip" placeholder="提示信息" style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;"/>
          </template>
        </el-table-column>
      </el-table>
	  </div>
    </el-drawer>
    
    <el-drawer ref="btmSearch" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="searchVisible" :append-to-body="true">
		<div class="spliter" @mousedown="mousedown($event,$refs.btmSearch)"></div>
    	<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
		<el-button type="primary" size="mini" @click="addSearch">添加条件</el-button>
		<el-button type="primary" size="mini" @click="getCols('in')">获取条件</el-button>
	  <el-button type="warning" class="el-icon-top" size="mini" v-if="currSearch" @click="upGo(com.searchComs,currSearch)">上移</el-button>
	  <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currSearch" @click="downGo(com.searchComs,currSearch)">下移</el-button>
      <span v-if="com.searchComs.length>0">搜索条
        <el-select size="mini" class="searchBarSelect" v-model="com.searchBar">
           <el-option label="None" value=""></el-option>
           <el-option v-for="(item,cIx) in com.searchComs" :key="cIx" :label="item.label" :value="item.field"></el-option>
        </el-select>
      </span>
    <el-table :data="com.searchComs" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}" highlight-current-row @current-change="currSearch=$event">
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <div class="el-icon-close" @click="deleteGo(com.searchComs,scope.$index)"></div>
      	<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
        </template>
      </el-table-column>
	  <el-table-column label="字段" width="120">
	    <template slot-scope="scope">
	  	<!-- <span :style="scope.row==currSearch?'color:red;font-size:14px':''">{{scope.row.field}}</span> -->
      <el-input :style="scope.row==currSearch?'color:red;font-size:14px':''" v-model="scope.row.field"></el-input>
	    </template>
	  </el-table-column>
      <el-table-column label="标签" width="120">
        <template slot-scope="scope">
          <el-input v-model="scope.row.label"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="80" label="默认值" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.default"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="80" label="样式" align="center">
        <template slot-scope="scope">
          <jfStyle v-model="scope.row.style"></jfStyle>
        </template>
      </el-table-column>
      <el-table-column label="组件类型" width="150">
        <template slot-scope="scope">
          <el-select v-model="scope.row.type" placeholder="请选择">
             <el-option key="4" label="输入框"  value="input" v-if="!scope.row.readonly"></el-option>
             <el-option key="9" label="年月"    value="month" v-if="!scope.row.readonly"></el-option>
             <el-option key="10" label="日期"    value="date" v-if="!scope.row.readonly"></el-option>
             <el-option key="12" label="时间"   value="time" v-if="!scope.row.readonly"></el-option>
             <el-option key="15" label="日期时间"    value="datetime" v-if="!scope.row.readonly"></el-option>
             <el-option key="18" label="下拉框"  value="select" v-if="!scope.row.readonly"></el-option>
             <el-option key="19" label="级联下拉"  value="cascader" v-if="!scope.row.readonly"></el-option>
             <el-option key="21" label="单选钮"  value="radio" v-if="!scope.row.readonly"></el-option>
             <el-option key="24" label="复选框"  value="checkbox" v-if="!scope.row.readonly"></el-option>
             <el-option key="27" label="参照页"  value="lookup" v-if="!scope.row.readonly"></el-option>
           </el-select>
        </template>
      </el-table-column>
      <el-table-column label="数据源" width="200">
        <template slot-scope="scope">
          <!-- <el-input v-if="scope.row.type=='select'" v-model="scope.row.optionsDataIndex"></el-input> -->
		  <jfDataSource v-if="['select','cascader'].includes(scope.row.type)" v-model="scope.row.options"></jfDataSource>
		  <selectorLookup v-if="scope.row.type=='lookup'" v-model="scope.row.look" :coms="com.searchComs"></selectorLookup>
		  <!-- <div v-if="scope.row.type=='lookup'" class="other">
		  	<selectorPage v-model="scope.row.link" :comData="comData"></selectorPage>
		  	<selectorPage v-model="scope.row.linkM" :comData="comData"></selectorPage>
		  	<el-input v-model="scope.row.textField" size="mini" placeholder="显示字段"/>
		  	<el-input v-model="scope.row.setField" size="mini" placeholder="带回字段"/>
		  </div> -->
        </template>
      </el-table-column>
    </el-table>
	</div>
    </el-drawer>
    
    <el-drawer ref="btmFieldl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="colVisible" :append-to-body="true">
		<div class="spliter" @mousedown="mousedown($event,$refs.btmFieldl)"></div>
    	<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
		<el-button type="primary" size="mini" @click="addField()">添加字段</el-button>
		<el-button type="primary" size="mini" @click="getCols('out')">获取字段</el-button>
		<el-button type="primary" size="mini" @click="getData">获取数据</el-button>
	  <el-button type="warning" class="el-icon-top" size="mini" v-if="currCol" @click="upGo(com.cols,currCol)">上移</el-button>
	  <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currCol" @click="downGo(com.cols,currCol)">下移</el-button>
	  
        <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
          <el-radio-group v-model="hasToken">
            <el-radio :label="0">不带</el-radio>
            <el-radio :label="1">带Token</el-radio>
          </el-radio-group>
          <el-row v-if="com.fun.prms" v-for="(value, key, index) in com.fun.prms" :key="index">
            <el-col :span="8">{{key}}</el-col>
            <el-col :span="8"><el-input v-model="com.fun.prms[key]" placeholder="Token"></el-input></el-col>
          </el-row>
          <el-button plain size="mini" @click="getData" type="primary">提交</el-button>
          <el-input v-model="currentToken" placeholder="Token" v-if="hasToken===1"></el-input>
      </el-dialog>
      <el-table :data="com.cols" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}" highlight-current-row @current-change="currCol=$event">
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="el-icon-close" @click="deleteGo(com.cols,scope.$index)"></div>
			<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="字段" width="100">
          <template slot-scope="scope">
			<input v-model="scope.row.field" @blur="scope.row.field = scope.row.field.trim()" :style="scope.row==currCol?'color:red;font-size:14px':''" placeholder="按钮文本"></input>
          </template>
        </el-table-column>
        <el-table-column label="标签" width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.label"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="80" label="类型">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择">
               <el-option key="1" label="文本" value="text"></el-option>
               <el-option key="5" label="图片" value="img"></el-option>
               <el-option key="6" label="输入框" value="input"></el-option>
               <el-option key="7" label="整数输入框" value="number"></el-option>
               <el-option key="8" label="小数输入框" value="numberFloat"></el-option>
               <el-option key="9" label="年月日" value="ymd"></el-option>
               <el-option key="10" label="年月日时分秒" value="ymdhms"></el-option>
             </el-select>
          </template>
        </el-table-column>
        <el-table-column label="变色条件" width="110">
          <template slot-scope="scope">
            <el-select v-model="scope.row.colorType" placeholder="请选择">
              <el-option label="按条件" value="condition"></el-option>
            </el-select>
            <input v-if="scope.row.colorType == 'condition'" v-model="scope.row.hideBy" placeholder="> < <= >= == !=" style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;"/>
            <div v-if="scope.row.colorType == 'condition'">颜色：<el-color-picker v-model="scope.row.colorValue"></el-color-picker></div>
          </template>
        </el-table-column>
        <el-table-column width="160" label="PC列宽">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.width" size="mini" controls-position="right"></el-input-number>
          </template>
        </el-table-column>
		<el-table-column width="60" label="排序" align="center">
		  <template slot-scope="scope">
		    <el-checkbox v-model="scope.row.sortable"></el-checkbox>
		  </template>
		</el-table-column>
        <el-table-column width="80" label="对齐">
          <template slot-scope="scope">
            <el-select v-model="scope.row.align" placeholder="请选择">
               <el-option key="1" label="左" value="left"></el-option>
               <el-option key="2" label="中" value="center"></el-option>
               <el-option key="3" label="右" value="right"></el-option>
             </el-select>
          </template>
        </el-table-column>
		<el-table-column width="80" label="样式">
			<template slot-scope="scope">
				<jfStyle v-model="scope.row.style"></jfStyle>
			</template>
		</el-table-column>
      </el-table>
	  </el-tab-pane>
   </el-tabs>
   </div>
   </el-drawer>
   <el-form  label-width="90px" size="mini">
    <el-form-item class="margin-tb" label="组件说明">
      <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
    </el-form-item>
		<el-form-item class="margin-tb" label-width="0px">
      <div style="display: flex;">
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="btnVisible=true">按钮栏</el-button>
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="rowVisible=true">行操作</el-button>
      </div>
		</el-form-item>
		<el-form-item class="margin-tb" label-width="0px">
      <div style="display: flex;">
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="searchVisible=true">搜索条件</el-button>
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="colVisible=true">列设置</el-button>
      </div>
		</el-form-item>
		<el-form-item class="margin-tb" label="分页">
			<el-checkbox v-model="com.paging">分页</el-checkbox>
		</el-form-item>
		<el-form-item class="margin-tb" v-if="com.paging" label="分页数据">
			<el-input-number size="mini" v-model="com.pageDataIndex"></el-input-number>
		</el-form-item>
		
		<el-form-item class="margin-tb" label="列表数据">
			<el-input-number size="mini" v-model="com.listDataIndex"></el-input-number>
		</el-form-item>
		<el-form-item class="margin-tb" label="操作列宽度">
			<el-input-number size="mini" v-model="com.actionWidth"></el-input-number>
		</el-form-item>
		<el-form-item class="margin-tb" label="选中依赖">
      <input v-model="com.checkByField" placeholder="> < <= >= == !="/>
		</el-form-item>
     
		<el-form-item class="margin-tb" label="是否树状">
			<el-checkbox v-model="com.isTree">是否树状</el-checkbox>
		</el-form-item>
		
		<el-form-item class="margin-tb" label="高度自动">
			<el-checkbox v-model="com.heightAuto">高度自动</el-checkbox>
		</el-form-item>
		
		<el-form-item class="margin-tb" label="是否参照">
			<el-checkbox v-model="com.isLookup">是否参照</el-checkbox>
		</el-form-item>
		
		<el-form-item class="margin-tb" v-if="com.isLookup">
			  <el-radio-group v-model="com.selectMode">
			      <el-radio :label="1">单选</el-radio>
			      <el-radio :label="2">多选</el-radio>
			    </el-radio-group>
		</el-form-item>
		</el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '列表'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
   </div>
</template>

<script>
export default {
  data () {
    return {
      hasToken: 0,
      currentToken: '',
      dialogVisible: false,
      // users: [],
      // backData: null,
	  sortStr: '',
	  btnVisible: false,
	  rowVisible: false,
	  searchVisible: false,
	  colVisible: false,
	  btmSize: '400px',
	  currBtn: null,
	  currRow: null,
	  currSearch: null,
	  currCol: null,
    drawer: false,
    desc: `列表组件可以在pc与移动端通用，可实现大部分列表操作，
    按钮栏可以配置组件顶部的按钮操作所有列表，行操作是在每列后面
    添加按钮方便操作每列数据，搜索条件方便列表搜索，列设置是列表
    列表每列的显示内容，可设置字段名、无类型、标签名、样式等，
    自动高度配置将取消固定高度，进行高度自适应。`,
    fieldData:[],
    requestData:[]
    }
  },
  created: function () {
    this.currentToken = localStorage.getItem('testToken')
    // const data = { module: 'RemoteDb', action: 'GetUsers', prms: { sysId: this.$route.params.id } }
    // this.common.request(data, this.getUsersCallback.bind(this))
  },
  methods: {
    // getUsersCallback (r) {
    //   if (r.code === 0) {
    //     this.users = r.data
    //   } else this.$message.error(r.msg)
    // },
    addButton (btns) {
      btns.push({ label: '', type: 'primary',rightId: '0', actionType: 'fun', fun: null, link: '',fields:[] })
    },
    addField () {
      const ctrl = {
        type: 'text',
        field: '',
        label: '',
        style: {}
      }
      this.com.cols.push(ctrl)
    },
	getData(){
		if (!this.dialogVisible) {
        if (!this.com.fun) this.$message.error('没有设置接口')
        else this.dialogVisible = true
        return
      }
	  if(this.currentToken.length>0) localStorage.setItem('testToken', this.currentToken)
      const opt = { server: this.$route.params.url, subsys: this.$route.params.subsys }
      if (this.hasToken) opt.token = this.currentToken
      else opt.withToken = false
      this.common.request(this.com.fun, this.setData.bind(this), opt)
	},
    setData (r) {
      if (r.code == 0) {
		  this.com.data = r.data[this.com.listDataIndex].slice(0, 5)
		  this.dialogVisible = false
	  } else this.$message.error(r.msg)
    },
	addSearch(){
		const prmsField = {field: '',label: '',type: 'input',optionsDataIndex: '',options: []}
		this.com.searchComs.push(prmsField)
	},
	getCols(type){
		if (this.com.fun) {
			let data = { module: 'sysfun', action: 'getDefine', prms: Object.assign({sysId: this.$route.params.id},this.com.fun)}
			this.common.request(data, this.getColsCb.bind(this,type))
		} else {
			this.$message.error('没有设置接口')
		}
	},
	getColsCb (type,r) {
	  if (r.code != 0) {
		  this.$message.error(r.msg)
		  return
	  }
	  if (r.data[0].length == 0) {
			this.$message.error('接口不存在')
	  		return
	  }
		let define = JSON.parse(r.data[0][0].define)
		if(type=='out')
		for (let j = 0; j < define.out.length; j++) {
		  const ctrl = {
			type: 'text',
			field: define.out[j].field,
			label: define.out[j].comment,
			style: {}
		  }
		  let has = this.com.cols.find(fld=>fld.field==ctrl.field)
		  if(!has) this.com.cols.push(ctrl)
		}
		else
		  for (let i = 0; i < define.in.length; i++) {
			if (['orderBy', 'page', 'limit', 'appId'].includes(define.in[i].field)) continue
			const prmsField = {
			  field: define.in[i].field,
			  label: define.in[i].comment,
			  type: 'input',
			  optionsDataIndex: '',
			  options: []
			}
			let has = this.com.searchComs.find(fld=>fld.field==prmsField.field)
			if(!has) this.com.searchComs.push(prmsField)
		  }
    },
	getIndex(arr,row){
		for(let i = 0; i < arr.length; i++){
			if(arr[i] == row) return i
		}
	},
    upGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
      } else {
        arr.push(arr.shift())
      }
    },
    downGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
      } else {
        arr.unshift(arr.splice(index, 1)[0])
      }
    },
    deleteGo (arr, index) {
      arr.splice(index, 1)
    },
	mousedown(evt,sourceDom) {
	  let height = parseInt(sourceDom.size)
	  let pageY = evt.pageY
	  let start = true
	  this.$refs.fc.style.display = 'block'
	  let that = this
	  document.onmousemove = (event) => {
		if(start) {
			// sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
			that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
		}
	  };
	  document.onmouseup = () => {
		  this.$refs.fc.style.display = 'none'
		document.onmousemove = null;
		document.onmouseup = null;
		start = false
		return false;
	  }
	}
  },
  props: {
    com: Object,
    comData: Object,
	  saveMethod: Function
  }
}
</script>
<style scoped>
.number{
  width:90px;
}
.el-form-item{
	width: 220px;
}
.el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
     padding-left: 2px;
}

.el-table .cell, .el-table th div {
     padding-right: 0px;
}

.el-collapse-item__header {
  background-color: lightblue;
}
.el-select{
  display: inline-block;
}
.sortStr{
	width: 180px;
	overflow: hidden;
	display: inline-block;
}
.drw{
	padding-left: 20px;
}
.spliter{
	width: 100%;
    height:5px;
    background-color: #006666;
	cursor: s-resize;
  }
  .fc{
	  display: none;
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(216, 216, 216, 0.1);
	  z-index: 10000;
  }
  .margin-tb {
    margin: 5px 0;
  }
</style>
