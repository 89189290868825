<template>
  <div :class="'h100 groupStyle'+com.groupStyle" :style="[com.style]">
    <img
      v-for="(item, index) in com.data"
      :key="index"
      :src="$route.params.url+item.imgUrl"
	  :onerror="common.imgOnError"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverUrl: this.common.serverUrl,
      data: null
    }
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>

<style scoped>
  .h100{height: 100%;}
  .groupStyle0{
	  img{width: 100%;height: 100%;}
  }
  .groupStyle1{
    img{width: 50%;height: 100%;}
  }
  .groupStyle2{
    img{width: 33.3%;height: 100%;}
  }
  .groupStyle3{
    img{width: 25%;height: 100%;}
  }
  .groupStyle4{
    img{width: 20%;height: 100%;}
  }
  .groupStyle5{
    img{height: 50%; width: 50%; float: left;}
    img:nth-child(1){height: 100%;}
  }
  .groupStyle6{
    img{height: 50%; width: 33.3%; float: right;}
    img:nth-child(1){height: 100%;}
  }
</style>
