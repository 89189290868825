<template>
  <div class="" :style="[com.style]">
    <el-carousel
      :loop="true "
      :autoplay="true"
      :interval="com.interval*1000"
      class="pikImg"
    >
      <el-carousel-item
        class="pikImg"
        v-for="(item, index) in com.data"
        :key="index"
      >
        <img :src="$route.params.url+item.imgUrl" class="pikImg" fit="fill" :onerror="common.imgOnError"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<style>
.pikImg,.el-carousel__container{
	width: 100%;
	height: 100%;
}
</style>

<script>
export default {
  methods: {
		getBinding(){
		  return [{label: '轮播图', field: 'info'}]
		}
  },
  props: {
    com: Object
  }
}
</script>
