<template>
	<!-- <el-select v-model="right" clearable filterable placeholder="请选择" @change="changed">
	  <el-option v-for="item in rights" :key="item.id" :label="item.name" :value="item.id"></el-option>
	</el-select> -->
	<el-cascader v-model="right" :options="rights" :show-all-levels="false"
	:props="{value:'id',label:'name',checkStrictly:true}"
	@change="rightChange"></el-cascader>
</template>

<script>
	export default {
	  data () {
	    return {
			rights: []
	    }
	  },
	  created: function () {
		  let fun = { module: 'RemoteDb', action: 'GetRights', prms: {sysId: this.$route.params.id } }
		  this.common.request(fun, this.getRightsCb.bind(this))
	  },
	  methods: {
		  rightChange(v){
				let right = v[v.length-1]
				this.$emit('setRight', right)
			},
		  getTree(arr,pId){
		  	let newArr = []
		  	for(let i = 0; i < arr.length; i++){
		  		if(arr[i].pId == pId){
		  			newArr.push(arr[i])
		  			let childs = this.getTree(arr,arr[i].id)
		  			if(childs.length>0) arr[i].children = childs
		  		}
		  	}
		  	return newArr
		  },
	    getRightsCb (r) {
	      if (r.code == 0) {
	        this.rights = this.getTree(r.data,0)
			// for(let i = 0; i < this.rights.length; i++){
			// 	this.rights[i].id = parseInt(this.rights[i].id)
			// }
	        this.rights.unshift({ id: '0', name: '不限制' })
	      } else this.$message.error(r.msg)
	    }
	  },
	  model: {
		prop: 'right',
		event: 'setRight'
	  },
	  props: {
	    right: [String,Number]
	  }
	}
</script>

<style>
</style>