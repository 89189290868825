<template>
    <div class='short-video wh100' :style="[com.style]">
        <video
            class="wh100"
            preload="auto"
            id="my-video"
            :src="'https://media.w3.org/2010/05/sintel/trailer.mp4'"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="true"
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            controls="controls"
            x5-video-ignore-metadata="true"
            width="100%"
            height="100%"
        >
            <p>当前视频不支持</p>
        </video>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        com: Object
    },
    methods: {},
    components:{}
};
</script>

<style scoped>
    .wh100 {
        width: 100%;
        height: 100%;
    }
</style>
