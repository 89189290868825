<template>
  <div class="funself">
    <!-- <div class="fun" :title="fun?fun.module + '-' + fun.action:''">{{fun?fun.module + '-' + fun.action:'null'}}</div> -->
    <el-popover v-model="visible" :placement="placement" width="400" trigger="click" :offset="offset" @show="search">
      <el-row>
        <el-col :span="14" class="pop">
          <el-tree :data="funList" :props="{children: 'children',label: 'label'}" accordion @node-click="funNodeClick"></el-tree>
        </el-col>
        <el-col :span="10" class="pop">
			<p style="color: red;" v-if="current">{{current.module}}-{{current.action}}</p>
          <p>参数绑定</p>
          <el-row v-for="(value, key, index) in current.prms" :key="index">
            <el-col :span="12">{{key}}</el-col>
            <el-col :span="12"><el-input v-model="current.prms[key]" placeholder="Token"></el-input></el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-button type="primary" size="mini" @click="clear" style="float:right">清空</el-button>
      <el-button type="primary" size="mini" @click="update" style="float:right" v-if="fun">更新</el-button>
      <el-button type="primary" size="mini" @click="dialogVisible=true" style="float:right" v-if="fun">编辑</el-button>
      <el-button type="primary" size="mini" @click="confirm" style="float:right">确定</el-button>
      <el-button type="primary" size="mini" slot="reference" :title="fun?fun.module + '-' + fun.action:''">{{fun?fun.module + '-' + fun.action:'设置'}}</el-button>
    </el-popover>
	<el-dialog :top="'5vh'" title="接口编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="90%" :append-to-body="true">
	  <funEditor v-if="dialogVisible" :comData="{fun:fun}" @funSaved="funSaved"></funEditor>
	</el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
		data:null,
      current: { prms: {} },
      funList: [],
      visible: false,
	  dialogVisible:false
    }
  },
  created: function () {
    if(this.fun) Object.assign(this.current.prms,this.fun.prms)
  },
  methods: {
	  search(){
		const data = { module: 'sysfun', action: 'getList', prms: { sysId: this.$route.params.id } }
		this.common.request(data, this.setPageList.bind(this))
	  },
    setPageList (data) {
		this.data = data.data[0]
      this.funList = this.getFunTree(data.data[0])
    },
    getFunTree (data) {
      const arr = []
      for (var i = 0; i < data.length; i++) {
        const fil = arr.filter(item => item.label === data[i].module)
        if (fil.length === 0) {
          const node = { label: data[i].module, children: [{ label: data[i].action, data: data[i] }] }
          arr.push(node)
        } else {
          fil[0].children.push({ label: data[i].action, data: data[i] })
        }
      }
      return arr
    },
    funNodeClick (node) {
      if (node.data){
		  this.current.subsys = node.data.subsys
		  this.current.module = node.data.module
		  this.current.action = node.data.action
		  this.current.prms	= {}
		  if(node.data.text){
			  const prms = this.current.prms
			  const arr = node.data.text.match(/:\w+/g)
			  for (let i = 0; arr && i < arr.length; i++) {
				const key = arr[i].substring(1)
				if(key == 'orderBy') prms[key] = 1
				else if(key == 'page') prms[key] = 0
				else if(key == 'limit') prms[key] = 10
				else prms[key] = ''
			  }
		  }
	  }
    },
	update(){
		let fun = this.data.find(item=>item.module==this.fun.module && item.action==this.fun.action)
		this.funNodeClick({data:fun})
	},
	funSaved(fun){
		this.dialogVisible = false
		this.funNodeClick({data:fun})
		this.confirm()
	},
    confirm () {
      this.$emit('setFun', Object.assign({}, this.current))
      this.visible = false
      this.$emit('changed', Object.assign({}, this.current))
    },
    clear () {
      this.$emit('setFun', null)
      this.visible = false
      this.$emit('changed', null)
    }
  },
  model: {
    prop: 'fun',
    event: 'setFun'
  },
  props: {
    fun: Object,
    comData: Object,
    placement: String,
    offset: 0
  }
}
</script>

<style scoped="scoped">
  .fun{display: inline-block;height:25px;width: 60px;overflow: hidden;}
  .funself{max-width: 200px;}
  .pop{overflow: auto; height: 400px;}
  /deep/ .el-dialog {
          height: 90vh;
          overflow: auto;
      }
</style>
