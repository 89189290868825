<template>
	<view>
		<view class="padding"></view>
		<view class="content">
			<view class="nav">
				<view class="nav-left" >
					<scroll-view scroll-y :style="'height:'+height+'px'">
						<view class="nav-left-item" @click="categoryClickMain(item,index)" :key="index" :class="index==categoryActive?'active':''" v-for="(item,index) in data">
							{{item.name|| ''}}
						</view>
					</scroll-view>
				</view>
				<view class="nav-right" :style="'height:'+height+'px'">
					<scroll-view scroll-y :scroll-top="scrollTop" @scroll="scroll" v-for="(c_item,c_index) in items" :key="c_index"  scroll-with-animation >
						<view>
							 {{c_item.name}}
						</view>
						<view :id="index==0?'first':''" class="nav-right-item" v-for="(sub_item,s_index) in c_item.items" :key="s_index"  @click="itemClick(item)">
							<image :src="sub_item.icon" />
							<view>{{sub_item.name}}</view>
						</view>
					</scroll-view>
				</view>
			</view>
		</view>
	</view>
</template>

<script>
export default {
  data () {
    return {
      data: [],
      items: [],
      height: 0,
      categoryActive: 0,
      scrollTop: 0,
      scrollHeight: 0
    }
  },
  created () {
    uni.getSystemInfo({
      success: res => {
        this.height = res.screenHeight
      }
    })
    this.items = this.data[0].items
  },
  methods: {
    scroll (e) {
      this.scrollHeight = e.detail.scrollHeight
    },
    categoryClickMain (categroy, index) {
      this.categoryActive = index
      this.items = categroy.items
      this.scrollTop = -this.scrollHeight * index
    },
    itemClick (data) {
					  this.common.navigateTo({ pageName: data.pageName, data: data })
    }
  },
  props: {
    pageObj: Object,
    parentData: Array,
    com: Object
  }
}
</script>

<style>
	.padding {
		height: 1px;
		width: 100%;
		top: 0;
		position: fixed;
	}
	.nav {
		display: flex;
		width: 100%;
	}

	.nav-left {
		width: 30%;
		background-color: #E0E0E0;
	}

	.nav-left-item {
		height: 50px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nav-right {
		width: 70%;
	}

	.nav-right-item {
		width: 28%;
		float: left;
		text-align: center;
		font-size: 28px;
	}
	.nav-right-item image{
		width: 50px;
		height: 50px;
	}
	.active {
		color: #F24544;
	}
	.content {
		width: 100%;
	}
</style>
