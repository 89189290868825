<template>
    <div :style="[com.style]">
        <div class="nav flex">
			<div class="nav-item" :class="{active: current == item.id}" @click="current = item.id" v-for="(item,index) in navArr" :key="index" v-show="item.check">{{item.text}}</div>
		</div>
        <div class="wrap">
            <div class="list" v-for="(item,index) in com.data" :key="index" :style="{backgroundColor:com.bgColor,height:com.cardHeight+'px',borderRadius:com.borderRadius+'px',overflow: 'hidden'}">
                <div class="header flex">
                    <div>{{ item.status }}</div>
                </div>
                <div class="content flex">
                    <div class="listImg left">
                        <!-- <img :src="$route.params.url+item.image"/> -->
                    </div>
                    <div class="right flex">
                        <div class="r-title">
                            <div class="goods-name show-ellipsis">{{item.title}}</div>
                        </div>
                        <div class="r-price">
                            <div class="goods-price" :style="{color: com.priceColor}">
                                ￥<span class="big-font">{{item.price||'商品价格'}}</span>
                            </div>
                            <div class="small-font">共{{ item.num }}件</div>
                        </div>
                    </div>
                </div>
                <div class="handle flex">
                    <div class="btn">删除</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current: 1,
            navArr: [{text:'全部',id:1,check:true},{text:'待支付',id:2,check:this.com.payState},{text:'待收货',id:3,check:this.com.consignState},{text:'已完成',id:4,check:this.com.successState},{text:'已取消',id:5,check:this.com.closeState},],
        };
    },
    created() {},
    methods: {},
    props: {
        com: Object
    }
};
</script>

<style scoped>
	.w100 {
	    width: 100%;
	}
	.h100 {
		height: 100%;
	}
    .flex {
        display: flex;
        align-items: center;
    }
    .nav {
        background-color: #f7f7f7;
        position: sticky;
        top: 0;
        height: 50px;
        text-align: center;
        line-height: 40px;
        .nav-item {
            flex: 1;
        }
    }
	.active {
		color: #ff4142;
		font-size: 32rpx;
		font-weight: 600;
	}
    .wrap {
        padding: 20px;
        font-size: 16px;
        .list {
            border: 1px solid #ccc;
            padding: 10px;
        }
        .header {
            justify-content: flex-end;
        }
        .content {
            height: 100px;
            .left {
                width: 30%;
            }
            .right {
                width: 70%;
                justify-content: space-between;
                .r-price {
                    text-align: right;
                }
                .big-font {
                    font-size: 18px;
                }
                .small-font {
                    font-size: 14px;
                }
            }
        }
        .handle {
            justify-content: flex-end;
            .btn {
                padding: 2px 10px;
                border: 1px solid #ccc;
                border-radius: 20px;
            }
        }
    }
    .show-ellipsis {
        overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* 显示的行数 */
		-webkit-box-orient: vertical;
    }
</style>
