<template>
  <div class="self">
    <el-popover v-model="visible" width="500" trigger="click">
		<div style="height: 400px;overflow: scroll;">
			<div v-if="type == 'fun'">sql位置：<el-input-number v-model="intValue"></el-input-number></div>
			<template v-if="type == 'set'">
				<el-descriptions class="margin-top" v-for="(item,index) in arr" :key="index" :column="3" size="mini" border>
						<el-descriptions-item>
							<template slot="label">值</template>
							<input class="border-none" v-model="item.value" :style="item==row?'border:2px solid #ebb563':''" @focus="changeCurrent(item,index)"/>
						</el-descriptions-item>
						<el-descriptions-item>
							<template slot="label">文本</template>
							<input class="border-none" v-model="item.text" :style="item==row?'border:2px solid #ebb563':''" @focus="changeCurrent(item,index)"/>
						</el-descriptions-item>
						<el-descriptions-item>
							<template slot="label">
								<div class="el-icon-close" style="border: 1px solid #ccc;padding: 5px;" @click="deleteGo(index)"></div>
							</template>
						</el-descriptions-item>
				</el-descriptions>
			</template>
		</div>
		<el-radio-group size="mini" v-model="type">
			<el-radio-button label="set">固定</el-radio-button>
			<el-radio-button label="fun">动态</el-radio-button>
		</el-radio-group>
      <el-button type="primary" size="mini" @click="add" v-if="type == 'set'">添加</el-button>
	  <el-button type="warning" class="el-icon-top" size="mini" v-if="type == 'set' && Object.keys(row).length > 0" @click="upGo(arr,row)">上移</el-button>
	  <el-button type="warning" class="el-icon-bottom" size="mini" v-if="type == 'set' && Object.keys(row).length > 0" @click="downGo(arr,row)">下移</el-button>
      <el-button type="primary" size="mini" @click="confirm" style="float:right">确定</el-button>
      <el-button type="primary" size="mini" slot="reference">数据源</el-button>
    </el-popover>

  </div>
</template>

<script>
export default {
  data () {
    return {
		intValue: 1,
		arr: [],
		type: 'set',
		visible: false,
		row: {},
		current: null
    }
  },
  created() {
	if(Number.isInteger(this.value)){
		this.type = 'fun'
		this.intValue = this.value
	} else {
		this.type = 'set'
		this.arr = this.value
	}
  },
  methods: {
    confirm () {
      this.visible = false
	  if(this.type == 'set'){
		  this.$emit('input', this.arr)
	  } else {
		  this.$emit('input', this.intValue)
	  }
    },
	add(){
		this.arr.push({value: String(this.arr.length+1),text: ''})
	},
	changeCurrent(row,index) {
		this.row = row
		this.current = index
	},
	getIndex(arr,row){
		for(let i = 0; i < arr.length; i++){
			if(arr[i] == row) return i
		}
	},
    upGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
      } else {
        arr.push(arr.shift())
      }
    },
    downGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
      } else {
        arr.unshift(arr.splice(index, 1)[0])
      }
    },
		deleteGo(index) {
			this.arr.splice(index,1)
		}
  },
  props: {
    value: [Number,Array],
  }
}
</script>

<style scoped="scoped">
  .self{max-width: 250px;}
  .pop{overflow: auto; height: 400px;}
  .row >*{
	  display: inline-block;
	  border: 1px slategray solid;
	  padding: 5px;
  }
  .border-none {
	width: 120px;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
  }
  .border-none:focus {
		border: 2px solid #ebb563; /* 更改边框颜色和宽度 */
		outline: none; /* 去除默认的轮廓线 */
	}
</style>
