<template>
  <el-tabs type="border-card" v-model="active">
    <el-tab-pane label="页面" name="页面">
		<el-button type="warning" size="mini" @click="getPages">刷新</el-button>
    <el-table :data="pages" stripe height="500" tooltip-effect="dark">
	  <el-table-column prop="type" label="分类" width="160"></el-table-column>
      <el-table-column prop="name" label="名称" width="160"></el-table-column>
      <el-table-column prop="ctime" label="删除时间" width="150"></el-table-column>
      <el-table-column prop="userName" label="操作者" width="120"></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
		  <el-link type="success" size="mini" @click.stop="revertUi(scope.row)">还原</el-link>
        </template>
      </el-table-column>
    </el-table>
	</el-tab-pane>
	<el-tab-pane label="接口" name="接口">
		<el-button type="warning" size="mini" @click="getFuns">刷新</el-button>
		<el-table :data="funs" stripe height="500" tooltip-effect="dark">
		  <el-table-column prop="name" label="名称" width="160"></el-table-column>
		  <el-table-column prop="module" label="模块" width="160"></el-table-column>
		  <el-table-column prop="action" label="动作" width="160"></el-table-column>
		  <el-table-column prop="ctime" label="删除时间" width="150"></el-table-column>
		  <el-table-column prop="userName" label="操作者" width="120"></el-table-column>
		  <el-table-column label="操作" fixed="right">
		    <template slot-scope="scope">
			  <el-link type="success" size="mini" @click.stop="revertFun(scope.row)">还原</el-link>
		    </template>
		  </el-table-column>
		</el-table>
	</el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data () {
    return {
		active:'页面',
      pages: [],
      funs: []
    }
  },
  created: function () {
    this.getPages()
    this.getFuns()
  },
  methods: {
	getPages(){
		const fun = { module: 'ui', action: 'getDeleted', prms: { sysId: this.$route.params.id } }
		this.common.request(fun, this.setPages.bind(this))
	},
	getFuns(){
		const d = { module: 'sysfun', action: 'getDeleted', prms: { sysId: this.$route.params.id } }
		this.common.request(d, this.setFuns.bind(this))
	},
    setPages (data) {
      this.pages = data.data[0]
    },
    setFuns (data) {
      this.funs = data.data[0]
    },
    revertUi (row) {
        const data = { module: 'ui', action: 'reDelete', prms: row }
        this.common.request(data, this.revertUiCb.bind(this))
    },
    revertFun (row) {
        const data = { module: 'sysfun', action: 'reDelete', prms: row }
        this.common.request(data, this.revertFunCb.bind(this))
    },
    revertUiCb (r) {
      if (r.code === 0) this.getPages()
      else this.$message.error(r.msg)
    },
    revertFunCb (r) {
      if (r.code === 0) this.getFuns()
      else this.$message.error(r.msg)
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
