<template>
  <div class="self" :style="[com.style]">
    <template v-if="clientType == 'm'">
      <div v-if="!showSearch&&com.searchBar" class="searchBar">
        <el-input v-model="com.fun.prms[com.searchBar]"></el-input>
      </div>
      <div class="topPanel">
        <el-button class="selectBtn" size="mini" @click="showSearch=!showSearch">筛选</el-button>
        <el-button class="selectBtn" size="mini" @click="showMenu=!showMenu">操作</el-button>
      </div>
      <div v-show="showMenu" class="btnPanel">
        <el-button @click="hasCom" v-for="(btn,index) in com.buttons" :key="index" :type="btn.type" :class="btn.type" size="mini">{{btn.label}}</el-button>
      </div>
      <div v-show="showSearch">
        <div v-for="(item, index) in com.searchComs" :key="index" :style="item.style" class="cell">
          <div class="searchLabel">{{item.label}}：</div>
          <el-input v-if="item.type == 'input'" v-model="com.fun.prms[item.field]"></el-input>
          <el-select v-if="item.type == 'select'" v-model="com.fun.prms[item.field]">
            <el-option label="None" value=""></el-option>
            <el-option v-for="(item,cIx) in item.options" :key="cIx" :label="item.text" :value="item.value"></el-option>
          </el-select>
          <el-date-picker v-if="item.type == 'date'" v-model="com.fun.prms[item.field]" type="date" placeholder="选择日期"></el-date-picker>
          <el-time-select v-if="item.type == 'time'" v-model="com.fun.prms[item.field]" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
          <el-date-picker v-if="item.type == 'datetime'" v-model="com.fun.prms[item.field]" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </div>
        <div style="display: flex;justify-content: flex-end;padding: 0 5px;">
          <el-button size="mini" @click="showSearch=false">取消</el-button>
          <el-button size="mini" @click="showSearch=false">搜索</el-button>
        </div>
      </div>
      <div v-for="(row, rI) in com.data" :key="rI" class="row">
        <div :style="{height: com.rowHeight}" class="cells">
          <div v-for="(item, index) in com.cols" :key="index" :style="item.style" class="cell">
            <div v-if="item.type == 'text'">
              <span class="fieldlabel" v-if="item.label">{{item.label}}:</span>
              <span>{{row[item.field] || '---'}}</span>
            </div>
            <img v-if="item.type == 'img'" :src="row[item.field]"></img>
          </div>
        </div>
        <div v-if="com.rowButtons.length>0" class="rbtns">
          <el-button v-for="(btn,bI) in com.rowButtons" :key="bI" class="rbtn" style="padding: 5px" :type="btn.type" size="mini">{{btn.label}}</el-button>
        </div>
      </div>
    </template>
    <template v-if="clientType == 'pc'">
        <div class="headBar" v-if="com.isLookup || com.searchComs.length>0 || com.buttons.length>0">
        <div v-if="!item.hide" v-for="(item, index) in com.searchComs" :key="index" :style="item.style" class="searchItem">
          <div class="searchLabel">{{item.label}}</div>
          <el-input v-if="item.type == 'input'" v-model="com.fun.prms[item.field]" clearable size="mini"></el-input>
            <el-select v-if="item.type == 'select'" v-model="com.fun.prms[item.field]" size="mini" clearable placeholder="请选择">
                <el-option  v-for="(itm,idx) in Number.isInteger(item.options)?pageData[item.options]:item.options" :key="idx" :label="itm.text" :value="itm.value"></el-option>
            </el-select>
          <el-date-picker v-if="item.type == 'month'" size="mini" v-model="com.fun.prms[item.field]" type="month" value-format="yyyy-MM" placeholder="选择年月"></el-date-picker>
          <el-date-picker v-if="item.type == 'date'" size="mini" v-model="com.fun.prms[item.field]" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          <time-select v-if="item.type == 'time'" v-model="com.fun.prms[item.field]" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></time-select>
          <el-date-picker v-if="item.type == 'datetime'" size="mini" v-model="com.fun.prms[item.field]" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </div>
        <el-button v-if="!btn.hide" v-for="btn in com.buttons" :class="btn.type" :key="btn.label" size="mini">{{btn.label}}</el-button>
      </div>
        <el-table ref="table" :height="com.heightAuto?'':tableHeight" :data="com.data" tooltip-effect="dark" row-key="id" lazy :load="loadChildren" :style="{width: tableWidth+'px'}">
          <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column v-if="!item.hide" :label="item.label"  v-for="(item, index) in com.cols" :key="index" :index="index" :width="item.width" :align="item.align" :prop="item.field" :sortable="item.sortable?'custom':false">
          <template slot-scope="scope">
            <div :style="[item.style]" class="cellValue" v-if="item.type == 'text'" >{{scope.row[item.field]}}</div>
          <img :style="[item.style]" class="cell" v-if="item.type == 'img'" :src="common.serverUrl + scope.row[item.field]"></img>
          <el-input  @click.native.stop v-if="item.type == 'input'" v-model="scope.row[item.field]"></el-input>
          <el-input-number  @click.native.stop v-if="item.type == 'number'" v-model="scope.row[item.field]" controls-position="right"></el-input-number>
          <el-input-number  @click.native.stop v-if="item.type == 'numberFloat'" v-model="scope.row[item.field]" :precision="2" controls-position="right"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :width="com.actionWidth || com.rowButtons.length*50+20" v-if="!com.isLookup && com.rowButtons.length>0">
          <template slot-scope="scope">
            <el-button :disabled="scope.row[btn.hideBy]==1" v-if="!btn.hide" v-for="(btn,bI) in com.rowButtons" :key="bI" class="rbtn" :class="btn.type" size="mini" @click.stop="rowAction(scope.row,btn)">{{btn.label}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
   </div>
</template>

<script>
export default {
  data () {
    return {
      showMenu: false,
      showSearch: false
    }
  },
  methods: {
    hasCom (name) {
      debugger
    }
  },
  props: {
    com: Object,
    current: Object,
    clientType: String
  }
}
</script>

<style scoped="scoped">
.self{
  height: 100%;
  width: 100%;
  overflow: auto;
  cursor: pointer;
  background-color: #fff;
}
.self::-webkit-scrollbar { width: 0 !important }
.currentCom{
  border-width: 2px;
  border-style: dashed;
  border-color: red;
  background-color: white;
  position: relative;
}
.cell{
  margin: 3px;
  display: inline-block;
  /* border: 1px solid #a5a49f */
  box-sizing: border-box;
  min-width: 45%;
  font-size: 14px;
}
.btn{
  display: inline-block;
  padding-left: 3px;
}
.selectBtn{
  float: right;
}
.topPanel{
  height: 30px;
  width: 100%;
  border: 3px solid #eee;
  background-color: beige;
  box-sizing: border-box;
}
.rbtns{
  /* height: 30px; */
  border-top: 1px solid #c2d9d4;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.rbtn{
  display: inline-block;
  padding-left: 3px;
  margin: 2px;
}
.row{
	border: 3px solid #eee;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3px;
    background-color: #fff;
}
  .cells{
    min-height: 60px;
  }
  .selectBtn{
    float: right;
  }
  .topPanel{
    height: 36px;
    width: 100%;
    border: 3px solid #e8e8e8;
    background-color: #e8e8e8;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px;
  }
  .btnPanel{
	  background-color: #ffffff;
	  /* height: 120px; */
	  border: 1px solid #c2d9d4;
	  margin: 5px;
	  padding: 5px;
  }
  .btn{
    display: inline-block;
    padding: 0 5px;
    margin: 0 2px;
  }
  .searchLabel{
    width: 90px;
    display: inline-block;
	  font-size: 12px;
  }
  .searchBar{
    display: inline-block;
    width: calc(100% - 130px);
  }
  .fieldlabel{
    font-weight: bold;
    padding-right: 5px;
  }
  
  .headBar{
    line-height: 30px;
    border: #7fccdd 1px solid;
    border-radius:10px;
    padding: 5px;
    display: flex;
    flex-wrap:wrap
  }
  .searchBar{
	  display: flex;
  }
  .searchItem{
	  display: flex;
	  background-color: #d4d9e1;
	  text-align: center;
	  width: 180px;
  }
  .primary{	  border: 1px solid #409EFF;}
  .success{	  border: 1px solid #67C23A;}
  .info{	  border: 1px solid #909399;}
  .warning{	  border: 1px solid #E6A23C;}
  .danger{	  border: 1px solid #F56C6C;}
  .file{display: none;}
</style>
