<template>
	<div>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
	  	</el-form>
		<div>不用设置</div>
    	<jfExplain v-model="drawer" :name="com.cName || '菜单'" :desc="desc" :fieldData="fieldData"></jfExplain>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			desc: '菜单是一个将pc端的菜单列表展示到移动端的组件，使用时可以在配置中设置跳转的路径、名称、图标及权限等……',
			fieldData:[
				{field: 'icon',explain: '菜单图片地址', type: 'string'},
				{field: 'id',explain: '菜单id', type: 'number'},
				{field: 'name',explain: '菜单标题', type: 'string'},
				{field: 'pId',explain: '分类id', type: 'number'},
				{field: 'path',explain: '调转路径', type: 'string'},
			]
		}
	},
	props: {
		com: Object
	}
}
</script>

<style scoped>

</style>