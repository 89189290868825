<template>
  <div class="self">
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item label="isNew">
        <el-checkbox v-model="com.isNew"></el-checkbox>
      </el-form-item>
      <el-form-item label="接口">
        <selectorFun v-model="com.postFun" :comData="comData" placement="bottom-end"></selectorFun>
      </el-form-item>
      <el-form-item label="成功执行">
        <el-select v-model="com.success">
          <el-option v-for="(evt, sI) in com.evts" :key="sI" :label="evt" :value="evt"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="失败执行">
        <el-select v-model="com.fail">
          <el-option v-for="(evt, fI) in com.evts" :key="fI" :label="evt" :value="evt"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="购物车">
        <span>显示</span>
        <el-checkbox v-model="com.cart.show"></el-checkbox>
        <span>链接</span>
        <selectorPage v-model="com.cart.pageName" :comData="comData"></selectorPage>
        <span>颜色</span>
        <el-color-picker v-model="com.cart.color"></el-color-picker>
        <span>背景</span>
        <el-color-picker v-model="com.cart.bgColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="评论">
        <span>显示</span>
        <el-checkbox v-model="com.comment.show"></el-checkbox>
        <span>链接</span>
        <selectorPage v-model="com.comment.pageName" :comData="comData"></selectorPage>
        <span>颜色</span>
        <el-color-picker v-model="com.comment.color"></el-color-picker>
        <span>背景</span>
        <el-color-picker v-model="com.comment.bgColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="加入购物车">
        <span>显示</span>
        <el-checkbox v-model="com.cartButton.show"></el-checkbox>
        <span>链接</span>
        <selectorPage v-model="com.cartButton.pageName" :comData="comData"></selectorPage>
        <span>颜色</span>
        <el-color-picker v-model="com.cartButton.color"></el-color-picker>
        <span>背景</span>
        <el-color-picker v-model="com.cartButton.bgColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="立即购买">
        <span>显示</span>
        <el-checkbox v-model="com.buyButton.show"></el-checkbox>
        <span>链接</span>
        <selectorPage v-model="com.buyButton.pageName" :comData="comData"></selectorPage>
        <span>颜色</span>
        <el-color-picker v-model="com.buyButton.color"></el-color-picker>
        <span>背景</span>
        <el-color-picker v-model="com.buyButton.bgColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="地址列表链接">
        <selectorPage v-model="com.addressPageName" :comData="comData"></selectorPage>
      </el-form-item>
      <el-form-item label="地址显示">
          <div class="rows">
            <el-switch
              v-model="com.showAddress"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-form-item>
      <el-form-item label="优惠券列表链接">
        <selectorPage v-model="com.couponPageName" :comData="comData"></selectorPage>
      </el-form-item>
    </el-form>
    <jfExplain v-model="drawer" :desc="desc" :name="com.cName || '购物导航'" :fieldData="fieldData"></jfExplain>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      desc: "购物导航是一个主要用于实现加入购物车、评论、选择规格、购买的入口组件，可以通过设置接口、地址列表、添加地址等配置完成跳转及展示。",
      fieldData:[
        {field: 'productId',explain: '产品id', type: 'number'},
        {field: 'number',explain: '数量', type: 'string'},
        {field: 'tourId',explain: '分类id', type: 'string'}
      ]
    }
  },
  props: {
    com: { coms: Array },
    comData: Object
  }
}
</script>

