<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
            <el-form-item label="组件说明">
            <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
            </el-form-item>
            <el-form-item label="文件路径">
                <el-input v-model="com.path" placeholder="请输入路径"></el-input>
            </el-form-item>
			<el-form-item label="操作按钮">
				<div class="rows">
					<el-checkbox v-model="com.isBack">返回</el-checkbox>
					<el-checkbox v-model="com.isAdd">添加文件夹</el-checkbox>
					<el-checkbox v-model="com.isUpload">上传文件</el-checkbox>
					<el-checkbox v-model="com.isDel">删除</el-checkbox>
				</div>
			</el-form-item>
        </el-form>
        <jfExplain v-model="drawer" :name="com.cName || '图片管理'" :desc="desc" :fieldData="fieldData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            desc: '图片管理主要用于pc端，在PC端上管理需要访问的文件夹或图片，可设置访问路径，也可以对操作按钮权限进行勾选。',
            fieldData:[]
        }
    },
    created() {},
    methods: {},
    components:{},
    props: {
        com:Object
    }
};
</script>

<style scoped>

</style>
