<template>
  <div class="comself">
    <!-- <el-button class="btn" plain size="mini" @click="isEdit=!isEdit" type="primary">{{isEdit?'返回':'编辑'}}</el-button> -->
    <!-- <el-button class="btn" plain size="mini" @click="search" type="warning">刷新</el-button> -->
    <!-- <el-button v-if="isEdit" class="btn" plain size="mini" @click="newItem.visible = true" type="primary">添加</el-button>
    <el-button v-if="isEdit" class="btn" plain size="mini" @click="upLoadClick" type="primary">上传</el-button>
    <input class="upInput" type="file" ref="file" id="upFiles" name="image" multiple="multiple" @change="upload"/> -->
	<div v-if="!isEdit" v-for="item in coms" :key="item.id" class="comList">
      <p class="comType">{{item.cname}}</p>
        <div :draggable="true" @dragstart="drag($event,subItem.json, subItem.type)" v-for="subItem in item.children" :key="subItem.id" class="comItems">
        <!-- <div v-for="subItem in item.children" :key="subItem.id" class="comItems"> -->
          <!-- <draggable :list="[subItem]" :options="{group:{name: 'article',pull:'clone',put:false},ghostClass:'ghostClass',sort: false}"> -->
            <div class="comItem" :comId="subItem.id" :com="subItem.json">
              <i :class="subItem.icon"></i>
              <p>{{subItem.cname}}</p>
            </div>
          <!-- </draggable> -->
        </div>
    </div>
    <el-tree v-if="isEdit" :data="coms" node-key="id" @node-click="treeNodeClick">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <i v-if="node.isLeaf && data.iconType === 0" :class="data.icon"></i>
          <img class="imgIcon" v-if="node.isLeaf && data.iconType === 1" :src="common.getImgUrl(data.icon)"/>
          <i v-if="node.isLeaf && data.iconType === 2">{{data.icon}}</i>
          {{ data.cname }}
          </span>
        <span>
          <i v-if="node.isLeaf" class="el-icon-delete" @click.stop="treeNodeRemove(node, data)"></i>
        </span>
      </span>
    </el-tree>

    <el-dialog title="添加组件" :visible.sync="newItem.visible" width="20%" @close="dgClose">
      <el-form>
        <el-form-item label="分类" label-width="80px">
          <el-input v-model="newItem.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称" label-width="80px">
          <el-input v-model="newItem.cname" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newItem.visible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: { draggable },
  data () {
    return {
      newItem: { cname: '', type: '', visible: false },
      isEdit: false,
      coms: []
    }
  },
  created: function () {
    this.search()
	
  },

  methods: {
	upLoadClick() {
		this.$refs.file.click()
	},
	upload (e) {
		if(e.target.files.length>0){
		const data = { module: 'Uploader', action: 'UploadCom', prms: { sysId: this.$route.params.id} }
		this.common.request(data, this.upCb.bind(this), {files:e.target.files})
		e.srcElement.value = ''
	  }
	},
	upCb(r){
		if(r.code != 0) this.$message.error(r.msg)
	},
    drag (e, json, type) {
      console.log(e)
    	e.dataTransfer.setData('json', json)
    	e.dataTransfer.setData('type', type)
    },
    search () {
      const data = { module: 'com', action: 'getList', prms: {} }
      this.common.request(data, this.setComs.bind(this))
    },
    setComs (data) {
      if (data.code === 0) {
        data = data.data[0]
        this.coms = []
        for (var i = 0; i < data.length; i++) {
          // data[i].config = JSON.parse(unescape(data[i].json))
          const fil = this.coms.filter(item => item.cname === data[i].type)
          if (fil.length === 0) {
            const node = { cname: data[i].type, children: [data[i]] }
            this.coms.push(node)
          } else {
            fil[0].children.push(data[i])
          }
        }
      }
    },
    treeNodeRemove (node, nodeData) {
      this.$confirm('确定删除组件[ ' + nodeData.cname + ' ]吗?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        const data = { module: 'com', action: 'delete', prms: { id: nodeData.id } }
        this.common.request(data, this.comDeleteCallback.bind(this))
        this.$emit('del', nodeData)
      })
    },
    comDeleteCallback (data) {
      if (data.code === 0) {
        this.search()
      }
    },
    treeNodeClick (data, node) {
      if (node.isLeaf) {
        this.$emit('selected', data)
      }
    },
    add () {
      const pageData = { fun: null, coms: [] }
      const data = { module: 'com', action: 'add', prms: { cname: this.newItem.cname, type: this.newItem.type, json: unescape('{"name": "jfCom"}') } }
      this.common.request(data, this.addCallback.bind(this))
    },
    addCallback (data) {
      if (data.code === 0) {
        this.search()
        this.newItem.visible = false
      } else this.$message.error(data.msg)
    },
    dgClose () {
      this.newItem.came = ''
      this.newItem.type = ''
    }
  }
}
</script>

<style scoped="scoped">
  .comself{height: 100%; overflow: auto;}
  .comList{text-align: center;overflow: auto;}
  .comself::-webkit-scrollbar { width: 0 !important }
  .comItems {
    border: 1px solid #0D3349;
    width:54px;
    height: 54px;
    margin: 5px;
    float: left;
  }
  .comType{
    padding: 5px;
    font-size: 16px;
    background-color: #b4c3cc;
  }
  .comItem{
    padding: 3px;
    font-size: 11px;
    text-align:center;
  }

  .comItem i{
    font-size: 30px;
    font-weight: bold;
  }
  .comItem img {
    height: 30px;
    width: 30px;
  }
  .imgIcon {
    height: 14px;
    width: 14px;
  }
  .btn{
    margin-left: 1px;
    padding: 3px 3px;
  }
  .ghostClass {
    border: 3px dashed red;
    /* width:calc(100% - 12px); */
  }
  .upInput{
    display: none;
  }
</style>
