<template>
  <div class="videoConten" :style="[com.style]">
    <div class="video_box">
      <video
        preload="auto"
        id="my-video"
        :src="com.data[0].url"
        :poster="$route.params.url+com.data[0].poster"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        controls="controls"
        x5-video-ignore-metadata="true"
        width="100%"
        height="100%"
      >
        <p>当前视频不支持</p>
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>
