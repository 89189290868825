<template>
    <div class='jf-logout jf-flex' :style="[com.style,{backgroundColor:com.bgColor,color:com.fontColor}]">
        {{com.title}}
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    props:{
        com: Object
    }
};
</script>

<style scoped>
    .jf-logout {
        width: 90%;
        height: 30px;
        border-radius: 20px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
