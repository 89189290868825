<template>
	<div>
		<el-form label-width="90px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item label="组件高度(单位vh)">
				<div class="rows">
					<el-input-number v-model="com.ctlgHeight" :min="0" :max="100" :step="10" controls-position="right" size="mini"></el-input-number>
				</div>
			</el-form-item>
			<el-form-item label="左侧栏背景颜色">
				<div class="rows">
					<el-color-picker v-model="com.lBgColor"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="右侧栏背景颜色">
				<div class="rows">
					<el-color-picker v-model="com.rBgColor"></el-color-picker>
				</div>
			</el-form-item>
		</el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '分类'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			desc: '分类组件用于多类目的展示，只需要设置分类列表接口即可，一般是配合商品列表使用，方便3级类目点击跳转类目商品列表，在链接处设置跳转路径。',
			fieldData:[
				{field: 'name',explain: '名称', type: 'string'},
				{field: 'pId',explain: '分类所属id', type: 'string'},
				{field: 'imgUrl',explain: '图片', type: 'string'}
			],
			requestData:[
				{name: '分类列表接口',module: 'd_goods_ctlg', action: 'getByPid'}
			]
		}
	},
	props: {
		com: Object
	}
}
</script>

<style scoped>

</style>