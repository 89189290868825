<template>
	<div>
		<div class="com1">*********</div>
	</div>
</template>

<script>
	export default {
	  props: {
		com: Object
	  }
	}
</script>

<style scoped>
	.com1{
		font-size: 30px;
		color: red;
	}
</style>