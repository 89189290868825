<template>
    <div class="listBox" :style="[com.style]">
	  <div class="listBox" :style="{backgroundColor:com.bgColor}">
		<ul class="clear">
		  <li v-for="(item, index) in com.data" :key="index" class="fl" :class="clientType == 'pc'?'pc-col':''" 
		  :style="{borderRadius:com.borderRadius+'%',overflow: 'hidden'}">
			<div class="listImg w100">
			  <img :src="$route.params.url+item.imgUrl"/>
			</div>
			<div class="text">
				<div class="goods-title">{{item.title||'商品名称'}}</div>
				<div class="goods-price" :style="{color: com.priceColor}">
					￥<span class="big-font">{{item.price||'商品价格'}}</span>{{com.priceTitle || '到手价'}}
					<span class="old-price" v-if="com.isPrice">￥{{item.oldPrice||'???'}}</span>
				</div>
			</div>
		  </li>
		</ul>
	  </div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      data: []
    }
  },
	methods: {
			getBinding(){
				return [{label: '状态',field:'state'},{label: '刷新列表',field:'search()'}]
			}
	},
  props: {
    pageObj: Object,
    com: Object,
	clientType: String
  }
}
</script>

<style scoped>
    .w100 {
        width: 100%;
    }
    .clear {
        display: flex;
        flex-wrap: wrap;
    }
    .fl {
		width: 47%;
		margin: calc(6% / 3) 0;
		font-size: 14px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: #FFFFFF;
	}
	.fl:nth-child(odd) {
		margin: calc(6% / 3);
	}
    .fl img {
        width: 100%;
        height: 100%;
    }
	.fl .text {
		padding: 5px;
	}
	.fl .goods-title {
		color: #434343;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* 显示的行数 */
		-webkit-box-orient: vertical;
	}
	.fl .goods-price {
		color: #ff4142;
	}
	.fl .big-font {
		font-size: 16px;
	}
	.old-price {
		margin-left: 10px;
		color: #ccc;
	}
	.pc-col {
		width: 200px;
		height: 250px;
		margin:  calc(6% / 6) !important;
	}
</style>