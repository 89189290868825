<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="删除收货地址">
		        <selectorFun v-model="com.funDelAddress" placement="bottom-end"></selectorFun>
            </el-form-item>
            <el-form-item label="修改默认收货地址">
		        <selectorFun v-model="com.funUpdateAddress" placement="bottom-end"></selectorFun>
            </el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '地址列表'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
			drawer: false,
			desc: `地址列表组件用于存放展示用户所新增的地址列表，方便后续操作使用，在接口处设置地址列表接口即可。`,
			fieldData:[
				{field: 'street',explain: '省市区', type: 'string'},
				{field: 'addr',explain: '详细地址', type: 'string'},
				{field: 'addrName',explain: '收货人', type: 'string'},
				{field: 'addrPhone',explain: '收货人电话', type: 'string'},
				{field: 'status',explain: '是否是默认收货地址1：是 2：否', type: 'string'}
			],
			requestData:[
				{name: '地址列表接口',module: 'd_user_addr', action: 'getList'},
				{name: '切换是否是默认地址接口',module: 'd_user_addr', action: 'updateByStatus'},
				{name: '地址删除接口',module: 'd_user_addr', action: 'delete'},
			]
        };
    },
    created() {},
    methods: {},
    components:{},
    props:{
        com:Object
    }
};
</script>

<style scoped>

</style>
