export default {
  getHtml (fields) {
    let html = `<template>
  <div>
    <el-form label-width="100px" class="demo-ruleForm" size="mini">`
    for (let i = 0; i < fields.length;) {
      html += `
      <el-row>`
      html += this.getField(fields[i])
      i++
      if (i < fields.length) {
        html += this.getField(fields[i])
      }
      i++
      html += `
      </el-row>`
    }
    html += `
    <el-form-item>
        <el-button @click="cancel" type="warning" plain size="mini" icon="el-icon-folder-delete">取消</el-button>
        <el-button @click="save" type="warning" plain size="mini" icon="el-icon-folder-checked">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>`
    return html
  },
  getField (field) {
    const ctrl = this.getCtrl(field)
    return `
        <el-col :span="12">
          <el-form-item label="${field.label}">
            ${ctrl}
          </el-form-item>
        </el-col>`
  },
  getCtrl (field) {
    switch (field.control) {
      case 'jfInput': return `<el-input v-model="info.${field.name}"/>`
      case 'jfLabel': return `<div>{{info.${field.name}}}</div>`
      case 'jfSelect':
        const option = this.getOption(field)
        return `<el-select size="mini" v-model="info.${field.name}" clearable placeholder="请选择">${option}
            </el-select>`
      case 'jfDate': return `<el-date-picker v-model="info.${field.name}" type="date" placeholder="选择日期"/>`
      case 'jfDateTime': return `<el-date-picker v-model="info.${field.name}" type="datetime" placeholder="选择日期时间">`
      case 'jfUploadImg': return `<el-input v-model="info.${field.name}"/>`
    }
  },
  getOption (field) {
    if (field.optionsDataIndex !== '') {
      const isStr = isNaN(field.optionsDataIndex * 1)
      // if (isStr) prm.options = JSON.parse(prm.optionsDataIndex)
      if (isStr) {
        let strOpt = ''
        const options = field.optionsDataIndex.split(/[，,\n]/)
        for (let i = 0; i < options.length; i++) {
        	const opt = options[i].split(/[:：]/)
        	if (opt.length === 2) {
            strOpt += `
              <el-option label="${opt[1]}" :value="${opt[0]}"/>`
          } else {
            strOpt += `
              <el-option label="${opt[0]}"" :value="${opt[0]}"/>`
          }
        }
        return strOpt
      }
    } else {
      return `
      <el-option v-for="item in options" :key="item.key" :label="item.value" :value="item.key"></el-option>`
    }
  },
  getJs (ui) {
    return `
<script>
  export default {
    data() {
      return {
        options: [],
        info: {}
      }
    },
    created() {
      this.info.id = this.currentId
      if (this.currentId != ''){
          let fun = ${JSON.stringify(ui.fun)}
          this.common.request(fun, this.callback.bind(this))
      } else {
        this.common.request({module: '${ui.fun.module}',action: 'getOption'}, this.getOptionCb.bind(this))
      }
    },
    methods: {
      getOptionCb(r){
        if(r.code === 0) this.options = r.data
      },
      callback(r) {
        if (r.code == 0) {
          this.options = r.data[0]
          this.info = r.data[1][0]
        }
      },
      save() {
        let fun = ${JSON.stringify(ui.funSave)}
        this.common.request(fun, this.saveCb.bind(this))
      },
      saveCb(r) {
        if (r.code === 0) this.$emit('cancel', '')
        else this.$message.error(r.msg)
      },
      cancel() {
        this.$emit('cancel', '')
      }
    },
    props: {
      currentId: ''
    }
  }
</script>`
  },
  getCss () {
    return `
    <style>
      .el-select {
        display: inline;
      }

      .img {
        width: 30px;
        height: 30px;
      }

      .upInput {
        display: none;
      }
    </style>`
  }
}
