<template>
  <!-- <el-tree
    :data="com.coms"
    :props="{children: 'coms'}"
    default-expand-all
    draggable
    :expand-on-click-node="false"
    @node-click="nodeClick">
    <span class="custom-tree-node" slot-scope="{ node, data}" :style="isSelect(data)?'color:red;border:2px dashed red':''" >
      <span class="label">{{ data.name }}</span>
      <span>
        <i class="el-icon-delete" @click.stop="removeNode(data, node)"></i>
		
		  
      </span>
    </span>
  </el-tree> -->
  
  <div class="tbl">
	  <div v-for="(item,index) in com.coms" :key="index" @click="rowClick(index)" class="row" :style="isSelect(item)?'color:red;border:2px dashed red':''">
		  <div class="comName">{{item.name}}</div>
		  <div>
			  <div class="el-icon-top" @click.stop="upGo(com.coms,index)"></div>
			  <div class="el-icon-bottom" @click.stop="downGo(com.coms,index)"></div>
			  <div class="el-icon-close" @click.stop="deleteGo(com.coms,index)"></div>
		  </div>
	  </div>
	</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created: function () {
  },
  methods: {
    nodeClick (data, node) {
      const coms = node.parent.data.coms ? node.parent.data.coms : node.parent.data
      for (let i = 0; i < coms.length; i++) {
        if (coms[i] === data) {
          this.current.init(coms)
          this.current.select(coms, i)
        }
      }
    },
	rowClick(index){
		this.current.init(this.com.coms)
		this.current.select(this.com.coms, index)
	},
    upGo (arr, index) {
      if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
		this.rowClick(index-1)
      } else {
        arr.push(arr.shift())
		this.rowClick(arr.length - 1)
      }
    },
    downGo (arr, index) {
      if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
		this.rowClick(index+1)
      } else {
        arr.unshift(arr.splice(index, 1)[0])
		this.rowClick(0)
      }
    },
    deleteGo (arr, index) {
      arr.splice(index, 1)
    },
    removeNode (data, node) {
      this.nodeClick(data, node)
      this.current.del()
    },
    dragEnd (node, dropNode, dropType, ev) {
      this.nodeClick(node.data, node)
    },
    isSelect (com) {
      for (let i = 0; i < this.current.selection.length; i++) {
        const eq = this.current.coms[this.current.selection[i]] === com
        if (eq) return true
      }
      return false
    }
  },
  props: {
    com: Object,
    current: Object
  }
}
</script>

<style scoped="scoped">
  .label{padding: 5px;}
  .tbl{
	  display: flex;
	  flex-direction: column;
  }
  .row{
	  display: flex;
	  padding: 5px;
	  border: 1px solid #efe9d9
  }
  .comName{
	  width: 80%;
  }
</style>
