<template>
    <div class=''>
        <el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
			<el-form-item label="接参字段">
				<div class="rows">
                    <span>价格：</span>
                    <el-input size="mini" placeholder="请输入字段名" v-model="com.price"></el-input>
				</div>
				<div class="rows">
                    <span>订单id：</span>
                    <el-input size="mini" placeholder="请输入字段名" v-model="com.id"></el-input>
				</div>
			</el-form-item>
			<el-form-item label="付款方式">
				<div class="rows">
					<el-checkbox v-model="com.wx">微信</el-checkbox>
					<el-checkbox v-model="com.zfb">支付宝(小程序不支持)</el-checkbox>
				</div>
			</el-form-item>
            <el-form-item label="确认支付">
                <div style="width: 150px;">
                    <span>后置操作：</span>
                    <el-select v-model="com.afterAction.after">
                        <el-option label="关闭" value="close"></el-option>
                        <el-option label="跳转页面" value="link"></el-option>
                        <el-option label="返回并刷新上页" value="backAndRefresh"></el-option>
                        <el-option label="无" value=""></el-option>
                    </el-select>
                </div>
                <div v-if="com.afterAction.after == 'link'">
                    <span>链接</span>
                    <selectorPage v-model="com.afterAction.pageName" :comData="comData"></selectorPage>
                </div>
                <span>颜色</span>
                <el-color-picker v-model="com.afterAction.color"></el-color-picker>
                <span>背景</span>
                <el-color-picker v-model="com.afterAction.bgColor"></el-color-picker>
            </el-form-item>
        </el-form>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '支付'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
			desc: `支付组件为需要支付的系统提供，组件只为支付功能服务，使用此组件需要传参订单id，配置链接可自定义跳转页面(默认为返回上一页)`,
			fieldData:[
                {field: 'payType',explain: '支付方式', type: 'number'},
                {field: 'allMoney',explain: '支付金额', type: 'number'},
                {field: 'orderId',explain: '订单id', type: 'number'},
            ],
            requestData:[]
        };
    },
    created() {
        console.log(this.com);
    },
    methods: {},
    props:{com:Object,comData:Object}
};
</script>

<style scoped>

</style>
