<template>
    <div class='jf-cart-editor'>
		<el-form label-width="100px" size="mini">
			<el-form-item label="组件说明">
				<el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
			</el-form-item>
            <el-form-item label="删除商品接口(必填)">
		        <selectorFun v-model="com.delCartList" placement="bottom-end"></selectorFun>
            </el-form-item>
            <el-form-item label="更新商品数量接口(必填)">
		        <selectorFun v-model="com.updateCart" placement="bottom-end"></selectorFun>
            </el-form-item>
			<el-form-item label="商品详情页">
				<span>链接</span>
				<selectorPage v-model="com.goodsDetailPageName" :comData="comData"></selectorPage>
			</el-form-item>
			<el-form-item label="结算">
				<span>显示</span>
				<el-checkbox v-model="com.buyShow"></el-checkbox>
				<span>链接</span>
				<selectorPage v-model="com.buyPageName" :comData="comData"></selectorPage>
			</el-form-item>
            <el-form-item label="添加商品">
				<el-button type="primary" size="mini" @click="add()">添加商品</el-button>
            </el-form-item>
        </el-form>
		<el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
			<el-table-column width="80" label="图片">
			<template slot-scope="scope">
				<selectorImg v-model="scope.row.imgUrl"></selectorImg>
			</template>
			</el-table-column>
			<el-table-column width="90" label="名称">
			<template slot-scope="scope">
				<el-input v-model="scope.row.name"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="90" label="规格">
			<template slot-scope="scope">
				<el-input v-model="scope.row.sku"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="90" label="价格">
			<template slot-scope="scope">
				<el-input v-model="scope.row.price"></el-input>
			</template>
			</el-table-column>
			<el-table-column width="150" label="链接">
			<template slot-scope="scope">
				<selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
			</template>
			</el-table-column>
			<el-table-column width="90" label="参数">
			<template slot-scope="scope">
				<el-input v-model="scope.row.query"></el-input>
			</template>
			</el-table-column>
			<el-table-column label="操作" width="70">
			<template slot-scope="scope">
				<div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
				<div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
				<div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
			</template>
			</el-table-column>
		</el-table>
		<jfExplain v-model="drawer" :desc="desc" :name="com.cName || '购物车'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
    </div>
</template>

<script>
export default {
    data() {
        return {
			drawer: false,
			desc: `购物车组件用于展示购物车的列表、收货地址、优惠券、结算等功能，
			涉及接口：购物车列表接口(上面接口处设置，支持分页)、删除商品接口(必
			填,下面配置项设置，支持单项删除与多项删除)、更新商品数量接口(必填，
			更改购物车数量时同步数据库)、获取默认收货地址接口(固定为module:
			d_user_addr,action: getListByStatus)、创建订单接口(该接口需要
			一次请求三个接口分别为(订单表添加(module:d_order,action:add)、
			订单商品添加(module:d_order,action:add_m)、优惠券使用并计算接口
			(module:d_order,action:updatePayment)),此外还有固定公共接口：优惠券
			列表接口、优惠券领取接口、支付接口)`,
			fieldData:[
				{field: 'image',explain: '商品图片', type: 'string'},
				{field: 'title',explain: '商品名称', type: 'string'},
				{field: 'valu',explain: '商品规格', type: 'string'},
				{field: 'price',explain: '售卖价', type: 'string'},
				{field: 'price',explain: '售卖价', type: 'string'},
				{field: 'num',explain: '购物车商品数量', type: 'string'},
				{field: 'goodsId',explain: '商品id', type: 'string'}
			],
			requestData:[
				{name: '购物车列表接口',module: 'd_goods_cart', action: 'getList'},
				{name: '删除商品接口',module: 'd_goods_cart', action: 'delete'},
				{name: '更新商品数量接口',module: 'd_goods_cart', action: 'update'},
				{name: '默认收货地址接口',module: 'd_user_addr', action: 'getListByStatus'},
				{name: '订单表添加',module: 'd_order', action: 'add'},
				{name: '订单商品添加',module: 'd_order', action: 'add_m'},
				{name: '使用优惠券',module: 'd_order', action: 'updatePayment'},
				{name: '优惠券列表',module: 'd_coupon', action: 'getList_m'},
				{name: '优惠券领取',module: 'd_user_coupon', action: 'add'},
				{name: '支付',module: 'WxPay', action: 'xcxPay'}
			]
		};
    },
    created() {},
    methods: {
        add () {
            this.com.data.push({
                name: "测试名字1",
                imgUrl: "",
                check: false,
                sku:"",
                price: "",
                number: 1,
                pageName:"",
                query:"",
            })
        }
    },
    props:{
        com: Object,
				comData: Object
    }
};
</script>

<style scoped>

</style>
