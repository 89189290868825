import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: resolve => require(['../page/index.vue'], resolve),
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/sysList',
        name: 'sysList',
        component: resolve => require(['../page/sysList.vue'], resolve),
        meta: {
          title: '系统管理'
        }
      },
	  {
        path: '/tempList',
        name: 'tempList',
        component: resolve => require(['../page/tempList.vue'], resolve),
        meta: {
          title: '选择模板'
        }
      },
      {
        path: '/funTest',
        name: 'funTest',
        component: resolve => require(['../page/funTest.vue'], resolve),
        meta: {
          title: '接口测试'
        }
      },
      {
        path: '/userList',
        name: 'userList',
        component: resolve => require(['../page/userList.vue'], resolve),
        meta: {
          title: '用户管理'
        }
      },
	  {
        path: '/workerList',
        name: 'workerList',
        component: resolve => require(['../page/workerList.vue'], resolve),
        meta: {
          title: '系统设置'
        }
      },
	  {
        path: '/comList',
        name: 'comList',
        component: resolve => require(['../page/comList.vue'], resolve),
        meta: {
          title: '组件管理'
        }
      }
    ]
  },{
        path: '/jfDesigner',
        name: 'jfDesigner',
        component: resolve => require(['../page/jfDesigner.vue'], resolve),
        meta: {
          title: '设计'
        }
      },
  {
    path: '/login',
    name: 'login',
    component: () => import('../page/login.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
