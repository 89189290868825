<template>
    <div class="listBox" :style="[com.style]">
	  <div class="listBox" :style="{backgroundColor:com.bgColor}">
		<ul class="clear" :class="clientType == 'pc'?'pc-flex':'pc-flex'">
		  <li v-for="(item, index) in com.data" :key="index" class="fl w100" :class="clientType == 'pc'?'pc-col':'pc-col'" 
		  :style="{height:com.cardHeight+'px',borderRadius:com.borderRadius+'px',overflow: 'hidden'}">
			<div class="listImg left h100">
			  <!-- <img src="https://www.bjapp11.com/svr/upload/lv/1.jpg"/> -->
			  <img :src="$route.params.url+item.imgUrl"/>
			</div>
			<div class="text right h100">
				<div class="r-top">
					<div class="goods-name show-ellipsis">{{item.name||'商品名称'}}</div>
					<div class="goods-desc show-ellipsis">{{item.desc||'商品描述'}}</div>
				</div>
				<div class="goods-price" :style="{color: com.priceColor}">
					￥<span class="big-font">{{item.price||'商品价格'}}</span>{{com.priceTitle || '到手价'}}
					<span class="old-price" v-if="com.isPrice">￥{{item.oldPrice||'???'}}</span>
				</div>
			</div>
		  </li>
		</ul>
	  </div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      data: []
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object,
	clientType: String
  }
}
</script>

<style scoped>
	.w100 {
	    width: 100%;
	}
	.h100 {
		height: 100%;
	}
	.clear {
		padding: 10px;
	}
	.clear *:not(:last-child) {
	    margin: 0 0 10px 0;
	}
	.fl {
		display: flex;
		width: 100%;
		height: 100px;
		font-size: 14px;
	    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	    background-color: #FFFFFF;
	}
	.fl img {
	    width: 100%;
	    height: 100%;
	}
	.fl .left {
		width: 30%;
	}
	.fl .right {
		text-align: left;
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px;
		box-sizing: border-box;
	}
	.fl .goods-name {
		color: #434343;
	}
	/* 省略号显示 */
	.show-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* 显示的行数 */
		-webkit-box-orient: vertical;
	}
	.fl .goods-price {
		color: #ff4142;
	}
	.fl .big-font {
		font-size: 16px;
	}
	.old-price {
		margin-left: 10px;
		color: #ccc;
	}
	.pc-col {
		width: 350px;
		margin: 10px 5px !important;
	}
	.pc-flex {
		display: flex;
		flex-wrap: wrap;
	}
</style>