<template>
  <div>
    <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
			<el-form-item label="导航栏">
				<div class="rows">
					<el-checkbox v-model="com.leftState">左侧标题</el-checkbox>
					<el-checkbox v-model="com.centerState">中间标题</el-checkbox>
					<el-checkbox v-model="com.rightState">右侧标题</el-checkbox>
				</div>
			</el-form-item>
			<el-form-item label="左侧标题">
					<el-input v-model="com.leftTitle"></el-input>
			</el-form-item>
			<el-form-item label="中间标题">
					<el-input v-model="com.centerTitle"></el-input>
			</el-form-item>
			<el-form-item label="右侧标题">
					<el-input v-model="com.rightTitle"></el-input>
			</el-form-item>
			<el-form-item label="背景颜色">
					<el-color-picker v-model="com.bgColor"></el-color-picker>
			</el-form-item>
			<el-form-item label="字体颜色">
					<el-color-picker v-model="com.fontColor"></el-color-picker>
			</el-form-item>
			<el-form-item label="logo">
        <selectorImg v-model="com.imgUrl"></selectorImg>
			</el-form-item>
    </el-form>
	<jfExplain v-model="drawer" :name="com.cName || '标题'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '标题组件通常用于页面、栏目……的标题设置，可以选择需要展示的标题位置以及设置logo及文本内容从而实现标题的展示。',
      fieldData:[]
    }
  },
  methods: {
    add (type) {
      this.com.data.push({ imgUrl: '', pageId: 0 })
    }
  },
  props: { com: {} }
}
</script>
