<template>
  <div class="phoneBox" :style="[com.style,{lineHeight:com.style['min-height']}]">
    <div class="phoneMin">
      <ul class="clear">
        <li class="fl">
         <i class="el-icon-phone"></i>
        </li>
        <li class="fl">
          <span
            class="phoneText"
          >{{com.data[0].text||'电话'}}{{com.data[0].number}}</span>
        </li>
        <!-- <li class="fr phoneRgiht">
           <svg class="icon1">
            <use xlink:href="#icon-enter"></use>
        </svg>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null
    }
  },
  created: function () {
  },
  props: {
    pageObj: Object,
    com: Object
  }
}
</script>
<style scoped>
  .phoneBox {
    background-color: #fff;
  }
  ul {
    margin: 0;
	  display: flex;
	  background-color: #fff;
	  padding: 0px 10px;
	  position: relative;
	  box-sizing: border-box;
	  align-items: center;
  }
  li {
    list-style-type: none;
    margin-left: 0;
  }
</style>
