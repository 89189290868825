<template>
    <div class='tabs flex' :class="[com.buttons.length > (com?.tabScrool || 5) ? 'isTabsScroll' : '']" :style="[com.style]">
        <div @click="hasCom(index)" v-for="(btn,index) in com.buttons" :key="index" :class="[index == current ? 'active' : 'tabs-item',com.buttons.length > (com?.tabScrool || 5) ? 'isItemScroll' : '']" :style="[com?.tabStyle,{'color': index == com.current ? com.activeColor : '','fontSize': '18px','fontWeight': index == com.current ? 600 : 400}]" v-if="!btn.hide">{{btn.label}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {
        hasCom(index){
            this.com.current = index
        },
		getBinding(){
		  return [{label: '状态',field:'state'}]
		}
    },
    props:{com:Object}
};
</script>

<style scoped>
    .flex {
        display: flex;
        align-items: center;
    }
    .tabs {
        background-color: #fff;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
	}
    .tabs .tabs-item {
        flex: 1;
    }
    .isTabsScroll {
        overflow-x: scroll;
    }
    .isItemScroll {
        flex-shrink: 0;
        width: auto;
        flex: none !important;
    }
    .active {flex: 1;font-size: 18px!important;font-weight: 600!important;}
	.activeprimary {flex: 1;font-size: 18px;font-weight: 600;}
	.activesuccess {flex: 1;font-size: 18px;font-weight: 600;}
	.activeinfo {flex: 1;font-size: 18px;font-weight: 600;}
	.activewarning {flex: 1;font-size: 18px;font-weight: 600;}
	.activedanger {flex: 1;font-size: 18px;font-weight: 600;}
</style>
