import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import common from './plugins/common.js'

Vue.use(ElementUI)
Vue.prototype.common = common
common.router = router
window.common = common
window.Vue = Vue
const requireComponent = require.context('./coms/', true, /[A-Za-z0-9-_]+\.(vue|js)$/)

requireComponent.keys().forEach(fileName => {
  // console.log(fileName)
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.substring(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('.'))
  // const extension = fileName.substring(fileName.lastIndexOf('.') + 1)
  Vue.component(componentName, componentConfig.default || componentConfig)
  // if (extension == 'js') Vue.prototype[componentName] = componentConfig.default || componentConfig
  // else Vue.prototype[componentName] = componentName
})
Vue.config.productionTip = false


// for(let item in mycom){
    // Vue.component(item,mycom[item])
// }
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
