<template>
  <div>
	  <div class="fc" ref="fc"></div>
    <el-form label-width="90px" size="mini">
      <el-form-item class="margin-tb" label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item class="margin-tb" label="数据源">
        <div class="rows">
          <el-input-number v-model="com.dataIndex" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
        </div>
      </el-form-item>
      <el-form-item class="margin-tb" label="标签位置">
        <el-radio-group v-model="com.labelPosition" size="small">
          <el-radio-button label="left">左边</el-radio-button>
          <el-radio-button label="top">上边</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="margin-tb" label="标签宽度">
		  <el-input-number v-model="com.labelWidth" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
	  </el-form-item>
      <el-form-item class="margin-tb" label="控件宽度">
		  <el-input-number v-model="com.ctrlWidth" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
	  </el-form-item>
      <el-form-item class="margin-tb" label="列数">
		  <el-input-number v-model="com.colCount" size="mini" controls-position="right" style="width: 130px;"></el-input-number>
	  </el-form-item>
      <el-form-item class="margin-tb" label="格线">
        <el-radio-group v-model="com.border" size="small">
          <el-radio-button :label="true">显示</el-radio-button>
          <el-radio-button :label="false">不显示</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="margin-tb" label="底部导航">
        <div style="margin-bottom: 10px;">
            <el-radio-group v-model="com.footerPosition" size="small">
              <el-radio-button :label="true">固定底部</el-radio-button>
              <el-radio-button :label="false">正常展示</el-radio-button>
            </el-radio-group>
        </div>
        <div style="margin-bottom: 10px;" v-if="com.footerPosition">
          <input style="border: 1px solid #ccc; width: 150px; border-radius: 5px" type="text" v-model="com.bottomNum" size="mini" placeholder="底部距离"></input>
        </div>
        <jfStyle v-model="com.footerStyle"></jfStyle>
      </el-form-item>
      <el-form-item class="margin-tb" label="底部左侧文本">
        <div style="margin-bottom: 10px;">
          <el-radio-group v-model="com.leftText.hide" size="small">
            <el-radio-button :label="true">显示</el-radio-button>
            <el-radio-button :label="false">不显示</el-radio-button>
          </el-radio-group>
        </div>
        <div style="margin-bottom: 10px;" v-if="com.leftText.hide">
          前缀：
          <input style="border: 1px solid #ccc; width: 150px; border-radius: 5px" type="text" v-model="com.leftText.prefix" size="mini"></input>
        </div>
        <div style="margin-bottom: 10px;" v-if="com.leftText.hide">
          内容：
          <input style="border: 1px solid #ccc; width: 150px; border-radius: 5px" type="text" v-model="com.leftText.value" size="mini"></input>
        </div>
        <jfStyle v-model="com.leftText.style" v-if="com.leftText.hide"></jfStyle>
      </el-form-item>
	  <el-form-item class="margin-tb" label-width="0px">
      <div style="display: flex;">
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="btnVisible=true">按钮设置</el-button>
        <el-button style="flex: 1;" type="primary" plain size="mini" @click="fieldVisible=true">字段设置</el-button>
      </div>
	  </el-form-item>
    </el-form>
	
	<el-drawer ref="btmbtn" :direction="'btt'" :with-header="false" title="按钮设置" :size="btmSize" :visible.sync="btnVisible" :append-to-body="true">
		<div class="spliter" @mousedown="mousedown($event,$refs.btmbtn)"></div>
	<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
	<el-button type="primary" size="mini" @click="addButton(com.buttons)">添加按钮</el-button>
	<el-button type="warning" class="el-icon-top" size="mini" v-if="currentBtn" @click="upGo(com.buttons,currentBtn)">上移</el-button>
	<el-button type="warning" class="el-icon-bottom" size="mini" v-if="currentBtn" @click="downGo(com.buttons,currentBtn)">下移</el-button>
    <el-table :data="com.buttons" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row @current-change="handleCurrentBtnChange">
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="el-icon-close" @click="deleteGo(com.buttons,scope.$index)"></div>
			<el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="标签" width="80">
          <template slot-scope="scope">
            <input v-model="scope.row.label" :style="scope.row==currentBtn?'color:red;font-size:14px':''" placeholder="按钮文本"></input>
          </template>
        </el-table-column>
        <el-table-column label="样式" width="100">
          <template slot-scope="scope">
            <jfStyle v-model="scope.row.style"></jfStyle>
          </template>
        </el-table-column>
        <el-table-column label="动作" width="110">
          <template slot-scope="scope">
            <el-select v-model="scope.row.actionType" placeholder="请选择">
               <el-option label="访问接口" value="fun"></el-option>
               <el-option label="打开页面" value="link"></el-option>
               <el-option label="弹出页面" value="dialog"></el-option>
               <el-option label="滑出页面" value="drawer"></el-option>
               <el-option label="返回" value="back"></el-option>
             </el-select>
          </template>
        </el-table-column>
		<el-table-column label="权限" width="110">
			<template slot-scope="scope">
				<selectorRight v-model="scope.row.rightId"></selectorRight>
			</template>
		</el-table-column>
    <el-table-column label="禁用条件" width="150">
        <template slot-scope="scope">
            <el-select v-model="scope.row.hideByType" placeholder="请选择">
                <el-option label="按条件(禁用)" value="condition"></el-option>
                <el-option label="按条件(隐藏)" value="hideBy"></el-option>
            </el-select>
            <input v-if="scope.row.hideByType == 'condition' || scope.row.hideByType == 'hideBy'" v-model="scope.row.hideBy"
                placeholder="> < <= >= == !="
                style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
        </template>
    </el-table-column>
		<el-table-column label="后置操作" width="150">
			<template slot-scope="scope">
				<el-select v-model="scope.row.after">
					<el-option label="关闭" value="close"></el-option>
					<el-option label="刷新" value="refresh"></el-option>
					<el-option label="跳转页面(不保留)" value="link"></el-option>
					<el-option label="跳转页面(保留)" value="linkTo"></el-option>
					<el-option label="返回并刷新上页(移动端)" value="backAndRefresh"></el-option>
					<el-option label="无" value=""></el-option>
				</el-select>
        <el-input v-if="['link','linkTo'].includes(scope.row.after)" size="mini" placeholder="订单id位置" v-model="scope.row.idNumber"></el-input>
        <el-input v-if="['link','linkTo'].includes(scope.row.after)" size="mini" placeholder="订单id字段" v-model="scope.row.idName"></el-input>
			</template>
		</el-table-column>
        <el-table-column label="动作类型" width="250">
          <template slot-scope="scope">
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.link" :comData="comData"></selectorPage>
            <selectorPage v-if="['link','dialog','drawer'].includes(scope.row.actionType)" v-model="scope.row.linkM" :type="'M'" :comData="comData"></selectorPage>
            <selectorFun v-if="['fun'].includes(scope.row.actionType)" v-model="scope.row.fun" :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
			<input v-if="scope.row.actionType=='fun'" v-model="scope.row.funTip" placeholder="提示信息" style="display: inline-block;border: 1px solid #b8b8b8;width: 220px;"/>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
			  <el-button v-if="scope.row.actionType=='fun' && scope.row.fun" type="primary" size="mini" @click="getPrms('in',scope.row.fun)">获取字段</el-button>
            <selectorPage v-if="['link','linkTo'].includes(scope.row.after)" v-model="scope.row.linkA" :type="'M'" :comData="comData"></selectorPage>
          </template>
        </el-table-column>
    </el-table>
	</div>
	</el-drawer>
	<el-drawer ref="btmField" :direction="'btt'" :with-header="false" title="字段设置" :size="btmSize" :visible.sync="fieldVisible" :append-to-body="true">
		<div class="spliter" @mousedown="mousedown($event,$refs.btmField)"></div>
		<div class="drw">
		<el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
	<el-button type="primary" size="mini" @click="addField">添加字段</el-button>
	<el-button type="primary" @click="getPrms('out',com.fun)" size="mini">获取字段</el-button>
	<el-button type="warning" class="el-icon-top" size="mini" v-if="currentRow" @click="upGo(com.coms,currentRow)">上移</el-button>
	<el-button type="warning" class="el-icon-bottom" size="mini" v-if="currentRow" @click="downGo(com.coms,currentRow)">下移</el-button>
    <el-table :data="com.coms" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}" highlight-current-row @current-change="handleCurrentChange">
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <!-- <div class="el-icon-top" @click="upGo(com.coms,scope.$index)"></div>
          <div class="el-icon-bottom" @click="downGo(com.coms,scope.$index)"></div> -->
          <div class="el-icon-close" @click="deleteGo(com.coms,scope.$index)"></div>
		  <el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
        </template>
      </el-table-column>
	  <el-table-column width="80" label="字段">
	    <template slot-scope="scope">
	      <input v-model="scope.row.field" @blur="scope.row.field = scope.row.field.trim()" :style="currentRow&&scope.row.field==currentRow.field?'color:red;font-size:14px':''" placeholder="字段"/>
	    </template>
	  </el-table-column>
      <el-table-column width="120" label="标签">
        <template slot-scope="scope">
          <input v-model="scope.row.label" placeholder="标签"/>
        </template>
      </el-table-column>
      <el-table-column width="90" label="组件类型">
        <template slot-scope="scope">
          <el-select size="mini" v-model="scope.row.type" placeholder="请选择">
             <el-option key="2" label="只读文本"  value="label"></el-option>
             <el-option key="4" label="输入框"  value="input"></el-option>
             <el-option key="6" label="图片"    value="image"></el-option>
             <el-option key="7" label="文件上传"    value="filePicker"></el-option>
             <el-option key="9" label="年月"    value="month"></el-option>
             <el-option key="10" label="日期"    value="date"></el-option>
             <el-option key="12" label="时间"   value="time"></el-option>
             <el-option key="15" label="日期时间"    value="datetime"></el-option>
             <el-option key="16" label="下拉框"  value="select"></el-option>
             <el-option key="18" label="下拉输入框"  value="selectInput"></el-option>
             <el-option key="19" label="级联下拉"  value="cascader"></el-option>
             <el-option key="21" label="单选钮"  value="radio"></el-option>
             <el-option key="24" label="复选框"  value="checkbox"></el-option>
             <el-option key="27" label="参照页"  value="lookup"></el-option>
             <el-option key="30" label="地址选择"  value="mapPicker"></el-option>
             <el-option key="31" label="城市选择"  value="pageName"></el-option>
             <el-option key="33" label="富文本"  value="editor"></el-option>
             <el-option key="34" label="年月日(只读)"  value="ymd"></el-option>
             <el-option key="35" label="优惠券"  value="coupon"></el-option>
             <el-option key="36" label="地址"  value="address"></el-option>
           </el-select>
        </template>
      </el-table-column>	  
	  <el-table-column width="100" label="跨列" align="center">
	    <template slot-scope="scope">
			<el-input-number v-model="scope.row.span" size="mini" controls-position="right" style="width: 90px;"></el-input-number>
	    </template>
	  </el-table-column>
      <el-table-column width="80" label="默认值" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.default"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="60" label="只读" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.readonly"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="60" label="必填" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.require"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="120" label="数据类型">
        <template slot-scope="scope">
          <el-select size="mini" v-model="scope.row.dataType" placeholder="请选择">
             <el-option v-for="(item,index) in restaurants" :key="index" :label="item.value" :value="item.value"></el-option>
           </el-select>
        </template>
      </el-table-column>
      <el-table-column width="120" label="长度">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.max" controls-position="right" size="mini" style="width: 90px;"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="120" label="占位符">
        <template slot-scope="scope">
          <el-input v-model="scope.row.placeholder" size="mini" style="width: 90px;"></el-input>
        </template>
      </el-table-column>
    <el-table-column label="隐藏条件" width="150">
        <template slot-scope="scope">
            <el-select v-model="scope.row.hideByType" placeholder="请选择">
                <el-option label="按条件(隐藏)" value="hideBy"></el-option>
            </el-select>
            <input v-if="scope.row.hideByType == 'condition' || scope.row.hideByType == 'hideBy'" v-model="scope.row.hideBy"
                placeholder="> < <= >= == !="
                style="display: inline-block;border: 1px solid #b8b8b8;width: 120px;padding: 2px;" />
        </template>
    </el-table-column>
      <el-table-column width="180" label="其他">
        <template slot-scope="scope">
          <div>
			  <jfDataSource v-if="['select','cascader','radio','checkbox','selectInput'].indexOf(scope.row.type)!=-1" v-model="scope.row.options"></jfDataSource>
            <div v-if="scope.row.type=='input'" class="other">
              <div class="rows">
                <el-select size="mini" v-model="scope.row.inputType" placeholder="键盘类型">
                  <el-option key="0" value="text" label="普通文本"></el-option>
                  <el-option key="1" value="textarea" label="多行文本"></el-option>
                  <el-option key="2" value="password" label="密码框"></el-option>
                  <el-option key="3" value="number" label="数字键盘"></el-option>
                  <el-option key="4" value="idcard" label="身份证键盘"></el-option>
                  <el-option key="5" value="digit" label="带小数点数字键盘"></el-option>
                </el-select>
              </div>
              <div class="rows" v-if="scope.row.inputType=='textarea'">
                行数
                <el-input-number v-model="scope.row.rows" controls-position="right" size="mini"></el-input-number>
              </div>
            </div>
			<selectorLookup v-if="scope.row.type=='lookup'" v-model="scope.row.look" :coms="com.coms"></selectorLookup>
			<!-- <div v-if="scope.row.type=='lookup'" class="other">
				<selectorPage v-model="scope.row.link" :comData="comData"></selectorPage>
				<selectorPage v-model="scope.row.linkM" :comData="comData"></selectorPage>
				<el-input v-model="scope.row.textField" size="mini" placeholder="显示字段 userName"/>
				<el-input v-model="scope.row.setField" size="mini" placeholder="带回字段 userId:id,userName:name,userAge:age"/>
			</div> -->
			<div v-if="scope.row.type=='filePicker'" class="other">
				<span>最大数量</span>
				<el-input-number v-model="scope.row.fileLimit" controls-position="right" size="mini"></el-input-number>
				<jfStyle v-model="scope.row.imgStyle"></jfStyle>
				<el-select size="mini" v-model="scope.row.mediatype" placeholder="文件类型">
				  <el-option key="0" value="image" label="image"></el-option>
				  <el-option key="1" value="video" label="video"></el-option>
				  <el-option key="2" value="file" label="file"></el-option>
				</el-select>
			</div>
          </div>
          <selectorPage v-if="scope.row.type == 'pageName'" v-model="scope.row.link" :comData="comData"></selectorPage>
          <selectorPage v-if="scope.row.type == 'pageName'" v-model="scope.row.linkM" :type="'M'" :comData="comData"></selectorPage>
          <div v-if="['coupon'].includes(scope.row.type)">
            优惠券列表：
            <selectorFun v-model="scope.row.fun"
                :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
          </div>
          <div v-if="['coupon'].includes(scope.row.type)">
            领取优惠券：
            <selectorFun v-model="scope.row.getCouponFun"
                :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
          </div>
          <div v-if="['address'].includes(scope.row.type)">
            地址列表：
            <selectorFun v-model="scope.row.getAddressFun"
                :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
          </div>
          <div v-if="['address'].includes(scope.row.type)">
            删除地址：
            <selectorFun v-model="scope.row.deleteAddressFun"
                :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
          </div>
          <div v-if="['address'].includes(scope.row.type)">
            新增地址页
            <selectorPage v-model="scope.row.addressLink" :comData="comData"></selectorPage>
          </div>
        </template>
      </el-table-column>

    </el-table>
	</div>
	</el-drawer>
  <jfExplain v-model="drawer" :desc="desc" :name="com.cName || '表单'" :fieldData="fieldData" :requestData="requestData"></jfExplain>
  </div>
</template>

<script>
export default {
  data () {
    return {
		btmSize: '400px',
		fieldVisible: false,
		btnVisible: false,
		active: '按钮',
		currentRow:null,
		currentBtn:null,
    restaurants : [{value:'string'},{value:'decimal'},{value:'phone'},{value:'email'},{value:'pwd'},{value:'int'},{value:'number'},{value:'time'},{value:'date'},{value:'datetime'},{value:'url'},{value:'idNumber'},{value:'ip'}],
		drawer: false,
    desc: `表单组件可以在pc与移动端通用，可实现大部分表单操作，
    按钮设置可以配置组件地底部的按钮操作，字段设置是表单中需要
    展示的表单字段，可配置字段名、标签、类型等。`,
    fieldData:[],
    requestData:[]
    }
  },
  created: function () {
    if(!this.com.leftText) {
      this.com.leftText ={
          "prefix": "",
          "value": "",
          "style": {}
      }
    }
  },
  methods: {
	addButton (btns) {
	  btns.push({ label: '', type: 'primary',rightId: '0', actionType: 'fun', fun: null, link: '',fields:[] })
	},
	addField(){
		const prmsField = {field: '', label: '', type: 'input', rows: 1, options: [], readonly: false,require: false }
		this.com.coms.push(prmsField)
	},
	getPrms(type,fun){
		if (fun) {
			let data = { module: 'sysfun', action: 'getDefine', prms: Object.assign({sysId: this.$route.params.id},fun)}
			this.common.request(data, this.getPrmsCb.bind(this,type))
		} else {
			this.$message.error('没有设置接口')
		}
	},
    getPrmsCb (type,r) {
      if (r.code != 0) {
		  this.$message.error(r.msg)
		  return
      }
	  if (r.data[0].length == 0) {
			this.$message.error('接口不存在')
	  		return
	  }
	  let prms = JSON.parse(r.data[0][0].define)[type]
      if (prms) {
        for (var i = 0; i < prms.length; i++) {
          if (prms[i].field === 'id') continue
          const existCom = this.com.coms.find(ele => ele.field == prms[i].field)
          if (!existCom) {
            const prmsField = {field: prms[i].field, label: prms[i].comment, type: 'input', rows: 1, options: [], readonly: false,require: prms[i].require }
            this.com.coms.push(prmsField)
          } else existCom.readonly = false
        }
      }
    },
	handleCurrentChange(val) {
		this.currentRow = val
	},
	handleCurrentBtnChange(val) {
		this.currentBtn = val
	},
	getIndex(arr,row){
		for(let i = 0; i < arr.length; i++){
			if(arr[i] == row) return i
		}
	},
    upGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
      } else {
        arr.push(arr.shift())
      }
    },
    downGo (arr, row) {
		let index = this.getIndex(arr,row)
      if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
      } else {
        arr.unshift(arr.splice(index, 1)[0])
      }
    },
    deleteGo (arr, index) {
      arr.splice(index, 1)
    },
	mousedown(evt,sourceDom) {
	  let height = parseInt(sourceDom.size)
	  let pageY = evt.pageY
	  let start = true
	  this.$refs.fc.style.display = 'block'
	  let that = this
	  document.onmousemove = (event) => {
		if(start) {
			// sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
			that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
		}
	  };
	  document.onmouseup = () => {
		  this.$refs.fc.style.display = 'none'
		document.onmousemove = null;
		document.onmouseup = null;
		start = false
		return false;
	  }
	}
  },
  props: {
    com: { coms: Array },
    comData: Object,
	saveMethod: Function
  }
}
</script>

<style scoped="scoped">
/* .el-radio-group{width: 60px;} */
.el-input-number--mini{width: 75px;border:1px solid #B3C0D1}
.el-form-item{
	width: 220px;
}
.other{
  display: flex;
  flex-wrap: wrap;
}
.rows{
  display: flex;
}

.drw{
	padding-left: 20px;
}
.spliter{
	width: 100%;
    height:5px;
    background-color: #006666;
	cursor: s-resize;
  }
  .fc{
	  display: none;
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(216, 216, 216, 0.1);
	  z-index: 10000;
  }
  .margin-tb {
    margin: 5px 0;
  }
</style>
