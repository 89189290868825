<template>
	<div>
		<div style="height: 65vh;overflow: scroll;">
			<el-radio-group v-show="step==0" v-model="fromId" @input="changeList[0]=changeList[1]=true">
				  <el-radio :label="itm.id" border v-for="(itm,idx) in sysList" :key="idx">{{itm.name}}</el-radio>
				</el-radio-group>
			<el-table v-show="step==1" ref="tableF" :data="funList" stripe tooltip-effect="dark" style="width: 100%" @row-click="$refs.tableF.toggleRowSelection($event)" @selection-change="selectionF=$event;changeList[1]=true">
			  <el-table-column type="selection" label="选择" width="100"></el-table-column>
			  <el-table-column prop="module" label="module" width="120"></el-table-column>
			  <el-table-column prop="action" label="action" width="120"></el-table-column>
			  <el-table-column prop="name" label="名称" width="100"></el-table-column>
			  <el-table-column prop="rep" label="是否重复" width="120"></el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<div v-if="scope.row.rep =='重复' && scope.isSelected" @click.stop="">
							<el-radio v-model="scope.row.way" label="覆盖">覆盖</el-radio>
							<el-radio v-model="scope.row.way" label="合并">合并</el-radio>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-table v-show="step==2" ref="tableT" :data="tableList" stripe tooltip-effect="dark" style="width: 100%" @row-click="$refs.tableT.toggleRowSelection($event)"  @selection-change="selectionT=$event;changeList[2]=true">
			  <el-table-column type="selection" label="选择" width="100"></el-table-column>
			  <el-table-column prop="TABLE_NAME" label="名称" width="120"></el-table-column>
			  <el-table-column prop="TABLE_COMMENT" label="备注" width="100"></el-table-column>
			  <el-table-column prop="rep" label="是否重复" width="120"></el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<div v-if="scope.row.rep =='重复' && scope.isSelected" @click.stop="">
							<el-radio v-model="scope.row.way" label="覆盖">覆盖</el-radio>
							<el-radio v-model="scope.row.way" label="合并">合并</el-radio>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-steps :active="step" finish-status="success">
		  <el-step title="选择来源系统"></el-step>
		  <el-step title="选择接口"></el-step>
		  <el-step title="选择数据表"></el-step>
		</el-steps>
		<div class="titleBar" slot="footer" style="display: flex;justify-content: flex-end;">
			<el-button v-if="step>0" plain size="mini" @click="pre" type="danger">上一步</el-button>
			<el-button plain size="mini" @click="next" type="danger">{{step==2?'提交':'下一步'}}</el-button>
		</div>
	</div>
</template>

<script>
	export default {
	  data () {
	    return {
			fromId: '',
			sysList: [],
			funList: [],
			tableList:[],
			selectionF: [],
			selectionT: [],
			step: 0,
			changeList: [false,false]
		}
	},
	created: function () {
		this.search()
	},
	methods:{
		search () {
			let sysFun = { module: 'sys', action: 'getList' }
			this.common.request(sysFun, this.setSysList.bind(this))
		},
		setSysList(r){
			this.sysList = r.data[0]
		},
		pre(){
			this.step--
			this.changeList[this.step] = false
		},
		next(){
				console.log(this.step)
				console.log(this.changeList)
				
			if(!this.changeList[this.step]){
				this.step++
				return
			}
			if(this.step == 0){
				let fun = { module: 'sysfun', action: 'getListForImport', prms: { sysId: this.fromId,toSysId:this.$route.params.id } }
				this.common.request(fun, this.setFunList.bind(this))
			} else if(this.step == 1){
				if(this.selectionF.length == 0){
					this.step++
					this.changeList[this.step] = true
					return;
				}
				// const regex = /\b(?:FROM|JOIN)\s+([\w.]+)/gi
				const regex = /\b(?:FROM|JOIN|UPDATE|INSERT\s+INTO|DELETE\s+FROM)\s+([a-zA-Z0-9_\.]+)\b/gi
				const tableNames = [];
				let match;
				for(let i = 0; i < this.selectionF.length; i++){
				 	let sql = this.selectionF[i].text
					while ((match = regex.exec(sql))) {
						tableNames.push(match[1]);
					}
				}
				let names = Array.from(new Set(tableNames))
				const data = { module: 'sys',action:'getTabelsForCopy', prms: { sysId: this.fromId,toSysId: this.$route.params.id,tableNames: names.join()} }
				this.common.request(data, this.setTableList.bind(this))
			} else if(this.step == 2){
				const data = { module: 'sys',action:'import', prms: { sysId: this.fromId,toSysId: this.$route.params.id,pages:[],funs:this.selectionF,tables: this.selectionT} }
				this.common.request(data, this.importCb.bind(this))
			}
		},
		setFunList(r){
			this.funList = r.data[0]
			this.step++
			this.selectionF = []
			this.selectionT = []
		},
		setTableList(r){
			this.tableList = r.data
			this.step++
			this.selectionT = []
			this.$nextTick(() => {
				this.$refs.tableT.toggleAllSelection()
			})
		},
		importCb(r){
			this.$emit("close")
		}
	}
}
</script>

<style>
  .el-radio.is-bordered{
	  margin-top: 10px;
	  width: 180px;
  }
</style>