<template>
  <div class="arrayEditorself">
    <el-button type="primary" title="设置数组的对象" @click="dialogVisible=true">数组编辑</el-button>

	<el-dialog :top="'5vh'" title="数组编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="90%" :append-to-body="true">
	  <el-button size="mini" @click="add()">添加</el-button>
	  <el-button size="mini" plain type="primary" @click="confirm()">确定</el-button>
	  <div class="arrTable">
		  <div class="arrRow" style="font-weight: bold;background-color: gainsboro;">
			  <div v-for="(col, idx) in cols" :key="idx" class="arrCell">
				{{col.label}}
			  </div>
		  </div>
		  <div v-for="(obj, index) in arr" :key="index" class="arrRow">
				<div v-for="(col, index) in cols" :key="index" class="arrCell">
					<input v-if="col.type!='img'" v-model="obj[col.field]"/>
					<selectorImg v-if="col.type=='img'" v-model="obj[col.field]"></selectorImg>
				</div>
				<div>
					<jfStyle v-model="obj.style"></jfStyle>
				</div>
				<div>
					<el-button plain type="danger" size="mini" @click="del(index)">删除</el-button>
				</div>
		  </div>
	  </div>
	</el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
		data:null,
		current: { prms: {} },
		funList: [],
		dialogVisible:false
    }
  },
  created: function () {
  },
  methods: {
	add(){
		let obj = {}
		for(let i = 0; i < this.cols.length; i++){
			obj[this.cols[i].field] = ""
		}
		this.arr.push(obj)
	},
    confirm () {
		for(let i = 0; i < this.arr.length; i++){
			this.arr[i].id = i
		}
      this.$emit('setArr', this.arr)
      this.dialogVisible = false
    },
	del(index){
		this.arr.splice(index,1)
	}
  },
  model: {
    prop: 'arr',
    event: 'setArr'
  },
  props: {
    arr: {type:Array},
	cols: Array
  }
}
</script>

<style scoped="scoped">
  .arrayEditorself{max-width: 200px;}
  /deep/ .el-dialog {
          height: 90vh;
          overflow: auto;
      }
	.arrTable{
		display: flex;
		flex-direction: column;
	}
	.arrRow{
		display: flex;
		margin-top: 10px;
		align-items: center;
		border-bottom: 1px #b4e5ee solid;
	}
	.arrCell{
		display: flex;
		align-items: center;
		width: 200px;
		height: 60px;
	}
	.arrCell input{
		height: 30px;
		width: 198px;
		border: 1px #97c0c7 solid;
	}
</style>
