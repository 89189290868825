<template>
  <div>
  <div v-show="!dialogVisible">
  <el-tabs type="border-card" v-model="active">
    <el-tab-pane label="编辑" name="编辑">
      <el-collapse >
        <el-collapse-item title="按钮栏" name="buttons">
          <el-button type="primary" size="mini" @click="addButton(buttons)">添加按钮</el-button>
          <el-table :data="buttons" stripe tooltip-effect="dark" style="width: 100%">
            <el-table-column label="标签">
              <template slot-scope="scope">
                <el-input v-model="scope.row.label"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="颜色" width="50">
              <template slot-scope="scope">
                  <el-dropdown @command="scope.row.type=$event">
                    <el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="primary">主要</el-dropdown-item>
                      <el-dropdown-item command="success">成功</el-dropdown-item>
                      <el-dropdown-item command="info">信息</el-dropdown-item>
                      <el-dropdown-item command="warning">警告</el-dropdown-item>
                      <el-dropdown-item command="danger">危险</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column label="动作">
              <template slot-scope="scope">
                <el-select v-model="scope.row.actionType" placeholder="请选择">
                   <el-option key="0" label="访问接口" value="fun"></el-option>
                   <el-option key="1" label="打开页面" value="link"></el-option>
                   <el-option key="2" label="刷新" value="refresh"></el-option>
                 </el-select>
              </template>
            </el-table-column>
            <el-table-column label="动作">
              <template slot-scope="scope">
                <selectorFun v-if="scope.row.actionType=='fun'" v-model="scope.row.fun" :comData="comData" placement="bottom-end"></selectorFun>
                <el-input v-if="scope.row.actionType=='link'" v-model="scope.row.link"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="el-icon-top" @click="upGo(buttons,scope.$index)"></div>
                <div class="el-icon-bottom" @click="downGo(buttons,scope.$index)"></div>
                <div class="el-icon-close" @click="deleteGo(buttons,scope.$index)"></div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>

        <el-collapse-item title="行操作" name="rowButtons">
          <el-button type="primary" size="mini" @click="addButton(rowButtons)">添加按钮</el-button>
          <el-table :data="rowButtons" stripe tooltip-effect="dark" style="width: 100%">
            <el-table-column label="标签">
              <template slot-scope="scope">
                <el-input v-model="scope.row.label"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="颜色" width="50">
              <template slot-scope="scope">
                <el-dropdown @command="scope.row.type=$event">
                  <el-button :type="scope.row.type" icon="el-icon-edit" circle></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="primary">主要</el-dropdown-item>
                    <el-dropdown-item command="success">成功</el-dropdown-item>
                    <el-dropdown-item command="info">信息</el-dropdown-item>
                    <el-dropdown-item command="warning">警告</el-dropdown-item>
                    <el-dropdown-item command="danger">危险</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column label="动作">
              <template slot-scope="scope">
                <el-select v-model="scope.row.actionType" placeholder="请选择">
                   <el-option key="0" label="访问接口" value="fun"></el-option>
                   <el-option key="1" label="打开页面" value="link"></el-option>
                 </el-select>
              </template>
            </el-table-column>
            <el-table-column label="动作">
              <template slot-scope="scope">
                <selectorFun v-if="scope.row.actionType=='fun'" v-model="scope.row.fun" :comData="comData" placement="bottom-end"></selectorFun>
                <el-input v-if="scope.row.actionType=='link'" v-model="scope.row.link"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="el-icon-top" @click="upGo(rowButtons,scope.$index)"></div>
                <div class="el-icon-bottom" @click="downGo(rowButtons,scope.$index)"></div>
                <div class="el-icon-close" @click="deleteGo(rowButtons,scope.$index)"></div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>

        <el-collapse-item title="搜索条件" name="condition">
          <el-button type="primary" size="mini" @click="getCondation">获取条件</el-button>
        <el-table :data="searchComs" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
          <el-table-column label="字段" prop="field" width="60"></el-table-column>
          <el-table-column label="标签">
            <template slot-scope="scope">
              <el-input v-model="scope.row.label"></el-input>
            </template>
          </el-table-column>
          <el-table-column width="60" label="默认">
            <template slot-scope="scope">
              <el-input v-model="scope.row.emptyData"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="宽度">
            <template slot-scope="scope">
              <el-input-number class="number" v-model="scope.row.width" controls-position="right" :max="24" size="mini"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="组件类型">
            <template slot-scope="scope">
              <el-select v-model="scope.row.name" placeholder="请选择">
                 <el-option key="0" label="输入框" value="jfInput"></el-option>
                 <el-option key="1" label="选择框" value="jfSelect"></el-option>
                 <el-option key="2" label="日期" value="jfDate"></el-option>
                 <el-option key="3" label="日期时间" value="jfDateTime"></el-option>
               </el-select>
            </template>
          </el-table-column>
          <el-table-column label="数据源" width="60">
            <template slot-scope="scope">
              <el-input v-model="scope.row.optionsDataIndex"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="50">
            <template slot-scope="scope">
              <div class="el-icon-top" @click="upGo(searchComs,scope.$index)"></div>
              <div class="el-icon-bottom" @click="downGo(searchComs,scope.$index)"></div>
              <div class="el-icon-close" @click="deleteGo(searchComs,scope.$index)"></div>
            </template>
          </el-table-column>
        </el-table>
        </el-collapse-item>

        <el-collapse-item title="列设置" name="cols">
          <el-button type="primary" size="mini" @click="dialogVisible=true">获取字段</el-button>
          <el-table :data="cols" stripe tooltip-effect="dark" style="width: 100%">
            <el-table-column width="120" label="字段">
              <template slot-scope="scope">
                <el-input v-model="scope.row.field"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="120" label="标签">
              <template slot-scope="scope">
                <el-input v-model="scope.row.label"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="120" label="类型">
              <template slot-scope="scope">
                <el-select v-model="scope.row.type" placeholder="请选择">
                   <el-option key="1" label="文本" value="txt"></el-option>
                   <el-option key="2" label="图片" value="img"></el-option>
                   <el-option key="3" label="日期" value="date"></el-option>
                   <el-option key="4" label="日期时间" value="datetime"></el-option>
                 </el-select>
              </template>
            </el-table-column>
            <el-table-column width="120" label="宽度">
              <template slot-scope="scope">
                <el-input-number class="number" v-model="scope.row.width" controls-position="right" size="mini" :step="10"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <div class="el-icon-top" @click="upGo(cols,scope.$index)"></div>
                <div class="el-icon-bottom" @click="downGo(cols,scope.$index)"></div>
                <div class="el-icon-close" @click="deleteGo(cols,scope.$index)"></div>
              </template>
            </el-table-column>
            <el-table-column></el-table-column>
          </el-table>
       </el-collapse-item>

       <el-collapse-item title="分页" name="paging">
         <el-checkbox v-model="paging">分页</el-checkbox>总数数组序号
         <el-input class="pag" v-if="paging" v-model="pageDataIndex" placeholder="数据源"></el-input>
       </el-collapse-item>
       </el-collapse>
    </el-tab-pane>
    <el-tab-pane label="代码" name="代码">

    </el-tab-pane>
  </el-tabs>
  <el-button plain size="mini" @click="cancel" type="primary">取消</el-button>
  <el-button plain size="mini" @click="genarate" type="primary">生成</el-button>
  </div>

  <div v-if="dialogVisible">
      <el-radio-group v-model="hasToken">
        <el-radio :label="0">不带</el-radio>
        <el-radio :label="1">带Token</el-radio>
      </el-radio-group>
      <el-row v-for="(value, key, index) in prms" :key="index">
        <el-col :span="8">{{key}}</el-col>
        <el-col :span="8"><el-input v-model="prms[key]"></el-input></el-col>
      </el-row>
      <el-input class="token" v-model="currentToken" placeholder="Token" v-if="hasToken===1"></el-input>
      <el-table :data="users" stripe style="width: 100%;height: 500px;overflow: auto;" v-if="hasToken===1">
        <el-table-column prop="name" label="账号" width="120"></el-table-column>
        <el-table-column prop="roles" label="角色" width="150"></el-table-column>
        <el-table-column label="Token">
          <template slot-scope="scope">
            <el-link size="mini" plain type="primary" @click="currentToken = scope.row.token">使用</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-button plain size="mini" @click="dialogVisible=false" type="primary">返回</el-button>
      <el-button plain size="mini" @click="getCols" type="primary">提交</el-button>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fun: {},
      prms: {},
      active: '编辑',
      searchComs: [],
      buttons: [],
      rowButtons: [],
      cols: [],
      paging: true,
      pageDataIndex: 1,
      hasToken: 0,
      currentToken: '',
      dialogVisible: false,
      users: []
    }
  },
  created: function () {
    this.initFun()
    const data = { module: 'RemoteDb', action: 'GetUsers', prms: { sysId: this.$route.params.id } }
    this.common.request(data, this.getUsersCallback.bind(this))
  },
  methods: {
    initFun () {
      this.fun = { actions: [this.comData.fun.module + '-' + this.comData.fun.action] }
      const prms = {}
      const reg = /:\w+/g
      const arr = this.comData.fun.text.match(reg)
      for (let i = 0; arr && i < arr.length; i++) {
        const key = arr[i].substring(1)
        prms[key] = ''
      }
      this.prms = prms
      this.$forceUpdate()
    },
    getUsersCallback (r) {
      if (r.code === 0) {
        this.users = r.data
      } else this.$message.error(r.msg)
    },
    addButton (btns) {
      btns.push({ label: '', type: 'primary', actionType: 'fun', fun: null, link: '' })
    },
    getCondation () {
      this.searchComs = []
      if (this.prms) {
        const prmsFields = Object.keys(this.prms)
        for (let i = 0; i < prmsFields.length; i++) {
          if (['orderBy', 'page', 'limit'].includes(prmsFields[i])) continue
          const prmsField = {
            field: prmsFields[i],
            label: '',
            value: this.prms[prmsFields[i]],
            name: 'jfInput',
            width: 4,
            emptyData: '',
            optionsDataIndex: '',
            options: []
          }
          this.searchComs.push(prmsField)
        }
      }
    },
    genarate () {
      this.active = '代码'
    },
    getCols () {
      const opt = { server: this.$route.params.url, subsys: this.$route.params.testSubsys }
      if (this.hasToken) opt.token = this.currentToken
      else opt.withToken = false
      this.fun.prms = this.prms
      this.common.request(this.fun, this.setCols.bind(this), opt)
    },
    setCols (r) {
      if (r.code != 0) {
        this.$message.error(r.msg)
        return
      }
      if (r.data[0].length == 0) {
        this.$message.error('接口需要返回至少一条数据！')
        return
      }
      this.dialogVisible = false
      this.cols = []
      const fields = Object.keys(r.data[0][0])
      for (let j = 0; j < fields.length; j++) {
        const ctrl = {
          type: 'txt',
          field: fields[j],
          width: 120,
          label: ''
        }
        this.cols.push(ctrl)
      }
    },
    upGo (arr, index) {
      if (index !== 0) {
        arr[index] = arr.splice(index - 1, 1, arr[index])[0]
      } else {
        arr.push(arr.shift())
      }
    },
    downGo (arr, index) {
      if (index !== arr.length - 1) {
        arr[index] = arr.splice(index + 1, 1, arr[index])[0]
      } else {
        arr.unshift(arr.splice(index, 1)[0])
      }
    },
    deleteGo (arr, index) {
      arr.splice(index, 1)
    },
    cancel () {
      this.$emit('cancel', '')
    }
  },
  props: {
    comData: Object
  }
}
</script>

<style>
  .el-tabs--border-card {
    height: 500px;
  }
  .number{
    width:90px;
  }
  .el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
       padding-left: 0px;
  }
  .el-table td, .el-table th{
    padding: 0;
  }
  .el-table .cell, .el-table th div {
       padding-right: 0px;
  }

  .el-collapse-item__header {
    background-color: lightblue;
  }
  .pag{
    width: 60px;
  }
  .token{
    width: 100%;
  }
  .el-table input{
    border-top: none;
    border-right: none;
  }
</style>
