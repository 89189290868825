<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="间隔（秒）">
          <el-input-number v-model="com.interval" :min="1" :max="5" label="宽度(最大5)" controls-position="right" size="mini"></el-input-number>
        </el-form-item>
        <el-form-item label="指示点类型">
          <el-radio v-model="com.mode" label="default">默认</el-radio>
          <el-radio v-model="com.mode" label="round">长条形</el-radio>
          <el-radio v-model="com.mode" label="nav">导航</el-radio>
          <el-radio v-model="com.mode" label="indexes">下标</el-radio>
        </el-form-item>
        <el-form-item label="添加图片">
          <el-button type="primary" size="mini" @click="add">添加图片</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
        <el-table-column width="80" label="图片">
          <template slot-scope="scope">
            <selectorImg v-model="scope.row.imgUrl"></selectorImg>
          </template>
        </el-table-column>
        <el-table-column width="180" label="链接">
          <template slot-scope="scope">
            <selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
            <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
            <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
          </template>
        </el-table-column>
      </el-table>
    	<jfExplain v-model="drawer" :name="com.cName || '轮播图'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '轮播图组件通常用于图片的轮巡展示，可配置轮播图样式及轮播间隔，类型，在设置导航类型时可显示图片标题。',
      fieldData:[
        {field: 'imgUrl',explain: '图片', type: 'string'},
        {field: 'title',explain: '图片标题', type: 'string'}
      ]
    }
  },
  methods: {
    add () {
      this.com.data.push({ imgUrl: '', pageId: 0 })
    }
  },
  props: { com: Object, comData: Object }
}
</script>
