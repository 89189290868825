<template>
	<div class="ctlg-page" :style="[com.style,{height:com.ctlgHeight+'vh'}]">
		<div class="moduleItem h100">
			<div class="left h100" :style="{backgroundColor:com.lBgColor}">
				<div :class="[item.id == leftIndex ? 'l-item-active' : 'l-item']" v-for="(item,index) in menuList" :key='item.id' @click="changeCtlg(item)">
					<div class="module_title">
						{{item.name}}
					</div>
				</div>
			</div>
			<div class="right h100" :style="{backgroundColor:com.rBgColor}">
				<template v-if="leftContent.children && leftContent.children.length > 0">
					<div  v-for="(itm2,k) in leftContent.children" :key="k">
						<template v-if="itm2.pId == leftIndex">
							<div class="class-title">
								<div>{{ itm2.name }}</div>
							</div>
							<div class="goods-list">
								<div class="card-item" v-for="(itm3,k3) in itm2.children" :index="itm3.id" :key="k3" @click="$refs.skuPopup.open('bottom')">
									<div class="left flex">
										<!-- <img v-if="itm3.imgUrl" class="w100 h100" :src="common.getImgUrl(itm3.imgUrl)" /> -->
										<!-- <uni-icons v-else type="image" :size="50" color="#777" /> -->
									</div>
									<div class="right">
										<span class="span">{{itm3.name}}</span>
										<span class="span">￥？？？</span>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
				<template v-else>
					<div class="h100" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<!-- <uni-icons type="images" size="60"></uni-icons> -->
						<div>暂时为空</div>
					</div>
				</template>
			</div>
		</div>
		<div class="footer-btn flex" @click="$refs.cartPopup.open('bottom')">
			<div class="fl flex">
				<div class="accounts flex">
					合计：<div class="goods-price">￥<span class="big-font">{{totalPrice}}</span></div>
				</div>
			</div>
			<div class="fr flex">
				<div class="btn is-checked">去结算</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				menuList:[],
				leftIndex: 1,
				leftContent: {},
				sku:[
					{
						name: '外观',
						valu:[{check:false,name: '黑'},{check:false,name: '白'},{check:false,name: '灰'}]
					},
					{
						name: '版本',
						valu:[{check:false,name: '128G'},{check:false,name: '128G'},{check:false,name: '128G'}]
					}
				],
				buttonGroup: [{
						span: '加入购物车',
						backgroundColor: 'linear-gradient(90deg, #FFCD1E, #FF8A18)',
						color: '#fff'
					},
				],
				checkStr: [], // 选中规格
				goodsNum: 1, // 规格数量
				totalPrice: 0, //总价
			}
		},
		mounted() {
			this.menuList = this.getTree(0,this.com.data)
			this.changeCtlg(this.menuList[0]);
		},
		methods: {
			getTree(pId, arr) {
                let children = []
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].pId == pId) {
                        arr[i].children = this.getTree(arr[i].id,arr)
                        children.push(arr[i])
                    }
                }
                return children
			},
			changeCtlg(item) {
				this.leftIndex = item.id;
				this.leftContent = item;
			},
		},
        props: {
            com: {
				type: Object,
				default() {
					return {};
				}
            }
        }
	}
</script>

<style scoped>
	.w100 {
		width: 100%;
	}
	.h100 {
		height: 100%;
	}
	.flex {
		display: flex;
		align-items: center;
	}
	.ctlg-page {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.moduleItem {
		display: flex;
		color: #333;
	}
	.moduleItem .left {
		width: 26%;
		overflow: auto;
		background-color: #f8f8f8;
		box-sizing: border-box;
	}
	.moduleItem .l-item {
		padding: 15px 0;
		text-align: center;
	}
	.moduleItem .l-item-active {
		color: #e93b3d;
		background-color: #fff;
		font-size: 16px;
		font-weight: 600;
		padding: 15px 0;
		text-align: center;
	}
	.moduleItem .right {
		width: 74%;
		overflow: auto;
		background-color: #fff;
	}
	.ctlg-grid {
		display: flex;
		flex-direction: column;
		align-items: center;
		span-align: center;
		justify-content: space-between;
	}
	.ctlg-grid img {
		width: 100%;
		transform: scale(0.8);
		border-radius: 10px;
	}
	.card-item {
		height: 100px;
		padding: 5px;
		display: flex;
		margin-bottom: 5px;
	}
	.card-item .left {
		width: 90px;
		height: 90px;
		border-radius: 10px;
		overflow: hidden;
		justify-content: center;
	}
	.card-item .right {
		padding: 5px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.footer-btn {
		width: 100%;
		height: 50px;
		position: absolute;
		bottom: 60px;
		left: 0;
		padding: 5px;
		justify-content: space-between;
		background-color: #FFFFFF;
	}
	.footer-btn .accounts {
			margin: 0 0 0 10px;
		}
	.footer-btn .fr .btn {
		padding: 8px 20px;
		background: #ccc;
		border-radius: 20px;
	}
	.footer-btn .is-checked {
		background: #ff4142 !important;
		color: #fff;
	}
</style>